import { useContext, useEffect, useState } from "react";
import EchoRouterRouteContext from "../echo-router-route-context";

const getParams = (path, pathName) => {
  const pathElems = path.split("/");
  const paramNames = pathElems
    .map((name, index) => ({
      name,
      index,
    }))
    .filter((p) => p.name.startsWith(":"));

  const result = {};
  const possibleValues = pathName.split("/");

  for (let i = 0; i < paramNames.length; i += 1) {
    const paramName = paramNames[i];
    result[paramName.name.substring(1)] = possibleValues[paramName.index];
  }

  return result;
};

export const useParams = () => {
  const [state, setState] = useState({});
  const { path, pathName, active } = useContext(EchoRouterRouteContext);

  useEffect(() => {
    if (active && pathName) {
      setState(getParams(path, pathName));
    }
  }, [path, pathName, active]);

  return state;
};
