import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withBaseComponent } from "../../../with-base-component";
import { Box } from "@chakra-ui/react";
import { mapTile } from "./util/tile";
import { GraphDesigner } from "@echo/graph-designer";
import { useGraphSource } from "./hooks/use-graph-source";
import PropertiesComponent from "./components/properties-component";
import saveGraph from "./save-graph";
import { showErrorToast } from "../../../../echo-error-toast";
import { toast } from "react-toastify";

const GraphDesignerComponent = ({
  rootProps,
  tilesDataSource,
  connectionsDataSource,
  elementsDataSource,
  context,
  tilePropertiesComponentId,
  connectionPropertiesComponentId,
  graphPropertiesComponentId,
  saveTile,
  saveConnection,
  deleteTile,
}) => {
  const { nodeRef, ...restRootProps } = rootProps;
  const [tiles, setTiles] = useState([]);

  useEffect(() => {
    if (tilesDataSource && typeof tilesDataSource === "function") {
      Promise.resolve(tilesDataSource())
        .then((result) => {
          if (typeof result === "object" && Array.isArray(result)) {
            setTiles(result.map(mapTile));
          }
        })
        .catch((err) => console.err(err));
    } else if (
      tilesDataSource &&
      typeof tilesDataSource === "object" &&
      Array.isArray(tilesDataSource)
    ) {
      setTiles(tilesDataSource.map(mapTile));
    }
  }, [tilesDataSource]);

  const [graphState, updateGraphState] = useGraphSource(
    elementsDataSource,
    connectionsDataSource,
  );

  const handleDelete = useCallback(
    (tile) => {
      deleteTile(tile);
    },
    [deleteTile],
  );

  const handleSave = useCallback(
    (graphStructure) =>
      saveGraph(graphStructure, {
        connectionStorage: saveConnection,
        tileStorage: saveTile,
      })
        .then((result) => {
          toast.success("Saved successfully.");
          updateGraphState(result);
        })
        .catch((err) => {
          showErrorToast({
            reasonTitle: "Cannot save diagram data",
            location: "GraphDesigner - Save connection process",
            shortMessage: err?.message,
            message: err?.message,
          });
        }),
    [saveConnection, saveTile, updateGraphState],
  );

  return (
    <Box
      ref={nodeRef}
      {...restRootProps}
      display="flex"
      height="90%"
      width="100%"
      flex={0.95}
      justifyContent="center"
      alignItems="center"
      position="relative"
    >
      <GraphDesigner
        tiles={tiles}
        onSave={handleSave}
        onDelete={handleDelete}
        graphState={graphState}
        tilePropertiesComponentFunc={(state, setState) => (
          <PropertiesComponent
            context={context}
            componentId={Number(tilePropertiesComponentId)}
            state={state}
            onStateChange={setState}
          />
        )}
        connectionPropertiesComponentFunc={(state, setState) => (
          <PropertiesComponent
            context={context}
            componentId={Number(connectionPropertiesComponentId)}
            state={state}
            onStateChange={setState}
          />
        )}
        graphPropertiesComponentFunc={(state, setState) => (
          <PropertiesComponent
            context={context}
            componentId={Number(graphPropertiesComponentId)}
            state={state}
            onStateChange={setState}
          />
        )}
      />
    </Box>
  );
};

GraphDesignerComponent.propTypes = {
  rootProps: PropTypes.any,
  connectionsDataSource: PropTypes.any,
  elementsDataSource: PropTypes.any,
  tilePropertiesComponentId: PropTypes.string,
  connectionPropertiesComponentId: PropTypes.string,
  graphPropertiesComponentId: PropTypes.string,
  tilesDataSource: PropTypes.any,
  saveTile: PropTypes.func,
  saveConnection: PropTypes.func,
  deleteTile: PropTypes.func,

  context: PropTypes.any,
};

export default withBaseComponent(GraphDesignerComponent);
