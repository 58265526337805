import { Box, List, ListItem, Text, Tooltip } from "@chakra-ui/react";
import { ConditionComponent } from "./condition-component/condition-component";
import { withBaseComponent } from "../../../with-base-component";
import PropTypes from "prop-types";
import { useItems } from "./hooks/use-items";
import { useCallback, useMemo, useState } from "react";
import { InputTypeSwitch } from "./input-type-switch/input-type.switch";
import { ToggleVisibilityBtn } from "./visibility-change-btn/visibility-change-btn";
import { useComponentRegister } from "../../../../../../components-registry/hooks/use-component-register";

const ValuesEditorComponent = (props) => {
  const { dataSource, name, context, style, nodeRef, rootProps, ...rest } =
    props;
  const { items, onItemChange } = useItems(dataSource, context);

  const getData = useCallback(
    () =>
      items
        .filter((i) => i.isVisible)
        .map(({ name, value }) => ({ name, value })),
    [items],
  );

  const component = useMemo(
    () => ({
      name,
      getData,
    }),
    [name],
  );

  useComponentRegister(context, component);

  return (
    <Box ref={nodeRef} {...style} {...rootProps} {...rest} overflowY="auto">
      {items && items.length > 0 ? (
        <List display="flex" flexDirection="column" gap="5px" height="100%">
          {items.map((i) => (
            <ValuesListItem {...i} onChange={onItemChange} key={i.name} />
          ))}
        </List>
      ) : (
        <Box pointerEvents="none" opacity=".5">
          <List padding="5px">
            <ValuesListItem
              isVisible
              name="placeholder"
              value="placeholder"
              type="string"
            />
          </List>
        </Box>
      )}
    </Box>
  );
};

ValuesEditorComponent.propTypes = {
  name: PropTypes.string,
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
      editType: PropTypes.string,
      editDataSource: PropTypes.array,
    }),
  ),
  nodeRef: PropTypes.any,
  rootProps: PropTypes.object,
  context: PropTypes.any,
  style: PropTypes.object,
};

const ValuesListItem = (item) => {
  const { name, isVisible, onChange } = item;
  const [inputType, setInputType] = useState("input");

  const handleChange = (value, item) => onChange({ ...item, value });

  return (
    <ListItem display="flex" gap="5px">
      <ToggleVisibilityBtn
        isVisible={isVisible}
        onClick={() => onChange({ ...item, isVisible: !isVisible })}
      />
      <Box
        display="grid"
        gridTemplateColumns="30% 50% auto"
        gap="5px"
        aria-disabled={!isVisible}
        pointerEvents={!isVisible ? "none" : "auto"}
        _disabled={{ opacity: ".2" }}
        width="100%"
      >
        <Tooltip label={name}>
          <Text
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            cursor="default"
          >
            {name}
          </Text>
        </Tooltip>
        <ConditionComponent
          {...item}
          onChange={(val) => handleChange(val, item)}
          inputType={inputType}
        />
        {!item.editType && item.type === "string" && (
          <InputTypeSwitch value={inputType} onChange={setInputType} />
        )}
      </Box>
    </ListItem>
  );
};

ValuesListItem.propTypes = {
  name: PropTypes.string,
  isVisible: PropTypes.bool,
  type: PropTypes.string,
  editType: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  editDataSource: PropTypes.array,
  onChange: PropTypes.func,
};

export default withBaseComponent(ValuesEditorComponent);
