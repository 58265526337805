export const mapTile = (tile) => {
  let result = {
    id: null,
    type: null,
    color: null,
    icon: null,
    label: null,
  };
  if (tile && typeof tile === "object" && !Array.isArray(tile)) {
    const tileValues = Object.values(tile);
    if (tileValues.length > 3) {
      result.id = tileValues[0];
      result.type = tileValues[1];
      result.color = tileValues[2];
      result.icon = tileValues[3];
      result.label = tileValues[4];
    }
  }

  return result;
};
