import { useContext, useEffect, useState } from "react";
import { Box, Divider, IconButton } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { AddIcon } from "@chakra-ui/icons";
import ChildrenComponent from "../children-component/children.component";
import { withBaseComponent } from "../../../with-base-component";
import { useApiContext } from "../../../../../echo-components/api-context/use-api-context";
import { uuidv4 } from "@echo/tools";
import Tab from "../../../../tabs/tab/tab";
import { useCustomEffect } from "@echo/tools/src/lib/hooks/use-custom-effect";
import { getComponent } from "../../../../../../services/components-service";
import { showErrorToast } from "../../../../echo-error-toast";
import { Draggable } from "@echo/draggable";
import { getTabsPropType } from "./get-tab-proptype";
import { getEditorPropType } from "../../../../permission-editor/prop-type";
import UserContext from "../../../../../../user-context";

const TabsComponent = (props) => {
  const {
    id,
    guid,
    nodeId,
    rootProps,
    tabs,
    childIndexMappings,
    disableLazyLoading,
  } = props;
  const [apiContext, componentDefinition, mode] = useApiContext(
    id,
    guid,
    nodeId,
  );
  const [activeTab, setActiveTab] = useState();
  const { nodeRef, ...restRootProps } = rootProps;
  const [defaultComponent, setDefaultComponent] = useState(null);
  const [loadedChildKeys, setLoadedChildKeys] = useState([]);
  const { permissions } = useContext(UserContext);

  const componentPermissions =
    permissions?.filter(
      (permission) => permission.itemId === id && permission.modelId === 2070,
    ) || [];

  useEffect(() => {
    getComponent({ name: "flex" })
      .then(setDefaultComponent)
      .catch((err) =>
        showErrorToast({
          reasonTitle: "Error while loading default flex component from server",
          location: "getComponent",
          shortMessage: err.toString().slice(0, 300),
          message: err.toString(),
        }),
      );
  }, []);

  useEffect(() => {
    setLoadedChildKeys([...loadedChildKeys]);
  }, [activeTab]);

  const updateComponentDefinitionProperty = (newProperty, propertyName) => {
    if (mode === "designer") {
      const api = apiContext?.api;
      if (api) {
        api.updateComponentProperty(id, newProperty, propertyName);
      }
    }
  };

  const updateProps = (newProps) =>
    updateComponentDefinitionProperty(
      { ...componentDefinition.componentProps, ...newProps },
      "componentProps",
    );

  const updateChildComponents = (newChildComponents) =>
    updateComponentDefinitionProperty(newChildComponents, "childrenComponents");

  const setTabs = (tabs) => updateProps({ tabs });

  const updateTab = (newTab) => {
    const newTabs = [...tabs];
    const tabIndex = newTabs.findIndex((t) => t.key === newTab.key);
    newTabs[tabIndex] = newTab;
    setTabs(newTabs);
  };

  const updateOrder = (oldIndex, newIndex) => {
    const newTabs = tabs.filter((tab) => tab !== tabs[oldIndex]);
    newTabs.splice(newIndex, 0, tabs[oldIndex]);
    setTabs(newTabs);
  };

  const setChildIndexMappings = (mappings) =>
    updateProps({ childIndexMappings: mappings });

  useEffect(() => {
    if (tabs && tabs.length && !activeTab) {
      setActiveTab(tabs[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs]);

  const filterMappings = (mapping) =>
    mapping.tabKey &&
    componentDefinition?.childrenComponents?.some(
      (c) => c.key === mapping.childKey,
    ) === true;

  useCustomEffect(
    () => {
      // When children length is changed, update links to active tab
      if (mode === "designer" && tabs && tabs?.length) {
        if (
          activeTab &&
          !childIndexMappings?.find((c) => c.tabId === activeTab?.index) &&
          componentDefinition.childrenComponents.length
        ) {
          setChildIndexMappings([
            ...(childIndexMappings ?? []).filter(filterMappings),
            {
              childKey:
                componentDefinition.childrenComponents[
                  componentDefinition.childrenComponents.length - 1
                ].key,
              tabId: activeTab?.index,
              tabKey: activeTab?.key,
            },
          ]);
        } else if (tabs.length) {
          Promise.all([
            setChildIndexMappings([
              ...(childIndexMappings ?? []).filter(filterMappings),
              {
                childKey:
                  componentDefinition.childrenComponents[
                    componentDefinition.childrenComponents.length - 1
                  ]?.key,
                tabId: tabs[tabs.length - 1]?.index,
                tabKey: tabs[tabs.length - 1]?.key,
              },
            ]),
            setActiveTab(tabs[tabs.length - 1]),
          ]);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [componentDefinition?.childrenComponents?.length],
    ([newLength], [oldLength]) => {
      return newLength <= oldLength;
    },
  );

  useCustomEffect(
    () => {
      if (mode === "designer") {
        // When children length is changed, update links to active tab
        setChildIndexMappings(
          (childIndexMappings ?? []).filter(filterMappings),
        );
      }
    },

    [componentDefinition?.childrenComponents?.length],
    ([newLength], [oldLength]) => {
      return newLength >= oldLength;
    },
  );

  useCustomEffect(
    () => {
      if (mode === "designer") {
        updateChildComponents(
          componentDefinition.childrenComponents
            .map((c) => ({
              c,
              m: childIndexMappings.find((m) => m.childKey === c.key),
            }))
            .filter((cm) => cm.m && tabs.some((t) => t.key === cm.m.tabKey))
            .map((cm) => cm.c),
        );
      }
    },
    [tabs?.length || 0],
    ([newLength], [oldLength]) => {
      return newLength >= oldLength;
    },
  );

  const addComponent = () => {
    const api = apiContext?.api;
    const name = `${defaultComponent.name.replaceAll("\n", "")}_${uuidv4()
      .toString()
      .substring(0, 6)}`;
    api.addComponent(
      defaultComponent,
      {
        ...JSON.parse(
          JSON.stringify(defaultComponent.componentProps).replaceAll(
            "<<!name>>",
            name,
          ),
        ),
        name,
      },
      id,
    );
  };

  useCustomEffect(
    () => {
      if (defaultComponent && mode === "designer") {
        addComponent();
      }
    },
    [tabs?.length || 0],
    ([newLength], [oldLength]) => {
      return newLength <= oldLength;
    },
  );

  const handleOnMouseOver = (e) => {
    if (mode === "designer") {
      e.preventDefault();
      e.stopPropagation();
      apiContext.api.ChangeDropAreaId(id);
    }
  };

  const handleOnCloseTab = (e, tab) => {
    e.preventDefault();
    e.stopPropagation();
    const getLastElem = (array) => {
      if (!array || !array.length) {
        return undefined;
      }
      return array[array.length - 1];
    };
    const getFirstElem = (array) => {
      if (!array || !array.length) {
        return undefined;
      }
      return array[0];
    };
    Promise.all([
      updateProps({
        tabs: (tabs ?? []).filter((t) => t.name !== tab.name),
        childIndexMappings: (childIndexMappings ?? []).filter(
          (c) => !(c.tabId === tab.index && c.tabKey === tab.key),
        ),
      }),
      setActiveTab(
        tab.index !== activeTab?.index
          ? activeTab
          : tabs?.some((t) => t.index < tab.index)
            ? getLastElem(tabs?.filter((t) => t.index < tab.index))
            : getFirstElem(tabs?.filter((t) => t.index > tab.index)),
      ),
    ]);
  };

  const getChildIndex = () => {
    if (!tabs || !tabs?.length) {
      return undefined;
    }

    const mapping = childIndexMappings?.find(
      (c) => c.tabId === activeTab?.index && c.tabKey === activeTab?.key,
    );
    if (!mapping) {
      return undefined;
    }
    return mapping.childKey;
  };

  return (
    <div
      ref={nodeRef}
      {...restRootProps}
      style={{ ...rootProps.style, display: "flex", flexDirection: "column" }}
      data-value={
        mode === "designer"
          ? JSON.stringify({ depth: componentDefinition.depth, id })
          : undefined
      }
    >
      <Box className="tabs-box">
        <Box className="tabs-elem">
          {tabs
            ?.filter((tab) => {
              const tabPerm = componentPermissions.find((p) => {
                const permissionTabName = p.location.split("_")[0];
                return permissionTabName === tab.name;
              });

              return !tabPerm || (tabPerm && tabPerm.type === "Permitted");
            })
            ?.map((tab, index) => (
              <Draggable
                disabled={mode !== "designer"}
                key={`tab-${index}-${tab.name}`}
                onDragEnd={(_, dragDetails) =>
                  updateOrder(index, dragDetails.order)
                }
              >
                <Tab
                  text={tab.displayName}
                  editMode={mode === "designer"}
                  onEdit={(newName) =>
                    updateTab({ ...tab, displayName: newName })
                  }
                  isActive={activeTab && tab?.key === activeTab.key}
                  onClick={() => {
                    setActiveTab(tab);
                  }}
                  enableClose={mode === "designer"}
                  onClose={(e) => handleOnCloseTab(e, tab)}
                />
              </Draggable>
            ))}
          {mode === "designer" && (
            <IconButton
              variant="ghost"
              onClick={() => {
                const newIndex = tabs?.length
                  ? Math.max(...(tabs?.map((t) => t.index) ?? [])) + 1
                  : 1;
                Promise.all([
                  setActiveTab(null),
                  setTabs([
                    ...(tabs ?? []),
                    {
                      key: uuidv4(),
                      index: newIndex,
                      name: `tab${newIndex}`,
                      displayName: `tab${newIndex}`,
                    },
                  ]),
                ]);
              }}
            >
              <AddIcon />
            </IconButton>
          )}
        </Box>
      </Box>
      <Divider />
      {componentDefinition?.childrenComponents &&
        componentDefinition?.childrenComponents?.length &&
        componentDefinition?.childrenComponents.map((child) => {
          const childKey = getChildIndex();

          if (
            !disableLazyLoading &&
            !loadedChildKeys.includes(childKey) &&
            child.key === childKey
          ) {
            setLoadedChildKeys([...loadedChildKeys, childKey]);
          }

          return disableLazyLoading ||
            loadedChildKeys.includes(child.key) ||
            child.key === childKey ? (
            <Box
              key={child.key}
              style={{
                height: "100%",
                backgroundColor: "var(--chakra-colors-gray-50)",
                display: child.key === getChildIndex() ? undefined : "none",
              }}
            >
              {tabs?.length && activeTab ? (
                <ChildrenComponent
                  {...props}
                  mode={mode}
                  onMouseOver={handleOnMouseOver}
                  definition={child}
                />
              ) : null}
            </Box>
          ) : null;
        })}
    </div>
  );
};

TabsComponent.propTypes = {
  id: PropTypes.number,
  tabs: getTabsPropType(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      isVisible: PropTypes.bool,
      permissions: getEditorPropType(
        PropTypes.shape({
          propertyNameMap: PropTypes.string.isRequired,
          permissionCheckProcess: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            name: PropTypes.string,
          }),
        }),
      ),
    }),
  ),
  guid: PropTypes.string,
  nodeId: PropTypes.string,
  rootProps: PropTypes.any,
  childIndexMappings: PropTypes.array,
  disableLazyLoading: PropTypes.bool,
};

export default withBaseComponent(TabsComponent);
