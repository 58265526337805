import { Box } from "@chakra-ui/react";
import { withBaseComponent } from "../../../with-base-component";
import PropTypes from "prop-types";
import { GoogleMap } from "./components/google-map";
import { APIProvider } from "@vis.gl/react-google-maps";
import { API_KEY } from "./utils/variables";
import { useResolveProp } from "../../../../../hooks/resolve-prop/use-resolve-prop";

const MapComponent = (props) => {
  const {
    // dataSource,
    defaultCenter,
    disableSearch,
    value,
    defaultValue,
    // onChange,
    nodeRef,
    onMouseUp,
    onMouseDown,
    onTouchEnd,
    onMouseOver,
    onMouseLeave,
    onClick,
    style,
    designerMode,
  } = props;

  const defaultCenterValue = useResolveProp(defaultCenter, true);
  const disableSearchValue = useResolveProp(disableSearch, true);
  const defaultValueState = useResolveProp(defaultValue, true);

  return (
    <Box
      ref={nodeRef}
      onMouseUp={onMouseUp}
      onMouseDown={onMouseDown}
      onTouchEnd={onTouchEnd}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      style={
        designerMode
          ? style
          : {
              ...style,
              width: "500px",
              height: "500px",
              padding: "10px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "5px",
            }
      }
    >
      {!designerMode && API_KEY && (
        <APIProvider version="quarterly" apiKey={API_KEY}>
          <GoogleMap
            id="default-id"
            defaultCenter={defaultCenterValue || "Polska"}
            value={value || defaultValueState}
            markers={[]}
            searchBar={!disableSearchValue}
          />
        </APIProvider>
      )}
    </Box>
  );
};

MapComponent.propTypes = {
  id: PropTypes.any,
  name: PropTypes.string,
  value: PropTypes.string,
  dataSource: PropTypes.func,
  defaultValue: PropTypes.any,
  defaultCenter: PropTypes.string,
  defaultZoom: PropTypes.number,
  onChange: PropTypes.func,

  disableSearch: PropTypes.bool,

  onMouseUp: PropTypes.func,
  onMouseDown: PropTypes.func,
  onTouchEnd: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClick: PropTypes.func,

  rootProps: PropTypes.object,
  context: PropTypes.object,
  nodeRef: PropTypes.any,
  designerMode: PropTypes.bool,
  style: PropTypes.object,
};

export default withBaseComponent(MapComponent);
