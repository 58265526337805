export const initialRouterState = {
  activePage: null,
  openedPages: [],
  componentRegistry: [],
  activePagesList: [],
};

const getQueryParams = (uri) => {
  if (typeof uri === "string" && uri.includes("?")) {
    const result = {};
    const queryString = uri.split("?")[1];
    const queryParamsDictionary = queryString.split("&");
    for (let keyValuePair of queryParamsDictionary) {
      if (keyValuePair.includes("=")) {
        const [key, value] = keyValuePair.split("=");
        if (key) {
          result[key] = value;
        }
      }
    }
    return result;
  }
  return null;
};

const createPage = (action, state) => ({
  key: action.key,
  uri: action.uri,
  queryParams: getQueryParams(action.uri),
  displayName: action.displayName,
  state,
  componentProps: action.componentProps,
});

const setLocalStoragePages = (pages) =>
  localStorage.setItem("opened_pages", JSON.stringify(pages));

const getLocalStoragePages = () =>
  JSON.parse(localStorage.getItem("opened_pages"));

const clearLocalStoragePages = () => localStorage.removeItem("opened_pages");

const storePage = (page) => {
  const storedPages = getLocalStoragePages();
  let newPages;

  if (storedPages && storedPages.length > 0) newPages = [...storedPages, page];
  else newPages = [page];

  setLocalStoragePages(newPages);
};

const removeStoredPage = (key) => {
  const storedPages = getLocalStoragePages();
  let newPages = [];

  if (storedPages && storedPages.length > 0)
    newPages = storedPages.filter((page) => page.key !== key);

  if (newPages.length < 1) clearLocalStoragePages();
  else setLocalStoragePages(newPages);
};

const updateStoredPage = (key, displayName) => {
  const storedPages = getLocalStoragePages();
  if (storedPages && storedPages.length > 0) {
    const newPages = storedPages.map((page) =>
      page.key === key ? { ...page, displayName: displayName } : page,
    );
    setLocalStoragePages(newPages);
  }
};

export const routerReducer = (prevState, action) => {
  switch (action.type) {
    case "OPEN_PAGE": {
      const page = createPage(action, {
        __stateMounted: true,
        ...action.state,
      });

      storePage({ ...page, companyId: action.companyId });

      return {
        ...prevState,
        openedPages: [...prevState.openedPages, page],
        activePage: action.focus ? page : prevState.activePage,
        activePagesList: [...prevState.activePagesList, page],
      };
    }
    case "FOCUS_PAGE":
      return {
        ...prevState,
        activePage: prevState?.openedPages?.find((p) => p.key === action.key),
        activePagesList: [
          ...prevState.activePagesList.filter((p) => p.key !== action.key),
          prevState?.openedPages?.find((p) => p.key === action.key),
        ],
      };
    case "CLOSE_PAGE": {
      removeStoredPage(action.key);
      const activePagesList = prevState.activePagesList.filter(
        (p) => p.key !== action.key,
      );
      const activePage = activePagesList.find(
        (activePage, index) =>
          action.focusKey === activePage.key ||
          index === activePagesList.length - 1,
      );

      return {
        ...prevState,
        openedPages: prevState.openedPages.filter((p) => p.key !== action.key),
        activePage: activePage,
        activePagesList,
      };
    }
    case "UPDATE_DISPLAY_NAME": {
      updateStoredPage(action.key, action.displayName);
      return {
        ...prevState,
        openedPages: prevState.openedPages.map((p) => {
          if (p.key === action.key) {
            return { ...p, displayName: action.displayName };
          }
          return p;
        }),
        activePage: prevState.activePage
          ? { ...prevState.activePage, displayName: action.displayName }
          : null,
        activePagesList: prevState.activePagesList.map((p) => {
          if (p.key === action.key) {
            return { ...p, displayName: action.displayName };
          }
          return p;
        }),
      };
    }
    case "CLOSE_ALL_PAGES":
      clearLocalStoragePages();
      return initialRouterState;
    case "RESTORE_PAGES":
      return {
        ...prevState,
        openedPages: [...action.openedPages, ...prevState.openedPages],
        activePagesList: [...action.openedPages, ...prevState.openedPages],
      };
    case "GO_TO_START":
      return {
        ...prevState,
        activePage: null,
      };
    default:
      return prevState;
  }
};
