import { useEffect, useState } from 'react';

const sidePanelData = [
  {
    name: 'toolbox',
    displayName: 'Toolbox',
    order: 1,
    position: 'left',
  },
  {
    name: 'properties',
    displayName: 'Właściwości',
    order: 1,
    position: 'right',
  },
  {
    name: 'style-properties',
    displayName: 'Style',
    order: 2,
    position: 'right',
  },
  {
    name: 'designee-properties',
    displayName: 'Komponent',
    order: 3,
    position: 'right',
  },
];

const getPanelComponents = (data, panelComponents) => {
  const result = [];
  for (let componentIndex in data) {
    const component = data[componentIndex];
    const pComponent = panelComponents.find((p) => p.name === component.name);
    result.push({ ...component, ...pComponent });
  }
  return result;
};

export const useSidePanel = (
  data,
  panelComponents,
  updateCallback,
  dragMode
) => {
  const [state, setState] = useState(sidePanelData);
  const [updated, setUpdated] = useState(false);

  const handleOnComponentsChange = (newData) => {
    Promise.all([
      setUpdated(true),
      setState(
        newData.map((element) => ({
          name: element.name,
          displayName: element.displayName,
          order: element.order,
          position: element.position,
        }))
      ),
    ]);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted && data && !updated) {
      Promise.all([setState(data), setUpdated(true)]);
    }
    return () => (isMounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && updated && state) {
      updateCallback(state);
    }
    return () => (isMounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return [
    {
      components: getPanelComponents(state, panelComponents),
      dragMode,
      onComponentsChange: handleOnComponentsChange,
    },
  ];
};
