const getIsVisible = (conditions) => {
  if (conditions.some((c) => c)) {
    return false;
  }

  return true;
};

export const mergeColumns = (columns1, columns2) =>
  columns1.map((c1) => {
    const f = columns2.find((c2) => c1.name === c2.name);
    if (f) return { ...c1, ...f };

    const conditions = [c1.name.toLowerCase() === "id", columns2.length > 0];

    return { ...c1, isVisible: getIsVisible(conditions) };
  });
