import { parseDataValue } from "../../../../../../echo-components/component-designer/utils/data-value-parser";

export const useContainerComponent = (events, depth) => {
  const { onClick, onMouseOver, onMouseLeave } = events;

  const handleOnAreaClick = (event) => {
    event.preventDefault();
    event.stopPropagation(); // TODO not tested
    const dataValue = event.target.getAttribute("data-value");
    if (
      dataValue &&
      parseDataValue(dataValue) !== false &&
      parseDataValue(dataValue).depth === depth
    ) {
      onClick(event);
    }
  };

  const handleAreaOnMouseOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const dataValue = event.target.getAttribute("data-value");
    if (
      dataValue &&
      parseDataValue(dataValue) !== false &&
      parseDataValue(dataValue).depth === depth
    ) {
      onMouseOver(event);
    }
  };

  const handleAreaOnMouseLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const dataValue = event.target.getAttribute("data-value");
    if (
      dataValue &&
      parseDataValue(dataValue) !== false &&
      parseDataValue(dataValue).depth === depth
    ) {
      onMouseLeave(event);
    }
  };

  return {
    handleAreaOnMouseLeave,
    handleAreaOnMouseOver,
    handleOnAreaClick,
  };
};
