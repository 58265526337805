import {
  Box,
  Button,
  Checkbox,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";

import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useState } from "react";

export const ListEventListenersSelect = (props) => {
  const { elements, onChange, propValue } = props;
  const [selectedEvents, setSelectedEvents] = useState(propValue || []);

  const { onOpen, onClose, isOpen } = useDisclosure();

  const getEvents = (props) => {
    const keys = Object.keys(props);
    const events = keys.filter(
      (k) => props[k]?.type === "PROCESS" && k !== "dataSource",
    );

    return events;
  };

  const mapElement = useCallback((element) => {
    const { componentProps } = element;

    const events = getEvents(componentProps);

    return {
      name: componentProps.name,
      events,
    };
  }, []);

  const options = useMemo(
    () => elements?.map(mapElement).filter((el) => el.events.length !== 0),
    [elements, mapElement],
  );

  const handleSelect = (option) => {
    setSelectedEvents((prev) => {
      const found = prev.find(
        (opt) => opt.name === option.name && opt.event === option.event,
      );
      if (found)
        return prev.filter(
          (opt) => !(opt.name === option.name && opt.event === option.event),
        );
      else return [...prev, option];
    });
  };

  useEffect(() => {
    onChange(selectedEvents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEvents]);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
      >
        <Button onClick={onOpen} color="none" variant="ghost" size="md">
          {selectedEvents && selectedEvents.length}
          ...
        </Button>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Event Listeners Select</ModalHeader>
          <ModalBody>
            <OptionsList
              options={options}
              selectedOptions={selectedEvents}
              onOptionSelect={handleSelect}
            />
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
};

ListEventListenersSelect.propTypes = {
  elements: PropTypes.array,
  onChange: PropTypes.func,
  propName: PropTypes.string,
  propType: PropTypes.string,
  propValue: PropTypes.string,
};

const OptionsList = ({ options, selectedOptions, onOptionSelect }) => {
  return (
    <List
      width="100%"
      display="flex"
      flexWrap="wrap"
      gap="20px"
      alignItems="flex-start"
    >
      {options &&
        options.length > 0 &&
        options.map((opt) => (
          <ListItem
            key={opt.name}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            {opt.name}
            <List>
              {opt.events.map((ev) => (
                <EventListItem
                  onSelect={() => onOptionSelect({ name: opt.name, event: ev })}
                  isSelected={
                    selectedOptions &&
                    selectedOptions.find(
                      (el) => el.name === opt.name && el.event === ev,
                    )
                      ? true
                      : false
                  }
                  key={`${opt.name}-${ev}`}
                >
                  {ev}
                </EventListItem>
              ))}
            </List>
          </ListItem>
        ))}
    </List>
  );
};

OptionsList.propTypes = {
  options: PropTypes.array,
  selectedOptions: PropTypes.array,
  onOptionSelect: PropTypes.func,
};

const EventListItem = ({ children, isSelected, onSelect }) => {
  return (
    <ListItem display="flex" alignItems="center" gap="5px">
      <Checkbox isChecked={isSelected} onChange={onSelect} />
      <Box cursor="pointer" onClick={onSelect}>
        {children}
      </Box>
    </ListItem>
  );
};

EventListItem.propTypes = {
  children: PropTypes.any,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
};
