// @flow

import * as React from "react";
import { Box, Button, Text } from "@chakra-ui/react";
import { format } from "date-fns";
import pl from "date-fns/locale/pl";
import {
  MdOutlineKeyboardArrowRight,
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
  MdOutlineKeyboardDoubleArrowLeft,
} from "react-icons/md";

const formatDate = (date: Date, formatString: string) =>
  format(date, formatString, { locale: pl });

type HeaderProps = {
  date: Date,
  onDateChange: (
    type: "PREV_YEAR" | "PREV_MONTH" | "NEXT_MONTH" | "NEXT_YEAR",
  ) => void,
};

export const CalendarNav = (props: HeaderProps) => {
  const { date, onDateChange } = props;

  const handleNextMonth = () => onDateChange("NEXT_MONTH");
  const handlePrevMonth = () => onDateChange("PREV_MONTH");
  const handleNextYear = () => onDateChange("NEXT_YEAR");
  const handlePrevYear = () => onDateChange("PREV_YEAR");

  const getHeaderText = (date: Date) => {
    return `${formatDate(date, "MMM")} ${formatDate(date, "yyyy")}`;
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      gap="10px"
      width="100%"
    >
      <Button
        onClick={handlePrevYear}
        variant="ghost"
        display="flex"
        alignItems="center"
        justifyContent="center"
        padding="0"
        margin="0"
      >
        <MdOutlineKeyboardDoubleArrowLeft fontSize="18px" />
      </Button>
      <Button
        onClick={handlePrevMonth}
        variant="ghost"
        display="flex"
        alignItems="center"
        justifyContent="center"
        padding="0"
        margin="0"
      >
        <MdOutlineKeyboardArrowLeft fontSize="18px" />
      </Button>
      <Text>{getHeaderText(date)}</Text>
      <Button
        onClick={handleNextMonth}
        variant="ghost"
        display="flex"
        alignItems="center"
        justifyContent="center"
        padding="0"
        margin="0"
      >
        <MdOutlineKeyboardArrowRight fontSize="18px" />
      </Button>
      <Button
        onClick={handleNextYear}
        variant="ghost"
        display="flex"
        alignItems="center"
        justifyContent="center"
        padding="0"
        margin="0"
      >
        <MdOutlineKeyboardDoubleArrowRight fontSize="18px" />
      </Button>
    </Box>
  );
};
