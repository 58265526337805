import { useCustomEffect, arrayEqual } from "@echo/tools";
import { useState } from "react";
import useOnScreen from "./use-on-screen";

const getDimensions = (node) => {
  if (node?.getBoundingClientRect) {
    const { x, y, width, height } = node.getBoundingClientRect();
    let t;
    const scrollX = (
      ((t = document.documentElement) || (t = document.body.parentNode)) &&
      typeof t.scrollLeft == "number"
        ? t
        : document.body
    ).scrollLeft;
    const scrollY = (
      ((t = document.documentElement) || (t = document.body.parentNode)) &&
      typeof t.scrollTop == "number"
        ? t
        : document.body
    ).scrollTop;
    return {
      x: x + scrollX,
      y: y + scrollY,
      height,
      width,
    };
  }
  return null;
};

export const useRefDimensions = (
  ref,
  { reactOnResize = false, reactWhenNotVisible = false } = {},
  deps = [],
) => {
  const [componentDimensions, setComponentDimensions] = useState({
    width: 0,
    height: 0,
  });

  const [isVisible] = useOnScreen(ref);

  useCustomEffect(
    () => {
      const resizeFunc = () => {
        if ((reactWhenNotVisible && !isVisible) || isVisible) {
          const dimensions = getDimensions(ref?.current);
          if (dimensions) {
            setComponentDimensions(dimensions);
          }
        }
      };

      resizeFunc();

      if (reactOnResize) {
        window.addEventListener("resize", resizeFunc);
      }

      return () => {
        if (reactOnResize) {
          window.removeEventListener("resize", resizeFunc);
        }
      };
    },
    [deps, isVisible, reactOnResize, reactWhenNotVisible, ref],
    (a1, a2) => {
      const [deps1, ...arr1] = a1;
      const [deps2, ...arr2] = a2;

      return arrayEqual(deps1, deps2) && arrayEqual(arr1, arr2);
    },
  );

  return [componentDimensions, isVisible];
};
