import LinkComponent from "../components/link/link-component";
import ProcessLinkEdit from "../components/modals/process-link-edit";
import {
  fixDestinationProcessLinkCoordinates,
  fixSourceProcessLinkCoordinates,
} from "./coordinates";

export const getProcessLinkComponent = (
  processLink,
  processBlocks,
  onUpdate,
  onDelete,
  stateProps,
) => {
  const sourceCoordinates = processBlocks.find(
    (processBlock) => processBlock.id === processLink.sourceProcessBlockId,
  )?.position;

  const destinationCoordinates = processBlocks.find(
    (processBlock) => processBlock.id === processLink.destinationProcessBlockId,
  )?.position;
  return (
    <LinkComponent
      label={processLink.condition}
      editModalComponent={(modalProps) =>
        modalProps.isOpen ? (
          <ProcessLinkEdit
            key={processLink.id}
            {...modalProps}
            processLink={processLink}
            onEdit={onUpdate}
            onDelete={onDelete}
            processBlocks={processBlocks}
            stateProps={stateProps}
          />
        ) : null
      }
      from={fixSourceProcessLinkCoordinates(sourceCoordinates)}
      to={fixDestinationProcessLinkCoordinates(destinationCoordinates)}
    />
  );
};
