import {
  getSetting,
  saveSetting,
} from "../../../../../../../services/user-services";
import UserContext from "../../../../../../../user-context";
import { useContext, useEffect, useReducer, useRef, useState } from "react";
// import { useDebounce } from "../../../../../../../utils/debounce/use-debounce";
import { showErrorToast } from "../../../../../echo-error-toast";
import {
  dataGridReducer,
  getInitialDataGridState,
} from "../reducer/datagrid-reducer";
// import { checkProperties } from "../utils/check-properties";
import { loadDatagridData } from "../utils/data-loader/data-loader";
import { useDataGridEvents } from "./use-datagrid-events";
import { useDataGridListener } from "./use-datagrid-listener";
import { useDataGridDataLoader } from "./use-datagrid-loader";
import { useColumns } from "./use-columns";
import { uuidv4 } from "@echo/tools";
import { usePageSize } from "./use-page-size";
import {
  getColumnWithCtrl,
  getColumnWithoutCtrl,
  getSortingOrder,
} from "../utils/columns/columns-sort";

export const useDataGrid = (props) => {
  const {
    dataSource,
    onRowEdit,
    value,
    context,
    columns,
    designerMode,
    deps,
    enableInlineEdit,
    refreshPolicy,
    columnKey,
  } = props;
  const { userId, contextId, permissions } = useContext(UserContext);
  const userRoleId = (permissions.length && permissions[0]?.role?.id) || null;
  const loadTokenRef = useRef({ token: null });

  const { itemsPerPage, savePageSize } = usePageSize({
    ownerId: props.name || "%",
    key: dataSource
      ? `${dataSource.id}_${
          columnKey || dataSource.key || dataSource?.staticParams?.key || "%"
        }`
      : "",
    saveSetting: (id, key, itemsPerPage) =>
      saveSetting({
        id,
        userId: contextId || userId,
        key,
        value: JSON.stringify(itemsPerPage),
      }),
    getSetting: (key) => getSetting(contextId || userId, null, key),
    isActive:
      context?.routerContext?.getActivePage()?.key === context?.page?.key,
  });

  const [state, dispatch] = useReducer(
    dataGridReducer,
    getInitialDataGridState({ itemsPerPage }),
  );

  const handleSortObject = (sortObject) =>
    dispatch({ type: "SET_SORT_OBJECT", sortObject });

  const [columnsState, storeColumns, columnSettingKey] = useColumns(
    state?.source,
    props.name || "%",
    state?.source?.queryId,
    dataSource
      ? `${dataSource.id}_${
          columnKey || dataSource.key || dataSource?.staticParams?.key || "%"
        }`
      : "",
    (key) => getSetting(contextId || userId, userRoleId, key),
    async (id, key, columns, user, role, type) =>
      await saveSetting(
        {
          id,
          userId: user ? user : contextId || userId,
          roleId: role,
          key,
          value: JSON.stringify(columns),
        },
        type || "user",
      ),
    null,
    context?.routerContext?.getActivePage()?.key === context?.page?.key,
    [
      !!state.source,
      props.name,
      dataSource?.id,
      dataSource?.key,
      dataSource?.staticParams?.key,
      getSetting,
    ],
    handleSortObject,
  );

  const [error, setError] = useState(false);

  const handlePageSize = (pageSize) =>
    dispatch({ type: "SET_PAGE_SIZE", payload: pageSize });

  useEffect(() => {
    handlePageSize(itemsPerPage);
  }, [itemsPerPage]);

  const handleOnGlobalFilterChange = (filter) =>
    dispatch({ type: "UPDATE_GLOBAL_FILTER", filter });

  const handleOnSortChange = (column, ctrlKey) =>
    dispatch({ type: "UPDATE_COLUMN_SORT", column, ctrlKey });

  const handleOnReversePage = () =>
    dispatch({ type: "CHANGE_PAGE", page: state.page - 1 });

  const handleOnForwardPage = () =>
    dispatch({ type: "CHANGE_PAGE", page: state.page + 1 });

  const handleChangePage = (page) => dispatch({ type: "CHANGE_PAGE", page });

  const handleRefreshData = () => dispatch({ type: "REFRESH_DATA" });

  const handleOnRowEdit = (newRow) => {
    if (onRowEdit) {
      Promise.resolve(onRowEdit(newRow)).catch((err) => {
        showErrorToast(
          err.reasonTitle
            ? err
            : {
                reasonTitle:
                  "Error occurred while performing edit action on datagrid row",
                location: "Row edit process",
                shortMessage: err.toString().slice(0, 300),
                message: err.toString(),
              },
        );
      });
    }
  };

  const handleUpdateRow = (newRow, id) => {
    Promise.all([
      dispatch({ type: "UPDATE_ROW", newRow, id }),
      handleOnRowEdit(newRow),
    ]);
  };

  const handleUpdateColumnSorting = (column, ctrlKey) => {
    const columnsShallowCopy = [...(dataSource ? columnsState : columns)];
    const sortingOrder = getSortingOrder(columnsShallowCopy);
    const newColumns = columnsShallowCopy.map((col) => {
      if (ctrlKey) return getColumnWithCtrl(col, column, sortingOrder);
      return getColumnWithoutCtrl(col, column, 0);
    });

    storeColumns(newColumns);
  };

  const handleUpdateFilterOptions = (filterOptions) =>
    dispatch({ type: "UPDATE_FILTER_OPTIONS", filterOptions });

  const handleOnColumnFiltersChange = (columnFilters) => {
    dispatch({ type: "UPDATE_COLUMN_FILTERS", columnFilters });
  };
  useEffect(() => {
    if (props.filterColumnName) {
      dispatch({
        type: "UPDATE_COLUMN_FILTERS",
        columnFilters: {
          [props.filterColumnName]: { value: props.filterValue },
        },
      });
    }
  }, [props.filterColumnName, props.filterValue]);

  const handleUpdateColumnSize = (newSizePx, columnDef) => {
    const columnsShallowCopy = [...(dataSource ? columnsState : columns)];
    const colDefIdx = columnsShallowCopy.findIndex(
      (c) => c.name === columnDef.name,
    );

    columnsShallowCopy[colDefIdx].width = `${newSizePx}px`;

    storeColumns(columnsShallowCopy);
  };

  const handleUpdateColumnOrder = (newColumns) => {
    storeColumns(newColumns);
  };

  const handleHideColumn = (columnIndex) => {
    const columnsShallowCopy = [...(dataSource ? columnsState : columns)];

    if (columnsShallowCopy[columnIndex]) {
      columnsShallowCopy[columnIndex].isVisible = false;
    }
    storeColumns(columnsShallowCopy);
  };

  const loadData = (callback) => {
    if (
      !error &&
      (context?.state?.__mounted ||
        (!context?.state?.__mounted && !dataSource && value))
    ) {
      const token = uuidv4();
      loadTokenRef.current.token = token;

      loadDatagridData({
        value,
        dataSource,
        state,
        columns,
        context,
      })
        .then((result) => {
          if (result && token === loadTokenRef.current.token) {
            callback(result);
          }
        })
        .catch((err) => {
          showErrorToast(err);
          setError(true);
        });
    }
  };

  useDataGridEvents(props, state);

  useDataGridDataLoader(
    designerMode,
    loadData,
    dispatch,
    value,
    dataSource,
    state.page,
    state.itemsPerPage,
    state.sort,
    state.refreshKey,
    state.columnFilters,
    error,
    context?.state?.__mounted,
    context?.state?.formSource,
    deps,
    state.filterOptions,
    state.columnFilters,
  );

  useDataGridListener(
    designerMode,
    loadData,
    dispatch,
    value,
    dataSource,
    state.page,
    state.itemsPerPage,
    state.sort,
    state.columnFilters,
    state.globalFilter,
    error,
    context?.state?.__mounted,
    enableInlineEdit ? "disabled" : refreshPolicy,
    context?.state?.formSource,
    deps,
  );

  return [
    {
      ...state,
      columnDefinitions: dataSource ? columnsState : columns,
    },
    {
      actions: {
        onGlobalFilterChange: handleOnGlobalFilterChange,
        handlePageSize,
        savePageSize,
      },
      header: {
        onSortChange: handleOnSortChange,
        updateSorting: handleUpdateColumnSorting,
        refreshDatagrid: handleRefreshData,
      },
      footer: {
        onReversePage: handleOnReversePage,
        onForwardPage: handleOnForwardPage,
        onPageChange: handleChangePage,
      },
      columns: {
        updateColumns: storeColumns,
        updateColumnSize: handleUpdateColumnSize,
        updateColumnOrder: handleUpdateColumnOrder,
        hideColumn: handleHideColumn,
      },
      row: {
        onEdit: handleUpdateRow,
      },
      filterOptions: {
        value: state.filterOptions,
        onChange: handleUpdateFilterOptions,
        columnFilters: state.columnFilters,
        onColumnFiltersChange: handleOnColumnFiltersChange,
      },
    },
    false,
    { columnSettingKey },
  ];
};
