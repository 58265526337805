import { calculateProp } from "../../../../../../../echo-component/function-wrapper/calculate-prop";
import { showErrorToast } from "../../../../../echo-error-toast";

export const withoutId = (obj) => {
  const keys = Object.keys(obj);

  const newObj = keys.reduce(
    (acc, curr) =>
      curr.toLowerCase().includes("id") ? acc : { ...acc, [curr]: obj[curr] },
    {},
  );

  return newObj;
};

export const getValue = (state, name) => (state ? state[name] : null);

const getResultState = (prevState, newVal, propName) => {
  let result = { ...prevState };

  if (typeof newVal === "string" || typeof newVal === "number")
    result = { ...result, [propName]: newVal };
  else result = { ...result, ...newVal };

  return result;
};

export const getFunc = (
  func,
  state,
  setState,
  eventName,
  stateName,
  rowEvent,
) => {
  const { callback, listeners } = rowEvent;
  return async (val) => {
    if (eventName === "onClick") {
      await func(state);
      return;
    }

    const newState = getResultState(state, val, stateName);

    const funcRes = await func(newState);

    setState((prev) => ({ ...prev, ...newState, ...funcRes }));

    const found = listeners.find(
      (el) => el.name === stateName && el.event === eventName,
    );

    if (!found || !callback) return;

    const res = await callback(newState).catch(showErrorToast);
    setState((prev) => ({ ...prev, ...res }));
  };
};

const getBaseProps = (props) => {
  const keys = Object.keys(props);

  const newProps = keys.reduce((acc, curr) => {
    const prop = props[curr];
    if (prop?.type === "PROCESS") return acc;
    return { ...acc, [curr]: prop };
  }, {});

  return newProps;
};

const getProcessProps = (
  props,
  context,
  child,
  state,
  setState,
  stateName,
  rowEditEvent,
) => {
  const keys = Object.keys(props);
  const newProps = keys.reduce((acc, curr) => {
    const prop = props[curr];

    if (prop?.type !== "PROCESS" || curr === "dataSource") {
      return { ...acc, [curr]: prop };
    }

    const func = calculateProp(prop, context, props, child.id);
    const buildFunc = getFunc(
      func,
      state,
      setState,
      curr,
      stateName,
      rowEditEvent,
    );

    return { ...acc, [curr]: buildFunc };
  }, {});

  return newProps;
};

export const getProps = (child, context, state, setState, rowEditEvent) => {
  const { componentProps } = child;
  const { name } = componentProps;
  const baseProps = getBaseProps(componentProps);
  const processProps = getProcessProps(
    componentProps,
    context,
    child,
    state,
    setState,
    name,
    rowEditEvent,
  );
  const value = getValue(state, name);

  return {
    ...baseProps,
    ...processProps,
    value,
  };
};
