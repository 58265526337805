import { Box } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { GRID_TEMPLATE_ROWS } from "../utils/calendar-utils";

export const RowLabels = ({ labels }) => {
  return (
    <Box display="grid" gridTemplateRows={GRID_TEMPLATE_ROWS} opacity="0">
      {labels.map((h) => (
        <Box transform="translateY(-12px)" fontSize="18px" key={h}>
          {h}
        </Box>
      ))}
    </Box>
  );
};

RowLabels.propTypes = {
  labels: PropTypes.array,
};
