// eslint-disable-next-line no-undef
export const API_KEY = process.env.GOOGLE_MAPS_API_KEY;
export const INITIAL_MAP_CENTER = { lat: 52, lng: 19 };
export const INITIAL_MAP_ZOOM = 6;
export const DEFAULT_ZOOM = 12;
export const ZOOM_LEVELS = {
  country: 5,
  administrative_area_level_1: 7,
  locality: 10,
  sublocality: 13,
  route: 15,
  premise: 17,
};
