import { Select } from "@chakra-ui/react";
import PropTypes from "prop-types";

export const ComboEditComponent = ({ value, options, onChange }) => {
  const handleChange = (e) => {
    const value = e.target.value;
    onChange(value);
  };

  return (
    <Select value={value} onChange={handleChange}>
      <option value="" disabled></option>
      {options.map((opt, idx) => (
        <option value={opt} key={idx}>
          {opt}
        </option>
      ))}
    </Select>
  );
};
ComboEditComponent.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
};
