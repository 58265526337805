import PropTypes from "prop-types";
import { Box, Collapse, IconButton, useDisclosure } from "@chakra-ui/react";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";
import ContextMenu from "../../../../context-menu/context-menu";

const checkSelection = (selection, valuePath) => {
  if (selection && valuePath && selection === valuePath) {
    return true;
  }
  if (Array.isArray(selection) && Array.isArray(valuePath)) {
    if (valuePath.length !== selection.length) {
      return false;
    }

    for (let i = 0; i < valuePath.length; i++) {
      if (
        selection[i].path !== valuePath[i].path ||
        selection[i].label !== valuePath[i].label
      ) {
        return false;
      }
    }
  } else {
    return false;
  }
  return true;
};

const TreeElement = ({
  valuePath,
  displayName,
  child,
  onSelectChange,
  state,
  contextMenuDataSource,
  context,
  componentInfo,
}) => {
  const { isOpen, onToggle } = useDisclosure();

  const CollapseButton =
    child &&
    Object.keys(child).filter((displayName) => !displayName.startsWith("__"))
      .length > 0 ? (
      <IconButton variant="ghost" size="sm" onClick={onToggle}>
        {isOpen ? <FiChevronDown /> : <FiChevronRight />}
      </IconButton>
    ) : (
      <span style={{ width: "32px", height: "32px", flexShrink: 0 }} />
    );

  return (
    <Box>
      <ContextMenu
        {...componentInfo}
        context={context}
        dataSource={contextMenuDataSource}
        sourceParams={Array.isArray(valuePath) ? valuePath.at(-1) : {}}
      >
        <Box
          display="flex"
          flexFlow="row"
          alignItems="center"
          alignContent="center"
          fontWeight={
            checkSelection(state.selection, valuePath) ? "bold" : undefined
          }
        >
          {CollapseButton}
          <Box
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            cursor="pointer"
            _hover={{ color: "var(--chakra-colors-gray-600)" }}
            onClick={() => onSelectChange(valuePath)}
          >
            {displayName}
          </Box>
        </Box>
      </ContextMenu>
      {child &&
        Object.keys(child).filter(
          (displayName) => !displayName.startsWith("__"),
        ).length > 0 && (
          <Collapse in={isOpen} animateOpacity>
            <Box paddingLeft="8px">
              {Object.keys(child)
                .filter((displayName) => !displayName.startsWith("__"))
                .map((displayName, index) => {
                  const nestedChild = child[displayName];
                  return (
                    <TreeElement
                      key={`tree-element-${index}-${nestedChild.__meta.__path}`}
                      displayName={displayName}
                      onSelectChange={onSelectChange}
                      child={nestedChild}
                      valuePath={nestedChild.__meta}
                      state={state}
                      context={context}
                      contextMenuDataSource={contextMenuDataSource}
                      componentInfo={componentInfo}
                    />
                  );
                })}
            </Box>
          </Collapse>
        )}
    </Box>
  );
};

TreeElement.propTypes = {
  valuePath: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
    }),
  ),
  displayName: PropTypes.string.isRequired,
  child: PropTypes.shape({
    __meta: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.string,
      }),
    ),
  }),
  onSelectChange: PropTypes.func.isRequired,
  state: PropTypes.shape({
    selection: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    ),
  }),
  context: PropTypes.any,
  contextMenuDataSource: PropTypes.any,
  componentInfo: PropTypes.shape({
    id: PropTypes.number,
    guid: PropTypes.string,
    nodeId: PropTypes.string,
  }),
};

export default TreeElement;
