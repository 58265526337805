const check = (buffers) => {
  const signature = [0x25, 0x50, 0x44, 0x46, 0x2d];
  for (let i = 0, len = signature.length; i < len; i++) {
    if (buffers[i] !== signature[i] && signature[i] !== undefined) return false;
  }
  return true;
};

const getArrayBuffer = (file, start = 0, end = 32) => {
  return new Promise((reslove, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = (e) => {
        const buffers = new Uint8Array(e.target.result);
        reslove(buffers);
      };
      reader.onerror = (err) => reject(err);
      reader.onabort = (err) => reject(err);
      reader.readAsArrayBuffer(file.slice(start, end));
    } catch (err) {
      reject(err);
    }
  });
};

const isPdf = async (file) => {
  const buffers = await getArrayBuffer(file);

  return check(buffers);
};

export default isPdf;
