import React, { useRef, useState } from "react";
import Draggable from "react-draggable";
import PropTypes from "prop-types";
import { getComponent } from "../../../../../../../services/components-service";
import { uuidv4 } from "@echo/tools";

const getProps = (definition, defaultProps) => {
  if (Object.keys(defaultProps).length > 0) return defaultProps;
  const name = `${definition.name.replaceAll("\n", "")}_${uuidv4()
    .toString()
    .substring(0, 6)}`;

  if (!definition.componentProps.value) {
    definition.componentProps.value = {
      id: "formSource.<name>",
      name: "formSource.<name>",
      type: "STATE_PROP",
    };
  }

  return {
    ...JSON.parse(
      JSON.stringify(definition.componentProps).replaceAll("<<!name>>", name),
    ),
    name,
  };
};

const ToolboxItem = ({
  onDropItem,
  displayName,
  item,
  onStart,
  onStop,
  onDrag,
  dragMode = false,
  defaultProps = {},
}) => {
  const nodeRef = useRef(null);
  const [isDrag, setIsDrag] = useState(false);

  const handleOnDragStop = async () => {
    const definition = await getComponent({ id: item.id });
    const props = getProps(definition, defaultProps);

    await Promise.all([
      setIsDrag(false),
      onDropItem(definition, props),
      onStop(),
    ]);
  };

  const handleOnStart = (e) => {
    Promise.all([onStart(e), setIsDrag(true)]);
  };

  return (
    <Draggable
      nodeRef={nodeRef}
      position={{ x: 0, y: 0 }}
      onStart={handleOnStart}
      onStop={handleOnDragStop}
      onDrag={onDrag}
    >
      <div
        ref={nodeRef}
        style={{
          display: "block",
          visibility: (dragMode && isDrag) || !dragMode ? "visible" : "hidden",
        }}
        className={`box ${dragMode ? "cursor-move" : "cursor-pointer-hover"}`}
      >
        {displayName ? displayName : item.displayName}
      </div>
    </Draggable>
  );
};

ToolboxItem.propTypes = {
  dragMode: PropTypes.bool,
  displayName: PropTypes.string.isRequired,
  onDropItem: PropTypes.func.isRequired,
  item: PropTypes.object,
  definition: PropTypes.object,
  defaultProps: PropTypes.object,
  onStart: PropTypes.func.isRequired,
  onStop: PropTypes.func.isRequired,
  onDrag: PropTypes.func.isRequired,
};

export default ToolboxItem;
