import PropTypes from "prop-types";
import { withBaseComponent } from "../../../with-base-component";
// import { useDebouncedValueState } from "../../../../../hooks/shared/use-debounced-value-state";
// import { shimFunctions } from "../../../../../../utils/shims/shims";
import LabeledCheckboxFormControl from "../../../../labeled-checkbox-form-control/labeled-checkbox-from-control";
import { useResolveProp } from "../../../../../hooks/resolve-prop/use-resolve-prop";

const CheckboxComponent = ({
  rootProps,
  label,
  isReadOnly,
  value,
  onChange,
  name,
  tabOrder,
}) => {
  const isReadOnlyState = useResolveProp(isReadOnly, true);

  const { nodeRef, onClick, ...restRootProps } = rootProps;

  const handleChange = (e) => {
    const value = e === true ? 1 : 0;
    onChange(value);
  };

  return (
    <LabeledCheckboxFormControl
      id={name}
      nodeRef={nodeRef}
      isReadOnly={isReadOnlyState}
      value={value}
      rootProps={restRootProps}
      onClick={onClick}
      label={label}
      onChange={handleChange}
      tabIndex={parseFloat(tabOrder)}
      autoFocus={parseFloat(tabOrder) == 1}
    />
  );
};

CheckboxComponent.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  tabOrder: PropTypes.string,
  isReadOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  rootProps: PropTypes.any,
  disabled: PropTypes.bool,
};

export default withBaseComponent(CheckboxComponent);
