import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import EchoRouterSwitchContext from "./echo-router-switch-context";
import EchoRouterRouteContext from "./echo-router-route-context";
import { Box } from "@chakra-ui/react";
import { decodeUrl } from "./echo-router";

const comparePaths = (path, pathName) => {
  const pathElements = path.split("/");
  const pathNameElements = pathName.split("/");
  if (pathElements.length !== pathNameElements.length) {
    return false;
  }
  for (let i = 0; i < pathElements.length; i += 1) {
    if (
      !pathElements[i].startsWith(":") &&
      pathElements[i] !== pathNameElements[i].split("?")[0]
    ) {
      return false;
    }
  }
  return true;
};

const EchoRouterRoute = ({ path, children, unstacked }) => {
  const [initialOpen, setInitialOpen] = useState(true);

  const pathName = window.location.pathname;

  const decodedPathName =
    pathName.startsWith("/ZWNobw==") || pathName.startsWith("ZWNobw==")
      ? decodeUrl(pathName)?.path
      : pathName;

  const { openedPagesUris } = useContext(EchoRouterSwitchContext);

  const compared = comparePaths(path, decodedPathName);

  useEffect(() => {
    if (compared) {
      setInitialOpen(false);
    }
  }, [compared]);

  return (
    (unstacked || openedPagesUris.some((p) => comparePaths(path, p))) && (
      <Box
        display={comparePaths(path, decodedPathName) ? "flex" : "none"}
        width="100%"
        minHeight={0}
        flexDirection="column"
        boxSizing="border-box"
        flex={1}
      >
        <EchoRouterRouteContext.Provider
          value={{
            path,
            pathName: decodedPathName,
            active: comparePaths(path, decodedPathName),
          }}
        >
          {!initialOpen && children}
        </EchoRouterRouteContext.Provider>
      </Box>
    )
  );
};

EchoRouterRoute.propTypes = {
  path: PropTypes.string.isRequired,
  unstacked: PropTypes.bool,
  children: PropTypes.node,
};

export default EchoRouterRoute;
