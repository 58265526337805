import { Box } from "@chakra-ui/react";
import PropTypes from "prop-types";

const ColumnResizer = ({
  contentDimensions,
  columnWidth,
  onSizeChange,
  commitChange,
}) => {
  let resizing = false;
  let x = 0;

  const handleMouseDown = (e) => {
    e.target.setPointerCapture(e.pointerId);
    resizing = true;
    x = e.clientX;
  };

  const handleMouseMove = (e) => {
    if (resizing) {
      const dx = e.clientX - x;
      onSizeChange(columnWidth + dx);
    }
  };
  const handleMouseUp = (e) => {
    e.target.releasePointerCapture(e.pointerId);
    const dx = e.clientX - x;
    commitChange(columnWidth + dx);
    resizing = true;
  };

  return (
    <Box
      style={{
        height: contentDimensions?.height || 35,
        position: "absolute",
        top: 0,
        right: 0,
        width: "5px",
        cursor: "col-resize",
        userSelect: "none",
        borderRight: resizing
          ? "2px solid var(--chakra-colors-lightGrayCa)"
          : undefined,
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onDrag={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onDragStart={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onDragEnd={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onPointerDown={handleMouseDown}
      onPointerMove={handleMouseMove}
      onPointerUp={handleMouseUp}
      _hover={{ borderRight: "2px solid var(--chakra-colors-lightGrayCa)" }}
    />
  );
};

ColumnResizer.propTypes = {
  contentDimensions: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
  }),
  columnWidth: PropTypes.number,
  onSizeChange: PropTypes.func,
  commitChange: PropTypes.func,
};

export default ColumnResizer;
