import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { CalendarsList } from "../available-calendars-list/calendars-list";

export const CalendarSettingsModal = ({
  onClose,
  isOpen,
  availableCalendars,
  changeCalendarVisibility,
  changeCalendarBackground,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader></ModalHeader>
        <ModalBody>
          <CalendarsList
            onCalendarVisibilityChange={changeCalendarVisibility}
            onCalendarBackgroundChange={changeCalendarBackground}
            calendars={availableCalendars}
          />
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};

CalendarSettingsModal.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  availableCalendars: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
  ),
  changeCalendarVisibility: PropTypes.func,
  changeCalendarBackground: PropTypes.func,
};
