import { dataFilter } from "../data-filter";
import { getOrderBy } from "../data-view-utils";
import { executeBlock } from "../../../../../../../../process-executor/process-executor";

const resolveDetailedData = (resultData) => {
  const cData = resultData.data;

  const elementsCount = resultData?.metadata?.itemsCount || 0;

  return {
    type: "SET_DATA",
    data: cData.map((elem) => {
      if (elem["RecCount"]) {
        delete elem["RecCount"];
      }
      return elem;
    }),
    source: resultData,
    count: isNaN(elementsCount) ? 0 : Number(elementsCount),
  };
};

const resolveDataArray = (resultData, state) => {
  const cData = Array.isArray(resultData)
    ? resultData.length > state.itemsPerPage
      ? resultData
          .orderBy(getOrderBy(state.sort))
          .filter((item) => dataFilter(state.globalFilter, item))
          .slice(
            state.page * 20,
            state.page * state.itemsPerPage + state.itemsPerPage,
          )
      : resultData
    : [];

  const elementsCount =
    (Array.isArray(cData) && cData.length
      ? (cData.find((elem) => elem["elements_count"]) || {})["elements_count"]
      : resultData.length) || resultData.length;

  return {
    type: "SET_DATA",
    data: cData.map((elem) => {
      if (elem["RecCount"]) {
        delete elem["RecCount"];
      }
      return elem;
    }),
    source: resultData,
    count: isNaN(elementsCount) ? 0 : Number(elementsCount),
  };
};

export const processDataLoader = ({ dataSource, state, columns, context }) =>
  new Promise((resolve, reject) => {
    executeBlock(
      context,
      dataSource.id,
      {
        ...dataSource.staticParams,
        queryParams: [
          { name: "Page", value: state.page },
          { name: "ItemsPerPage", value: state.itemsPerPage },
          { name: "ItemsCount", value: state.count },
          {
            name: "ResultMode",
            value: dataSource?.staticParams?.resultMode || "DetailedData",
          },
          { name: "GlobalFilter", value: state.globalFilter }, // deprecated
        ],
        columns:
          Array.isArray(state.columnDefinitions) &&
          state.columnDefinitions.length > 0
            ? state.columnDefinitions
            : [{ name: "id" }],
        filters: {
          globalFilter: state.globalFilter,
          globalFilterOptions: state.filterOptions,
          columnFilters: state.columnFilters,
        },
        sort: state.sort,
      },
      [],
      {
        callerId: context?.component?.id,
        callerGuid: context?.component?.guid,
        callerNodeId: context?.component?.nodeId,
      },
    )
      .then((resultData) => {
        if (resultData) {
          if (Array.isArray(resultData)) {
            resolve(resolveDataArray(resultData, state, columns, dataSource));
          } else if (
            typeof resultData === "object" &&
            resultData.type === "DetailedInfo"
          ) {
            resolve(
              resolveDetailedData(resultData, state, columns, dataSource),
            );
          } else {
            resolve(resolveDataArray([resultData], state, columns, dataSource));
          }
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
