import React, { useContext, useRef, useState } from "react";
import PropTypes from "prop-types";
import { PanPinchZoomDragContext } from "@echo/pan-pinch-zoom-drag";
import { Box, IconButton, Stack } from "@chakra-ui/react";
import Draggable from "react-draggable";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useRefDimensions, Input } from "@echo/ui";
import Tile from "../tile/tile";
import { uuidv4 } from "@echo/tools";

const Toolbox = ({ onDrop, tiles = [] }) => {
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const elementsRef = useRef();

  const [dimensions] = useRefDimensions(elementsRef);

  const elementsForPage =
    Math.floor(dimensions.height / 124) * Math.floor(dimensions.width / 84);

  const filteredTiles = tiles.filter((tile) =>
    tile?.label?.toLowerCase()?.includes(searchValue.toLowerCase()),
  );

  const { areaState, updateToolboxState } = useContext(PanPinchZoomDragContext);

  const handleOnDrop = (...args) => {
    if (onDrop && typeof onDrop === "function") {
      onDrop(...args);
    }
  };

  const handleSearchChange = (e) => {
    Promise.all([setSearchValue(e.target.value), setPage(0)]);
  };

  const handleOnStop = (tile) => {
    if (areaState?.isMouseOver) {
      Promise.all([
        updateToolboxState({ dragComponent: null }),
        handleOnDrop(areaState.mousePosition, tile),
      ]);
    } else {
      updateToolboxState({ dragComponent: null });
    }
  };

  const handleOnStart = (dragComponent) => {
    updateToolboxState({ dragComponent });
  };

  return (
    <Stack
      minWidth="172px"
      height="100%"
      display="flex"
      flexFlow="column"
      padding="8px 0px"
    >
      <Input
        alignSelf="center"
        width="90%"
        flex="0 1 auto"
        value={searchValue}
        placeholder="Search..."
        onChange={handleSearchChange}
      />
      <IconButton
        variant="outline"
        onClick={() => setPage(page - 1)}
        disabled={page === 0}
        flex="0 1 auto"
        width="90%"
        alignSelf="center"
      >
        <FiChevronUp />
      </IconButton>
      <Box
        ref={elementsRef}
        padding="4px"
        minWidth="172px"
        display="grid"
        maxHeight="calc(100% - 100px)"
        flex="1 1 auto"
        gridTemplateColumns="repeat(auto-fill, 80px)"
        justifyContent="space-around"
        alignContent="start"
        gridGap="4px"
      >
        {filteredTiles
          .slice(page * elementsForPage, (page + 1) * elementsForPage)
          .map((tile) => (
            <Draggable
              key={`toolbox-tile-${tile.label || uuidv4()}`}
              onStop={() => handleOnStop(tile)}
              onStart={() =>
                handleOnStart(
                  <Box>
                    <Box>
                      <Tile {...tile} />
                    </Box>
                  </Box>,
                )
              }
              position={{ x: 0, y: 0 }}
            >
              <Box
                alignItems="center"
                alignContent="center"
                justifyContent="space-between"
                flexDirection="column"
                fontSize="small"
                borderColor="gray.300"
                borderStyle="solid"
                width="80px"
                height="120px"
                textAlign="center"
                borderWidth="1px"
                borderRadius="3px"
                display="flex"
                _hover={{ backgroundColor: "gray.200" }}
              >
                <Box transform="scale(0.5)">
                  <Tile type={tile.type} color={tile.color} icon={tile.icon} />
                </Box>
                <Box overflow="hidden" width="70px">
                  {tile.label}
                </Box>
              </Box>
            </Draggable>
          ))}
      </Box>
      <IconButton
        variant="outline"
        disabled={(page + 1) * elementsForPage >= filteredTiles.length}
        flex="0 1 auto"
        width="90%"
        alignSelf="center"
        onClick={() => setPage(page + 1)}
      >
        <FiChevronDown />
      </IconButton>
    </Stack>
  );
};

Toolbox.propTypes = {
  onDrop: PropTypes.func,
  tiles: PropTypes.array,
};

export default Toolbox;
