import { useRef } from "react";

export const useFreezableValue = (calculateValue, lock) => {
  const memoRef = useRef();

  if (!lock) {
    memoRef.current = calculateValue();
  }

  return memoRef.current;
};
