import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import LabeledSelectFormControl from "../../../shared/labeled-select-form-control/labeled-select-form-control";
import { ProcessPicker } from "../process-picker";
import { AVAILABLE_PROPERTIES } from "./static";
import updateStructure from "./update-structure";

const PropertySetter = ({
  mainComponentStructure,
  onSave,
  modalProps = {},
  openComponent = ({ onOpen }) => (
    <Button onClick={onOpen}>Advanced property setter</Button>
  ),
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedProperty, setSelectedProperty] = useState();
  const [value, setValue] = useState();

  const handleSelectChange = useCallback((e) => {
    if (e?.target?.value === "!?not-selected") {
      setSelectedProperty(undefined);
    } else {
      setSelectedProperty(e.target.value);
    }
  }, []);

  const handleSave = useCallback(() => {
    if (
      selectedProperty &&
      value &&
      value.id &&
      value.type === "PROCESS" &&
      typeof onSave === "function"
    ) {
      const property = { name: selectedProperty, value };
      Promise.all([
        onSave(
          mainComponentStructure
            ? updateStructure(mainComponentStructure, property)
            : null,
        ),
        setSelectedProperty(null),
        setValue(null),
        onClose(),
      ]);
    }
  }, [mainComponentStructure, onClose, onSave, selectedProperty, value]);

  return (
    <>
      {openComponent({ onOpen })}

      <Modal
        minHeight={0}
        isCentered
        {...modalProps}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Set property on all components</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <LabeledSelectFormControl
              value={selectedProperty}
              onChange={handleSelectChange}
              label="Property"
            >
              <option value="!?not-selected"> </option>
              {AVAILABLE_PROPERTIES.map((propertyName) => (
                <option key={`option=${propertyName}`} value={propertyName}>
                  {propertyName}
                </option>
              ))}
            </LabeledSelectFormControl>
            <ProcessPicker
              process={value}
              onChange={setValue}
              isDisabled={!selectedProperty}
              sourceName={selectedProperty}
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button onClick={handleSave} isDisabled={!value} variant="ghost">
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

PropertySetter.propTypes = {
  mainComponentStructure: PropTypes.any,
  openComponent: PropTypes.func,
  modalProps: PropTypes.object,
  onSave: PropTypes.func,
};

export default PropertySetter;
