import { getInvalidFields } from "./get-invalid-fields";
import { updateComponent } from "./set-fields-invalid";
import { showRequiredError } from "./show-error";

export const checkRequiredFields = async (arr, state, ctx) => {
  const { component } = ctx;
  const invalidFields = await getInvalidFields(arr, state);

  if (invalidFields.length > 0) {
    invalidFields.forEach(showRequiredError);
    updateComponent(component, invalidFields, ctx);
    return true;
  }

  return false;
};
