import { Button } from "@chakra-ui/react";
import { Fragment } from "react";
import PropTypes from "prop-types";

const DefaultBtnStyle = {
  variant: "ghost",
  size: "sm",
};

export const DatepickerBackBtns = (props) => {
  const { calendars, getBackProps } = props;
  const customBtnProps = props.propsConfigs?.dateNavBtnProps;
  return (
    <Fragment>
      <Button
        {...getBackProps({
          calendars,
          offset: 12,
        })}
        {...DefaultBtnStyle}
        {...customBtnProps}
      >
        {"<<"}
      </Button>
      <Button
        {...getBackProps({ calendars })}
        {...DefaultBtnStyle}
        {...customBtnProps}
      >
        {"<"}
      </Button>
    </Fragment>
  );
};

DatepickerBackBtns.propTypes = {
  calendars: PropTypes.any.isRequired, // Calendar[];
  getBackProps: PropTypes.func.isRequired,
  propsConfigs: PropTypes.shape({
    dateNavBtnProps: PropTypes.any, //ButtonProps,
    dayOfMonthBtnProps: PropTypes.shape({
      electedBg: PropTypes.any, //BackgroundProps['bg'],
      disabledBg: PropTypes.any, //BackgroundProps['bg'],
    }),
    inputProps: PropTypes.any, //InputProps,
  }),
};

export const DatepickerForwardBtns = (props) => {
  const { calendars, getForwardProps } = props;
  const customBtnProps = props.propsConfigs?.dateNavBtnProps;
  return (
    <Fragment>
      <Button
        {...getForwardProps({ calendars })}
        {...DefaultBtnStyle}
        {...customBtnProps}
      >
        {">"}
      </Button>
      <Button
        {...getForwardProps({
          calendars,
          offset: 12,
        })}
        {...DefaultBtnStyle}
        {...customBtnProps}
      >
        {">>"}
      </Button>
    </Fragment>
  );
};

DatepickerForwardBtns.propTypes = {
  calendars: PropTypes.any.isRequired, // Calendar[];
  getForwardProps: PropTypes.func.isRequired,
  propsConfigs: PropTypes.shape({
    dateNavBtnProps: PropTypes.any, //ButtonProps,
    dayOfMonthBtnProps: PropTypes.shape({
      electedBg: PropTypes.any, //BackgroundProps['bg'],
      disabledBg: PropTypes.any, //BackgroundProps['bg'],
    }),
    inputProps: PropTypes.any, //InputProps,
  }),
};
