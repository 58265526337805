import { checkRequiredFields } from "./utils/check-required-fields";
import { checkUniqueFields } from "./utils/check-unique-fields";
import {
  getRequiredFields,
  getUniqueFields,
} from "./utils/get-fields-by-property";

export const validateFormBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: async (staticParams) => {
      const { __model: state, isUniqueAlert } = staticParams;
      const { component } = componentContext;

      const uniqueFields = getUniqueFields(component);
      const requiredFields = getRequiredFields(component);

      const requiredError = await checkRequiredFields(
        requiredFields,
        state,
        componentContext,
      );

      if (uniqueFields.length > 0) {
        const uniqueError = await checkUniqueFields(
          uniqueFields,
          state,
          componentContext,
          !!isUniqueAlert,
        );

        if (requiredError || uniqueError) return { __resultCondition: "false" };
      }

      if (requiredError) return { __resultCondition: "false" };
      return { ...staticParams, __resultCondition: "true" };
    },
  };
};
