import { Box, useDisclosure } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useCallback, useMemo, useRef } from "react";
import { withBaseComponent } from "../../../with-base-component";
import DataGridFilterHeader from "./action-header/filter-header";
import { ColumnSettings } from "./column-settings/column-settings";
import DataGridContent from "./content/content";
import DataGridFooter from "./footer/footer";
import DataGridHeader from "./header/header";
import { useDataGrid } from "./hooks/use-datagrid";
import { useSelection } from "./hooks/use-selection";
import DataGridRow from "./row/row";
import { getColumnPropType } from "./utils/column-prop-type";
import { useRefDimensions, mobileCheck } from "@echo/ui";
import DatagridDataFooter from "./data-footer/data-footer";
import { PageSizeSelect } from "./page-size-select/page-size-select";
import { catchEventError } from "./utils/catch-event-error";
import { useResolveProp } from "../../../../../hooks/resolve-prop/use-resolve-prop";
import { useComponentRegister } from "../../../../../../components-registry/hooks/use-component-register";
import { getColumnMeta } from "./lib/columns/column-meta";
import {
  areArraysSame,
  mergeColumnArrays,
} from "../datagrid-v2-component/utils/compare-arrays";
import { toast } from "react-toastify";

const DataGrid = (props) => {
  const {
    name,
    rootProps,
    context,
    onRowClick,
    onRowDoubleClick,
    selectedModel,
    onSelectChange,
    enableInlineEdit,
    enableAdding,
    onRowAdd,
    pageSizeSelectOptions,
    isCombo,
    headerFontSize,
    rowFontSize,
    cellLinesNumber,
    hideFooter,
    cellWhiteSpace,
    designerMode = false,
    className = "",
    disableFilters = false,
    selectionMode = "multiple",
    inlineEditSaveType = "blur",
  } = props;
  const { nodeRef, onClick, ...restRootProps } = rootProps;
  const internalRef = useRef();

  const selectionModeValue = useResolveProp(selectionMode, true);

  const {
    isOpen: isColumnSettingsOpen,
    onClose: closeColumnSettings,
    onOpen: openColumnSettings,
  } = useDisclosure();

  const isMobile = mobileCheck();

  // eslint-disable-next-line no-unused-vars
  const [state, api, isLoading, columnSettings] = useDataGrid(props);

  const defaultPageSizeSelectOptions = [
    { value: 20, label: "20" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  const [rowSelectionProps, headerSelectionProps] = useSelection(
    selectedModel,
    selectionModeValue || "none",
    onSelectChange,
    state.data,
  );

  const handleOnAdd = useCallback(
    (newRow) => {
      if (typeof onRowAdd === "function") {
        Promise.all([onRowAdd(newRow)]);
      }
    },
    [onRowAdd],
  );

  const headerHandlers = api.header;
  const rowHandlers = api.row;
  const footerHandlers = api.footer;
  const actionHandlers = api.actions;

  // DESIGNER SECTION
  const handleOnClick = (e) => {
    if (designerMode && onClick) {
      onClick(e);
    }
  };
  // ~DESIGNER SECTION

  const tableRef = useRef();
  const contentRef = useRef();

  const [tableDimensions] = useRefDimensions(
    tableRef,
    {
      reactOnResize: true,
    },
    [state.columnDefinitions, state.data],
  );

  const [contentDimensions] = useRefDimensions(
    contentRef,
    {
      reactOnResize: true,
    },
    [state.data, state.columnDefinitions],
  );

  const itemsCount = state?.source?.queryParams?.find(
    (el) => el.name === "ItemsCount",
  ).value;

  const gridDataRenderCondition = state?.source?.queryParams
    ? itemsCount > 0
    : true;

  const handleRowClick = (e) => {
    if (!onRowClick) {
      if (isMobile) onRowDoubleClick(e).catch(catchEventError);
    } else {
      onRowClick(e);
    }
  };

  const inlineEditSaveTypeState = useResolveProp(inlineEditSaveType, true);

  // const getData = useCallback(() => state.data, [state.data]);

  const component = useMemo(
    () => ({
      name,
      getColumns: () => state.columnDefinitions,
      getData: () => state.data,
    }),
    [state.columnDefinitions, name, state.data],
  );

  useComponentRegister(context, component);

  const resetAction = () =>
    api.columns.updateColumns(getColumnMeta(state.source)?.specification);

  const updateModel = () => {
    const columnMeta = getColumnMeta(state.source)?.specification;
    const currentColumns = state.columnDefinitions;

    if (!areArraysSame(columnMeta, currentColumns)) {
      const merged = mergeColumnArrays(currentColumns, columnMeta);
      api.columns.updateColumns(merged);
    } else {
      toast.info("Model is up to date.");
    }
  };

  return (
    <Box
      display="flex"
      ref={nodeRef || internalRef}
      className={`${className} data-grid`}
      {...restRootProps}
      height="100%"
      width="100%"
      overflowX="hidden"
      flexFlow="column"
      justifyContent="space-between"
      padding="5px"
      onClick={handleOnClick}
    >
      <ColumnSettings
        isOpen={isColumnSettingsOpen}
        onClose={closeColumnSettings}
        defaultColumns={state.columnDefinitions}
        onChange={api.columns.updateColumns}
        sourceObject={state?.source}
        containerRef={nodeRef || internalRef}
        isCombo={isCombo}
        settingsKey={columnSettings.columnSettingKey}
        updateModel={updateModel}
        actions={[
          {
            name: "Reset",
            buttonProps: { colorScheme: "red" },
            action: resetAction,
          },
        ]}
      />
      <Box
        ref={tableRef}
        flex="1"
        boxSizing="border-box"
        display="flex"
        flexFlow="column"
        justifyContent="space-between"
        border="1px solid var(--chakra-colors-gray-200)"
        height="fit-content"
        // Fix scrollbar on chrome/chromium, because table is 0.5 px higher than table content, WTF
        overflowX={
          Math.floor(tableDimensions?.width || 0) - 2 ===
          Math.floor(contentDimensions?.width || 0)
            ? "hidden"
            : "auto"
        }
      >
        {state.columnDefinitions && state.columnDefinitions.length > 0 ? (
          <>
            <Box
              boxSizing="border-box"
              width="fit-content"
              display="flex"
              flexDirection="column"
              height="100%"
              overflow="hidden"
            >
              <Box
                as="table"
                ref={contentRef}
                boxSizing="border-box"
                sx={{ tableLayout: "fixed" }}
                overflowY="hidden"
                style={{
                  tableLayout: "fixed",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <DataGridHeader
                  {...headerSelectionProps}
                  {...state}
                  {...headerHandlers}
                  tableDimensions={tableDimensions}
                  contentDimensions={contentDimensions}
                  onSizeChange={api.columns.updateColumnSize}
                  onOrderChange={api.columns.updateColumnOrder}
                  onColumnHide={api.columns.hideColumn}
                  onColumnSettingsOpen={openColumnSettings}
                  fontSize={headerFontSize}
                  onColumnFiltersChange={
                    api.filterOptions.onColumnFiltersChange
                  }
                />
                <Box as="thead" display="block">
                  {!disableFilters && (
                    <DataGridFilterHeader
                      {...actionHandlers}
                      {...state}
                      {...headerSelectionProps}
                      filterOptions={api.filterOptions}
                      tableDimensions={tableDimensions}
                    />
                  )}
                </Box>
                {!state.loading ? (
                  <DataGridContent>
                    {gridDataRenderCondition &&
                      state.data.map((row, index) => {
                        return (
                          <DataGridRow
                            cellLinesNumber={cellLinesNumber}
                            fontSize={rowFontSize}
                            cellWhiteSpace={cellWhiteSpace}
                            key={`row-${row._id}-${index}`}
                            {...rowSelectionProps(row._id)}
                            enableInlineEdit={enableInlineEdit}
                            inlineEditSaveType={inlineEditSaveTypeState}
                            onClick={handleRowClick}
                            onDoubleClick={(e) =>
                              typeof onRowDoubleClick === "function" &&
                              Promise.resolve(onRowDoubleClick(e)).catch(
                                catchEventError,
                              )
                            }
                            context={context}
                            row={row}
                            columnDefinitions={state.columnDefinitions}
                            {...rowHandlers}
                            style={{
                              background: index % 2 === 0 ? "#eeeeef" : "",
                            }}
                            tableDimensions={tableDimensions}
                          />
                        );
                      })}
                    {enableAdding && (
                      <DataGridRow
                        selectionMode={"empty"}
                        enableInlineEdit
                        enforceEditMode
                        context={context}
                        row={{}}
                        onEdit={handleOnAdd}
                        columnDefinitions={state.columnDefinitions}
                        tableDimensions={tableDimensions}
                      />
                    )}
                  </DataGridContent>
                ) : (
                  <Box
                    display="flex"
                    width="100%"
                    height="100%"
                    justifyContent="center"
                    alignContent="center"
                    alignItems="center"
                    padding="20px"
                  >
                    Loading data...
                  </Box>
                )}
              </Box>
              <DatagridDataFooter
                {...state}
                {...headerSelectionProps}
                tableDimensions={tableDimensions}
              />
            </Box>
          </>
        ) : (
          <Box
            display="flex"
            width="100%"
            height="100%"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
          >
            Loading data...
          </Box>
        )}
      </Box>
      {!hideFooter && (
        <DataGridFooter
          {...state}
          {...footerHandlers}
          pageSizeSelect={
            <PageSizeSelect
              onSelect={actionHandlers.handlePageSize}
              savePageSize={actionHandlers.savePageSize}
              options={pageSizeSelectOptions || defaultPageSizeSelectOptions}
              selected={state.itemsPerPage}
            />
          }
        />
      )}
    </Box>
  );
};

DataGrid.propTypes = {
  id: PropTypes.any,
  name: PropTypes.string,
  dataSource: PropTypes.any,
  columns: getColumnPropType(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      displayName: PropTypes.string,
      order: PropTypes.number,
      isVisible: PropTypes.bool,
      width: PropTypes.number,
      align: PropTypes.oneOf(["left", "center", "right"]),
    }),
  ),
  objectKey: PropTypes.string,
  columnKey: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.any })),
  className: PropTypes.any,
  designerMode: PropTypes.bool,

  onSave: PropTypes.func,
  onRowClick: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  onRowEdit: PropTypes.func,
  onRowAdd: PropTypes.func,
  enableInlineEdit: PropTypes.bool,
  inlineEditSaveType: PropTypes.oneOf(["button", "blur"]),
  enableAdding: PropTypes.bool,
  selectionMode: PropTypes.oneOf(["single", "multiple", "none"]),
  context: PropTypes.any,
  selectedModel: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.any })),
  onSelectChange: PropTypes.func,

  pageSize: PropTypes.any,
  disableFilters: PropTypes.bool,
  refreshPolicy: PropTypes.oneOf(["disabled", "tab-change"]),
  deps: PropTypes.string,
  hideFooter: PropTypes.bool,

  rootProps: PropTypes.object,

  rowColor: PropTypes.func,
  pageSizeSelectOptions: PropTypes.array,

  headerFontSize: PropTypes.string,
  rowFontSize: PropTypes.string,
  cellWhiteSpace: PropTypes.string,
  cellLinesNumber: PropTypes.string,

  // For ComboGrid, dirty way. With new datagrid please use package datagrid component instead of *.component.js
  isCombo: PropTypes.bool,
  filterColumnName: PropTypes.string,
  filterValue: PropTypes.string,
};

export default withBaseComponent(DataGrid);
