import PropTypes from "prop-types";
import EchoArrayOfValueCell from "../echo-array-of-value-cell";

const DatagridColumnsPropEditor = ({
  propValue,
  propTypeObject,
  ...restProps
}) => {
  const getTableOptionType = (propTypeName) => {
    switch (propTypeName) {
      case "bool":
        return "boolSelect";
      case "permissionEditorArrayOf":
        return "permissionEditorPropSelect";
      default:
        return "inputSelect";
    }
  };

  const propTypes =
    propTypeObject?.info?.allowedChildrenPropType?.info?.objectShape;

  const getPropertiesTableOptions = () => {
    let result = Object.keys(propTypes).map((propKey) => {
      return {
        propName: propKey,
        propType: getTableOptionType(propTypes[propKey]?.info?.propTypeName),
        propTypeObject: propTypes[propKey],
      };
    });
    return result.some((o) => o.propName === "name")
      ? result
      : [{ propName: "name", propType: "input" }, ...result];
  };

  return (
    <EchoArrayOfValueCell
      {...restProps}
      propValue={propValue ?? []}
      propTypes={getPropertiesTableOptions()}
    />
  );
};

DatagridColumnsPropEditor.propTypes = {
  ...EchoArrayOfValueCell.propTypes,
  componentProps: PropTypes.object.isRequired,
  propTypes: PropTypes.any,
};

export default DatagridColumnsPropEditor;
