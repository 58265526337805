import { getRandomIntInclusive, uuidv4 } from "@echo/tools";
import { findComponentParent } from "./utils/find-component-parent";
import { resetComponentChildrenIds } from "./utils/reset-children-ids";

export const componentReducerInitialState = {
  toolboxComponents: [],
  previousHistory: [],
  nextHistory: [],
  componentData: null,
  componentInfo: {
    name: "",
    source: "!?echo-defined",
    type: "Component",
    displayName: "",
    componentPurpose: "Component",
    description: "",
    availableStyleOptions: [],
  },
  modelSchema: { formSource: { Id: {} } },
};

const updateComponentProperty = (childs, action) => {
  for (const index in childs) {
    if (childs[index].id === action.selectedComponent) {
      childs[index][action.propertyName] = action.model;
    } else if (
      childs[index].childrenComponents &&
      childs[index].childrenComponents.length
    ) {
      updateComponentProperty(childs[index].childrenComponents, action);
    }
  }
  return childs;
};

const resetNodeIds = (childs) => {
  for (const index in childs) {
    childs[index].nodeId = uuidv4();
    childs[index].component.nodeId = uuidv4();
    if (
      childs[index].childrenComponents &&
      childs[index].childrenComponents.length
    ) {
      resetNodeIds(childs[index].childrenComponents);
    }
    if (
      childs[index].component.childrenComponents &&
      childs[index].component.childrenComponents.length
    ) {
      resetNodeIds(childs[index].component.childrenComponents);
    }
  }
  return [...childs];
};

const createNewComponent = (action, depth) => {
  const { definition } = action;
  const { type } = definition;

  return {
    id: -getRandomIntInclusive(1, 999999999),
    key: uuidv4(),
    guid: uuidv4(),
    nodeId: uuidv4(),
    styleOptions: action.definition.styleOptions ?? {},
    componentProps: action.defaultProps ?? {},
    depth,
    component: {
      ...definition,
      childrenComponents: resetNodeIds(definition.childrenComponents),
    },
    childrenComponents:
      type === "Template" ? resetNodeIds(definition.childrenComponents) : [],
  };
};

const dropComponentInArea = (childs, action, depth = 2) => {
  for (const index in childs) {
    if (childs[index].id === action.activeAreaId) {
      childs[index].childrenComponents = [
        ...childs[index].childrenComponents,
        createNewComponent(action, depth),
      ];
      break;
    } else if (
      childs[index].childrenComponents &&
      childs[index].childrenComponents.length
    ) {
      dropComponentInArea(childs[index].childrenComponents, action, depth + 1);
    }
  }
  return childs;
};

const dropComponent = (prevState, action) => {
  const rootComponentId = prevState.componentInfo.id;
  let newAction;
  const { definition } = action;
  const { type } = definition;

  if (type === "Template") {
    const newDefinition = definition.childrenComponents[0];
    newAction = {
      ...action,
      defaultProps: { ...newDefinition.componentProps },
      definition: {
        ...newDefinition.component,
        styleOptions: newDefinition.styleOptions,
        childrenComponents: [
          ...resetComponentChildrenIds(newDefinition, rootComponentId),
        ],
        type,
      },
    };
  } else {
    newAction = { ...action, definition: { ...definition, type } };
  }

  if (!prevState.componentData) {
    const component = createNewComponent(newAction, 0);
    return {
      ...prevState,
      componentData: {
        ...component,
        styleOptions: {
          ...component.styleOptions,
          width: "100%",
          height: "100%",
        },
      },
    };
  }
  if (newAction.activeAreaId === prevState.componentData.id) {
    return {
      ...prevState,
      componentData: {
        ...prevState.componentData,
        childrenComponents: [
          ...prevState.componentData.childrenComponents,
          createNewComponent(newAction, 1),
        ],
      },
    };
  } else {
    return {
      ...prevState,
      componentData: {
        ...prevState.componentData,
        childrenComponents: dropComponentInArea(
          prevState.componentData.childrenComponents,
          newAction,
        ),
      },
    };
  }
};

const updateDepths = (childs, depth = 0) => {
  for (const index in childs) {
    childs[index].depth = depth;
    if (
      childs[index].childrenComponents &&
      childs[index].childrenComponents.length
    ) {
      updateDepths(childs[index].childrenComponents, depth + 1);
    }
  }
  return childs;
};

const dropExistingComponentInArea = (childs, component, parentId) => {
  for (const index in childs) {
    if (childs[index].id === parentId) {
      childs[index].childrenComponents = [
        ...childs[index].childrenComponents,
        component,
      ];
      break;
    } else if (
      childs[index].childrenComponents &&
      childs[index].childrenComponents.length
    ) {
      dropExistingComponentInArea(
        childs[index].childrenComponents,
        component,
        parentId,
      );
    }
  }
  return childs;
};

const removeComponentWithId = (childs, id) => {
  for (const index in childs) {
    if (childs[index].id === id) {
      const result = childs[index];
      return [childs.filter((cmp) => cmp.id !== id), result];
    } else if (
      childs[index].childrenComponents &&
      childs[index].childrenComponents.length
    ) {
      const [resultChilds, result] = removeComponentWithId(
        childs[index].childrenComponents,
        id,
      );
      childs[index].childrenComponents = resultChilds;
      if (result === null) {
        continue;
      }
      return [childs, result];
    }
  }

  return [childs, null];
};

const moveComponent = (childs, id, newParentId) => {
  const oldParentId = findComponentParent(childs[0], id);
  if (oldParentId === newParentId) {
    return childs;
  }
  const [childsResult, component] = removeComponentWithId(childs, id);

  if (component) {
    const result = dropExistingComponentInArea(
      childsResult,
      component,
      newParentId,
    );
    return result;
  } else {
    return childsResult;
  }
};

export const componentDesignerReducer = (prevState, action) => {
  window.dispatchEvent(new Event("designer_dispatch"));
  switch (action.type) {
    case "UPDATE_SIDE_PANEL_LAYOUT":
      return {
        ...prevState,
        sidePanelData: {
          ...prevState.sidePanelData,
          panelComponents: action.layout,
        },
      };
    case "REMOVE_ELEMENT":
      return {
        ...prevState,
        // previousHistory: [
        //   ...(prevState.previousHistory || []),
        //   structuredClone(prevState),
        // ],
        nextHistory: [],
        componentData: removeComponentWithId(
          [prevState.componentData],
          action.id,
        )[0][0],
      };
    case "DROP_COMPONENT":
      return {
        ...dropComponent(prevState, action),
        // previousHistory: [
        //   ...(prevState.previousHistory || []),
        //   structuredClone(prevState),
        // ],
        nextHistory: [],
      };
    case "MOVE_COMPONENT":
      return {
        ...prevState,
        // previousHistory: [
        //   ...(prevState.previousHistory || []),
        //   structuredClone(prevState),
        // ],
        nextHistory: [],
        componentData: updateDepths(
          moveComponent(
            [prevState.componentData],
            action.id,
            action.newParentId,
          ),
        )[0],
      };
    case "UPDATE_COMPONENT_PROPERTY":
      if (action.selectedComponent === prevState.componentData.id) {
        return {
          ...prevState,
          // previousHistory: [
          //   ...(prevState.previousHistory || []),
          //   structuredClone(prevState),
          // ],
          nextHistory: [],
          componentData: {
            ...prevState.componentData,
            [action.propertyName]: action.model,
          },
        };
      } else {
        return {
          ...prevState,
          // previousHistory: [
          //   ...(prevState.previousHistory || []),
          //   structuredClone(prevState),
          // ],
          nextHistory: [],
          componentData: {
            ...prevState.componentData,
            childrenComponents: updateComponentProperty(
              prevState.componentData.childrenComponents,
              action,
            ),
          },
        };
      }
    case "UPDATE_TOOLBOX_COMPONENTS":
      return {
        ...prevState,
        toolboxComponents: action.toolboxComponents,
      };
    case "UPDATE_COMPONENT_INFO":
      return {
        ...prevState,
        componentInfo: action.model,
        // previousHistory: [
        //   ...(prevState.previousHistory || []),
        //   structuredClone(prevState),
        // ],
        nextHistory: [],
      };
    case "INIT_COMPONENT": {
      return {
        ...prevState,
        componentInfo: action.componentInfo,
        componentData: action.componentData,
      };
    }
    case "UPDATE_MODEL_SCHEMA":
      return { ...prevState, modelSchema: action.modelSchema };
    case "UPDATE_STRUCTURE":
      return {
        ...prevState,
        componentData: action.newStructure || prevState?.componentData,
      };
    case "UNDO": {
      const previousHistory = prevState.previousHistory || [];
      const nextHistory = prevState.nextHistory || [];
      if (!previousHistory.length) {
        return prevState;
      }
      nextHistory.push(prevState);
      const state = previousHistory.pop();

      return { ...state, previousHistory, nextHistory };
    }
    case "REDO": {
      const previousHistory = prevState.previousHistory || [];
      const nextHistory = prevState.nextHistory || [];
      if (!nextHistory.length) {
        return prevState;
      }
      previousHistory.push(prevState);
      const state = nextHistory.pop();

      return { ...state, previousHistory, nextHistory };
    }
    default:
      return prevState;
  }
};
