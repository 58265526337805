import { showErrorToast } from "../../components/shared/echo-error-toast";
import { caseInsensitiveIdCheckExists } from "../../utils/data/id-check";
import { executeBlock, executeBlockByGroup } from "../process-executor";

const updateComponentData = (dataObject, componentContext) => {
  if (dataObject && caseInsensitiveIdCheckExists(dataObject)) {
    componentContext.functions.updateSource(dataObject);
  }
};

const getModelFromResult = (result) => {
  if (typeof result === "undefined" || result === null) {
    return result;
  }

  if (typeof result === "object" && Array.isArray(result)) {
    if (result.length > 0) {
      return typeof result[0] === "object" ? result[0] : null;
    }
  }

  return typeof result === "object" && !Array.isArray(result) ? result : null;
};

const executeProcess = (
  componentContext,
  staticParams,
  params,
  action,
  id,
  model,
  systemParams
) =>
  new Promise((resolve, reject) => {
    Promise.resolve(
      executeBlock(
        componentContext,
        action.id,
        { ...staticParams, __model: model.formSource },
        [
          ...(params?.filter((c) => c !== null) ?? []),
          { action: id ? "update" : "create" },
          { componentId: componentContext?.component?.id },
          { isOpen: false },
        ],
        systemParams
      )
    )
      .then((res) => {
        const resModel = getModelFromResult(res);
        if (res) {
          updateComponentData(resModel, componentContext);
        }
        resolve({
          __model: { ...model.formSource, ...resModel },
          useContextModel: true,
        });
      })
      .catch((err) => {
        showErrorToast(err);
        reject(err);
      });
  });

const executeProcessGroup = (
  componentContext,
  staticParams,
  params,
  action,
  id,
  model,
  resolve,
  reject,
  systemParams
) => {
  Promise.resolve(
    executeBlockByGroup(
      componentContext,
      { group: action.name, tag: id ? "UPDATE" : "CREATE" },
      { ...staticParams, ...action.staticParams, __model: model.formSource },
      [
        ...(params?.filter((c) => c !== null) ?? []),
        { action: id ? "update" : "create" },
        { componentId: componentContext?.component?.id },
        { isOpen: false },
      ],
      systemParams
    )
  )
    .then((res) => {
      const resModel = getModelFromResult(res);
      if (res) {
        updateComponentData(resModel, componentContext);
      }
      resolve({
        __model: { ...model.formSource, ...resModel },
        useContextModel: true,
      });
    })
    .catch((err) => {
      showErrorToast(err);
      reject(err);
    });
};

export const baseCrud = (block, componentContext) => {
  return {
    definition: block,
    execute: (staticParams, params, systemParams) =>
      new Promise((resolve, reject) => {
        const model = componentContext.functions.getModel();
        const id = model?.formSource?.id ?? model?.formSource?.Id;
        const action = id
          ? componentContext.functions.getUpdateAction(true)
          : componentContext.functions.getCreateAction(true);
        if (action.type === "PROCESS") {
          executeProcess(
            componentContext,
            staticParams,
            params,
            action,
            id,
            model,
            resolve,
            reject,
            systemParams
          )
            .then(() => resolve(staticParams))
            .catch(reject);
        } else if (action.type === "BLOCK_GROUP") {
          executeProcessGroup(
            componentContext,
            staticParams,
            params,
            action,
            id,
            model,
            resolve,
            reject,
            systemParams
          );
        } else {
          throw new Error("Unexpected action type");
        }
      }),
  };
};
