// @flow
import * as React from "react";
import { Box, Button, Input, Text } from "@chakra-ui/react";
import { useDatagridContext } from "../../hooks/useDatagridContext";
import {
  DATAGRID_NAVIGATION_PAGE_SELECT_NEXT_TESTID,
  DATAGRID_NAVIGATION_PAGE_SELECT_PAGE_TESTID,
  DATAGRID_NAVIGATION_PAGE_SELECT_PAGES_COUNT_TESTID,
  DATAGRID_NAVIGATION_PAGE_SELECT_PREV_TESTID,
  DATAGRID_NAVIGATION_PAGE_SELECT_TESTID,
} from "../../utils/test-ids";

export const Pagination = (): React.Node => {
  const {
    itemsCount,
    page,
    disablePagination,
    actions: { onPageChange },
    settings: { itemsPerPage },
  } = useDatagridContext();

  const currentPage = page + 1;

  const totalPages = Math.ceil(itemsCount / itemsPerPage.value);

  const handleClickPrev = () => onPageChange(page - 1);

  const handleClickNext = () => onPageChange(page + 1);

  const handleChange = (e) => {
    const value = parseFloat(e.target.value) - 1;
    onPageChange(value);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      gap="10px"
      data-testid={DATAGRID_NAVIGATION_PAGE_SELECT_TESTID}
    >
      <Button
        onClick={handleClickPrev}
        isDisabled={page === 0 || disablePagination}
        variant="ghost"
        data-testid={DATAGRID_NAVIGATION_PAGE_SELECT_PREV_TESTID}
      >
        {"<"}
      </Button>
      <Box display="flex" alignItems="center" gap="10px">
        <Input
          variant="unstyled"
          width="fit-content"
          maxWidth={"30px"}
          value={currentPage}
          onChange={handleChange}
          data-testid={DATAGRID_NAVIGATION_PAGE_SELECT_PAGE_TESTID}
        />
        <Text>of</Text>
        <Text data-testid={DATAGRID_NAVIGATION_PAGE_SELECT_PAGES_COUNT_TESTID}>
          {totalPages}
        </Text>
      </Box>
      <Button
        onClick={handleClickNext}
        isDisabled={
          page * itemsPerPage.value + itemsPerPage.value > itemsCount ||
          disablePagination
        }
        variant="ghost"
        data-testid={DATAGRID_NAVIGATION_PAGE_SELECT_NEXT_TESTID}
      >
        {">"}
      </Button>
    </Box>
  );
};
