import { convertDatabaseType } from "../../utils/data-loader/metadata/convert-database-type";

/**
 * Extract column specification from data
 * @param {array} data
 * @return {array} Detailed columns specification
 */
const createSpecFromRawData = (data) => {
  if (typeof data !== "object" || !Array.isArray(data)) {
    throw new Error("Cannot create columns specification from data.");
  }

  return Array.isArray(data) && data.length > 0
    ? Object.keys(data[0])
        .filter((key) => key !== "RecCount")
        .map((key) => ({
          name: key,
          isVisible:
            key.toLowerCase() !== "id" &&
            key.toLowerCase() !== "guid" &&
            key.toLowerCase() !== "nodeId",
          isNull: true,
          type: typeof (
            (data.find((elem) => elem[key] !== null) || {})[key] || data[0][key]
          ),
        }))
    : [];
};

/**
 * Extract specification from source
 * @param {object} sourceMetadata
 * @return {array} Specification
 */
const extractSpecFromSourceMeta = (sourceMetadata) => {
  if (
    typeof sourceMetadata !== "object" ||
    !sourceMetadata.columns ||
    !Array.isArray(sourceMetadata.columns)
  ) {
    throw new Error("Invalid column data.");
  }
  return (sourceMetadata?.columns || [])
    .filter((c) => c.columnName !== "RecCount")
    .map((c) => ({
      name: c.columnName,
      isVisible:
        c.columnName.toLowerCase() !== "id" &&
        c.columnName.toLowerCase() !== "_id" &&
        c.columnName.toLowerCase() !== "guid" &&
        c.columnName.toLowerCase() !== "nodeId",
      isNull: c.isNull,
      type: convertDatabaseType(c.dataTypeName),
    }));
};

/**
 * Get column details
 * @param {array} sourceObject
 * @return {array} Detailed columns specification
 */
const getFromRawData = (sourceObject) => {
  const columnMeta = {
    specification: null,
    specificationSource: null,
    alertInfo: null,
    alertSeverity: null,
  };

  try {
    columnMeta.specification = createSpecFromRawData(sourceObject);
    columnMeta.specificationSource = "SOURCE";
  } catch (err) {
    columnMeta.specification = [];
    columnMeta.specificationSource = "RAW_DATA";
    columnMeta.alertSeverity = "ERROR";
    columnMeta.alertInfo = `Cannot load columns from raw data:\n\t${err.toString()}`;
  }

  return columnMeta;
};

/**
 * Get column details
 * @param {any} sourceObject
 * @return {array} Detailed columns specification
 */
const getFromSource = (sourceObject) => {
  const columnMeta = {
    specification: null,
    specificationSource: null,
    alertInfo: null,
    alertSeverity: null,
  };

  try {
    columnMeta.specification = extractSpecFromSourceMeta(sourceObject);
    columnMeta.specificationSource = "SOURCE";
  } catch (err) {
    const rawDataMeta = getFromRawData(sourceObject.data);

    if (rawDataMeta.alertInfo !== null || rawDataMeta.alertSeverity !== null) {
      return {
        ...columnMeta,
        ...rawDataMeta,
        alertSeverity: "ERROR",
        alertInfo: `$Cannot load columns from source:\n\t${err.toString()}\n${
          rawDataMeta.alertInfo
        }\n Please check data source.`,
      };
    } else {
      return {
        ...columnMeta,
        ...rawDataMeta,
        alertSeverity: "WARNING",
        alertInfo: `$Cannot load columns from source:\n\t${err.toString()}\n Loaded from data instead`,
      };
    }
  }

  return columnMeta;
};

/**
 * Get column details
 * @param {any} sourceObject
 * @return {array} Detailed columns specification
 */
export const getColumnMeta = (sourceObject) => {
  const columnMeta = {
    specification: null,
    specificationSource: null,
    alertInfo: null,
    alertSeverity: null,
  };
  if (typeof sourceObject === "object" && Array.isArray(sourceObject)) {
    // manually extract column specification
    return { ...columnMeta, ...getFromRawData(sourceObject) };
  } else if (typeof sourceObject === "object" && sourceObject.metadata) {
    return { ...columnMeta, ...getFromSource(sourceObject?.metadata) };
  }

  return columnMeta;
};
