import PropTypes from "prop-types";
import { SingleDatepicker } from "../../../../../datepicker";
import { Box } from "@chakra-ui/react";

export const DateEditComponent = ({ value, format, onChange }) => {
  return (
    <Box
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <SingleDatepicker
        name="date-input"
        date={value}
        onDateChange={onChange}
        dateTimeFormat={format}
      />
    </Box>
  );
};

DateEditComponent.propTypes = {
  value: PropTypes.string,
  format: PropTypes.string,
  onChange: PropTypes.func,
};
