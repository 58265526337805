// @flow

import React, { useContext, useEffect } from "react";
import { Box, Tbody, useDisclosure } from "@chakra-ui/react";
import { useDatagridContext } from "../../hooks/useDatagridContext";
import { useMultipleSelect } from "../../hooks/useMultipleSelect";
import { Row } from "./row";
import {
  DATAGRID_BODY_ROW_TESTID,
  DATAGRID_BODY_TESTID,
} from "../../utils/test-ids";
import { getId } from "../../utils/get-id";
import { useDragContext } from "../../hooks/useDragContext";
import { useContextMenu } from "@echo/ui/src/lib/components/context-menu/hooks/useContextMenu";
import { ContextMenu } from "@echo/ui";
import { ContextMenuWrapper } from "../context-menu-wrapper/context-menu-wrapper";
import { BodyWithContextMenu } from "./body-with-context-menu";
import { useScrollContext } from "../../hooks/use-scroll-context";

export const Body = ({ columns }): React.Node => {
  const {
    data,
    actions: { onRowClick, onRowDoubleClick },
    loading,
  } = useDatagridContext();

  const { bodyRef, onBodyScroll, setScrollLeft } = useScrollContext();

  useEffect(() => {
    if (!bodyRef.current) return;
    const scrollLeft = bodyRef.current.scrollLeft;
    setScrollLeft(scrollLeft);
  }, []);

  const { selectMany, unselectMany } = useMultipleSelect();

  const handleRowClick = (row: { [key: string]: string }) => {
    if (!onRowClick) return;
    onRowClick(row);
  };

  const handleRowDoubleClick = (row: { [key: string]: string }) => {
    if (!onRowDoubleClick) return;
    onRowDoubleClick(row);
  };

  const handleRowSelect = (e) => {
    const isChecked = e.target.checked;
    const shiftPressed = e.nativeEvent.shiftKey;
    const id = e.target.value;

    if (isChecked) selectMany(id, shiftPressed);
    if (!isChecked) unselectMany(id, shiftPressed);
  };

  if (loading) {
    return (
      <BodyWithContextMenu
        height="100%"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box>Loading data...</Box>
      </BodyWithContextMenu>
    );
  }

  if (!loading && data.length === 0) {
    return (
      <BodyWithContextMenu
        height="100%"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box>No records found.</Box>
      </BodyWithContextMenu>
    );
  }

  return (
    <BodyWithContextMenu
      height="100%"
      width="100%"
      display="block"
      overflow="auto"
      onScroll={onBodyScroll}
    >
      {data.map((record, idx) => {
        return (
          <Row
            columns={columns}
            style={{
              background: idx % 2 === 0 ? "#eeeeef" : "",
              color: record?.__rowColor,
            }}
            record={record}
            onRowClick={() => handleRowClick(record)}
            onRowDoubleClick={() => handleRowDoubleClick(record)}
            onRowSelect={handleRowSelect}
            data-testid={DATAGRID_BODY_ROW_TESTID}
            key={getId(record)}
          />
        );
      })}
    </BodyWithContextMenu>
  );
};
