// @flow

import { useState } from "react";

import {
  subMonths,
  subYears,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  getDay,
} from "date-fns";

type Month = {
  firstDay: Date,
  lastDay: Date,
  firstDayIdx: number,
  lastDayIdx: number,
  days: Date[],
};

export const useDatePicker = (initialDate?: Date) => {
  const [currentDate, setCurrentDate] = useState(
    initialDate ? initialDate : new Date(),
  );

  const handleDateChange = (
    type: "PREV_YEAR" | "PREV_MONTH" | "NEXT_MONTH" | "NEXT_YEAR",
  ) => {
    switch (type) {
      case "PREV_YEAR":
        setCurrentDate((prev) => subYears(prev, 1));
        break;
      case "NEXT_YEAR":
        setCurrentDate((prev) => subYears(prev, -1));
        break;
      case "PREV_MONTH":
        setCurrentDate((prev) => subMonths(prev, 1));
        break;
      case "NEXT_MONTH":
        setCurrentDate((prev) => subMonths(prev, -1));
    }
  };

  const prevMonthDate = subMonths(currentDate, 1);
  const nextMonthDate = subMonths(currentDate, -1);

  const currentMonth: Month = {
    firstDay: startOfMonth(currentDate),
    lastDay: endOfMonth(currentDate),
    firstDayIdx: getDay(startOfMonth(currentDate)),
    lastDayIdx: getDay(endOfMonth(currentDate)),
    days: eachDayOfInterval({
      start: startOfMonth(currentDate),
      end: endOfMonth(currentDate),
    }),
  };

  const prevMonth: Month = {
    firstDay: startOfMonth(prevMonthDate),
    lastDay: endOfMonth(prevMonthDate),
    firstDayIdx: getDay(startOfMonth(prevMonthDate)),
    lastDayIdx: getDay(endOfMonth(prevMonthDate)),
    days: eachDayOfInterval({
      start: startOfMonth(prevMonthDate),
      end: endOfMonth(prevMonthDate),
    }),
  };

  const nextMonth: Month = {
    firstDay: startOfMonth(nextMonthDate),
    lastDay: endOfMonth(nextMonthDate),
    firstDayIdx: getDay(startOfMonth(nextMonthDate)),
    lastDayIdx: getDay(endOfMonth(nextMonthDate)),
    days: eachDayOfInterval({
      start: startOfMonth(nextMonthDate),
      end: endOfMonth(nextMonthDate),
    }),
  };

  const getPrevMonthDays = (currentMonth, prevMonth) => {
    if (currentMonth.firstDayIdx === 0) return prevMonth.days.slice(-6);
    if (currentMonth.firstDayIdx - 1 === 0) return prevMonth.days.slice(-7);
    return prevMonth.days.slice(-(currentMonth.firstDayIdx - 1));
  };

  const getNextMonthDays = (currentMonth, nextMonth) => {
    if (currentMonth.lastDayIdx === 0) return nextMonth.days.slice(0, 7);
    return nextMonth.days.slice(0, 7 - currentMonth.lastDayIdx);
  };

  const current = {
    type: "CURRENT",
    days: currentMonth.days,
  };

  const prev = {
    type: "PREV",
    days: getPrevMonthDays(currentMonth, prevMonth),
  };

  const next = {
    type: "NEXT",
    days: getNextMonthDays(currentMonth, nextMonth),
  };

  return { currentDate, current, prev, next, handleDateChange };
};
