import { toast } from "react-toastify";

const replaceKeyProperty = (component, componentName) => {
  const componentDefinitionClone = { ...component };

  if (componentDefinitionClone?.componentProps?.name === componentName) {
    // componentDefinitionClone.componentProps.key = {
    //   ...componentDefinitionClone.componentProps.key,
    // };
    componentDefinitionClone.componentProps.dataSource = {
      ...componentDefinitionClone.componentProps.dataSource,
    };
    return componentDefinitionClone;
  }
  if (
    componentDefinitionClone.childrenComponents &&
    Array.isArray(componentDefinitionClone.childrenComponents) &&
    componentDefinitionClone.childrenComponents.length > 0
  ) {
    for (let component of componentDefinitionClone.childrenComponents) {
      const result = replaceKeyProperty(component, componentName);
      if (result !== null) {
        return componentDefinitionClone;
      }
    }
  } else if (
    componentDefinitionClone.component.childrenComponents &&
    Array.isArray(componentDefinitionClone.component.childrenComponents) &&
    componentDefinitionClone.component.childrenComponents.length > 0
  ) {
    for (let component of componentDefinitionClone.component
      .childrenComponents) {
      const result = replaceKeyProperty(component, componentName);
      if (result !== null) {
        return componentDefinitionClone;
      }
    }
  }

  return null;
};

const getComponentContextStack = (componentContext) => {
  const componentContextStack =
    componentContext?.routerContext?.getActivePage()?.state?.sourceContextStack;
  if (Array.isArray(componentContextStack)) {
    return [
      componentContext,
      ...componentContextStack.map((c) => c.componentContext).reverse(),
    ];
  } else {
    return [componentContext];
  }
};

export const remountComponentBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: (staticParams) =>
      new Promise((resolve) => {
        const { componentName } = staticParams;

        const componentContextStack =
          getComponentContextStack(componentContext);

        for (let ctx of componentContextStack) {
          const component = replaceKeyProperty(ctx.component, componentName);
          if (component) {
            ctx.functions.updateComponentDefinition(component);
            resolve(staticParams);
            return;
          }
        }

        toast.warn(`Could not find component with name: ${componentName}`);

        resolve(staticParams);
      }),
  };
};
