import { Box, Button } from "@chakra-ui/react";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { MdOutlineNavigateNext, MdOutlineNavigateBefore } from "react-icons/md";

export const CalendarNavigation = (props) => {
  const { prevWeek, nextWeek, weekStart, weekEnd } = props;

  const getDay = (date) => format(date, "dd.MM");

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      gap="20px"
      padding="10px"
      fontSize="18px"
    >
      <Button variant="outline" onClick={prevWeek}>
        <MdOutlineNavigateBefore fontSize="20px" />
      </Button>
      <Box>
        {getDay(weekStart)} - {getDay(weekEnd)}
      </Box>
      <Button variant="outline" onClick={nextWeek}>
        <MdOutlineNavigateNext fontSize="20px" />
      </Button>
    </Box>
  );
};

CalendarNavigation.propTypes = {
  weekStart: PropTypes.instanceOf(Date),
  weekEnd: PropTypes.instanceOf(Date),
  nextWeek: PropTypes.func,
  prevWeek: PropTypes.func,
};
