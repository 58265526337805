export const useHistory = () => {
  return {
    goBack: () => window.console.info("go back"),
    push: (uri, props) => {
      const { origin } = window.location;
      if (typeof uri !== "string") {
        throw new Error("Bad 'uri' parameter type. Expected string.");
      }
      if (uri.includes(origin)) {
        window.history.pushState(props, document.title, uri);
      } else {
        window.history.pushState(
          props,
          document.title,
          `${origin}${uri.startsWith("/") ? "" : "/"}${uri}`
        );
      }
      window.dispatchEvent(
        new Event("uri_change", { cancelable: true, detail: { uri, props } })
      );
    },
  };
};
