export const getModelFromResult = (res) => {
  if (typeof res === "undefined" || res === null) {
    return res;
  }

  if (typeof res === "object" && Array.isArray(res)) {
    if (res.length > 0) {
      return typeof res[0] === "object" ? res[0] : null;
    }
  }

  return typeof res === "object" && !Array.isArray(res) ? res : null;
};
