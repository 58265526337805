import PropTypes from "prop-types";
import { Box } from "@chakra-ui/react";
import { withBaseComponent } from "../../../with-base-component";
import { useDebouncedValueState } from "../../../../../hooks/shared/use-debounced-value-state";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useEffect, useState } from "react";
import { showErrorToast } from "../../../../echo-error-toast";

const modules = {
  toolbar: {
    container: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
  },
};

const WysiwygComponent = ({ rootProps, value, onChange, name, readOnly }) => {
  const { nodeRef, ...restRootProps } = rootProps;
  const [valueQuill, setValueQuill] = useDebouncedValueState(
    value,
    onChange,
    name,
  );
  const [readOnlyValue, setReadOnlyValue] = useState(true);

  useEffect(() => {
    Promise.resolve(typeof readOnly === "function" ? readOnly() : readOnly)
      .then(setReadOnlyValue)
      .catch((err) =>
        showErrorToast({
          reasonTitle: "Cannot read readOnly prop",
          location: "WYSIWYG component",
          shortMessage: err.toString().slice(0, 300),
          message: err.toString(),
        }),
      );
  }, [readOnly]);

  return (
    <Box {...restRootProps} ref={nodeRef} className="wysiwyg">
      <ReactQuill
        readOnly={readOnlyValue}
        value={valueQuill}
        modules={modules}
        onChange={setValueQuill}
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        placeholder="Write text here"
        theme="snow"
      />
    </Box>
  );
};

WysiwygComponent.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  rootProps: PropTypes.object,
  readOnly: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

export default withBaseComponent(WysiwygComponent);
