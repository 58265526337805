import { toast } from "react-toastify";

export const showError = (field) => {
  const { componentProps } = field;
  const { label, name } = componentProps;
  toast.error(`Pole ${label ?? name} jest wymagane`);
};

export const showRequiredError = (field) => {
  const { componentProps } = field;
  const { label, name } = componentProps;
  toast.error(`Pole ${label ?? name} jest wymagane.`);
};

export const showUniqueError = (field) => {
  const { componentProps } = field;
  const { label, name } = componentProps;
  toast.error(`Wartość w polu ${label ?? name} już istnieje w bazie danych.`);
};
