import { useDisclosure } from "@chakra-ui/react";
import { useState } from "react";

export const useContextMenu = () => {
  const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });
  const { isOpen, onClose, onOpen } = useDisclosure();

  const openContextMenu = (e) => {
    if (!isOpen) {
      e.preventDefault();
      e.stopPropagation();

      setClickPosition({ x: e.clientX, y: e.clientY });
      onOpen(e);
    }
  };

  const closeContextMenu = (e) => {
    onClose(e);
  };

  return {
    clickPosition,
    openContextMenu,
    closeContextMenu,
    isContextMenuOpened: isOpen,
  };
};
