import React, { useRef } from "react";
import PropTypes from "prop-types";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
} from "@chakra-ui/react";
import { ManagerBody } from "../manager-body";

const ManagerDrawer = ({ context, isOpen, onClose }) => {
  const btnRef = useRef();

  return (
    <Drawer
      size="lg"
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Component manager</DrawerHeader>

        <DrawerBody>
          <ManagerBody context={context} />
        </DrawerBody>

        <DrawerFooter>
          <Button onClick={onClose} colorScheme="red">
            Close
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

ManagerDrawer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  context: PropTypes.any,
};

export default ManagerDrawer;
