import { useState } from "react";

export const useResize = (element) => {
  const [mousePos, setMousePos] = useState({ x: 0 });
  const [isMouseDown, setIsMouseDown] = useState(false);

  const onMouseDown = (e) => {
    setMousePos((prev) => ({ ...prev, x: e.clientX }));
    setIsMouseDown(true);
  };

  const onMouseUp = (e) => {
    const sizeChanged = e.clientX - mousePos.x;
    const columnWidthInt = parseInt(element.width.replace("px", ""));
    const newWidth = `${columnWidthInt + sizeChanged}px`;
    const resizedElement = { ...element, width: newWidth };
    setIsMouseDown(false);
    return resizedElement;
  };

  return {
    isMouseDown,
    onMouseDown,
    onMouseUp,
    mousePos,
  };
};
