// @flow
import React from "react";
import type { Column } from "../../datagrid-v2";
import { useDatagridContext } from "../../hooks/useDatagridContext";
import { EditCell } from "./edit-cell";
import { DisplayCell } from "./display-cell";

type FooterCellProps = {
  column: Column,
  value: string | Date | number,
  row: any,
  onClick: () => void,
};

export const FooterCell = (props: FooterCellProps) => {
  const { enableAdding } = useDatagridContext();
  const { column, onChange, value, onClick, row, style } = props;

  return enableAdding ? (
    <EditCell column={column} onChange={onChange} value={value} row={row} />
  ) : (
    <DisplayCell
      column={column}
      value={value}
      onClick={onClick}
      style={style}
      row={row}
    />
  );
};
