import { executeBlock, executeBlockByGroup } from '../process-executor';

export const updateFormBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: (staticParams, params, systemParams) =>
      new Promise((resolve, reject) => {
        const action = componentContext.functions.getUpdateAction();
        const formSource =
          componentContext.functions.getStateProp('formSource');
        if (action.type === 'PROCESS') {
          Promise.resolve([
            executeBlock(
              componentContext,
              action.id,
              { ...staticParams, model: formSource },
              params,
              systemParams
            ),
          ])
            .then(([res]) => {
              resolve(res);
              componentContext.history.goBack();
            })
            .catch(reject);
        } else if (action.type === 'BLOCK_GROUP') {
          Promise.resolve([
            executeBlockByGroup(
              componentContext,
              { group: action.name, tag: 'UPDATE' },
              { ...staticParams, ...action.staticParams, model: formSource },
              params,
              systemParams
            ),
          ])
            .then(([res]) => {
              resolve(res);
              componentContext.history.goBack();
            })
            .catch(reject);
        } else {
          throw new Error('Unexpected action type');
        }
      }),
  };
};
