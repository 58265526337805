export const sendEventBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: (staticParams, params, systemParams) =>
      new Promise((resolve, reject) => {
        try {
          if (staticParams.eventName) {
            let { callerId, callerGuid, callerNodeId } = systemParams;
            window.dispatchEvent(
              new CustomEvent(staticParams.eventName, {
                cancelable: true,
                bubbles: staticParams.bubbles || false,
                detail: {
                  params,
                  callerId,
                  callerGuid,
                  callerNodeId,
                  root: componentContext.component,
                },
              }),
            );
          }
          resolve(staticParams);
        } catch (err) {
          reject(err);
        }
      }),
    getMetadata: () => {},
  };
};
