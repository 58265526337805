import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import BaseModal from "../../../base-modal/base-modal";
import BlockGroupSelector from "../../../selectors/block-group-selector/block-group-selector";
import QuestionAlert from "../../../echo-components/base-components/components/question-alert-component/question-alert.component";
import { FiTrash2 } from "react-icons/fi";
import { echoPortal } from "../../../../echo-portal/echo-portal";

const ProcessGroupSelect = ({ propValue = { name: "" }, onChange, tag }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (propValue) {
      setValue(propValue);
    }
  }, [propValue]);

  const handleSelect = () => {
    Promise.all([
      onChange({
        type: "BLOCK_GROUP",
        name: value.name,
        id: value.id,
        tag,
        staticParams: value.staticParams,
      }),
      setModalOpen(false),
    ]);
  };

  const handleOnDelete = () => {
    echoPortal.render((container) => (
      <QuestionAlert
        open={true}
        header={"Erase property value"}
        question={"Do you really want to erase this property?"}
        alertMode={"Yes/No"}
        onAction={(type) => {
          if (type === "Yes" && onChange) {
            onChange(null);
          }
          container.unmount();
        }}
      />
    ));
  };

  return (
    <React.Fragment>
      <BaseModal
        open={modalOpen}
        onSubmit={handleSelect}
        onClose={() => Promise.all([setModalOpen(false), setValue(propValue)])}
      >
        <BlockGroupSelector
          value={value}
          title="Select block group from list"
          tag={tag}
          onChange={setValue}
        />
      </BaseModal>
      <div
        style={{
          display: "flex",
          justifyContent: propValue ? "space-between" : "flex-end",
          flexDirection: "row",
          marginLeft: "8px",
        }}
      >
        <div
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {propValue?.name}
        </div>
        <button onClick={() => setModalOpen(!modalOpen)}>...</button>
        <button onClick={handleOnDelete}>
          <FiTrash2 size="16px" />{" "}
        </button>
      </div>
    </React.Fragment>
  );
};

ProcessGroupSelect.propTypes = {
  tag: PropTypes.string.isRequired,
  propValue: PropTypes.any,
  onChange: PropTypes.func,
};

export default ProcessGroupSelect;
