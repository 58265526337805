// @flow

import { format } from "date-fns";
import { useEffect, useRef, useState } from "react";

const getDateFromPromise = async (promise: Promise, dateFormat: string) => {
  const res = await promise();
  return format(res ? new Date(res) : new Date(), dateFormat);
};

const getDateStringFromObj = (dateObj: Date, dateFormat: string) =>
  format(dateObj, dateFormat);

const getDateStringFromTime = (timeValue: number, dateFormat: string) => {
  const date = new Date(timeValue);
  return getDateStringFromObj(date, dateFormat);
};

const getDateStringFromISO = (date: string, dateFormat: string) => {
  if (date.includes("T")) {
    const dateObj = new Date(date);
    return getDateStringFromObj(dateObj, dateFormat);
  }

  return date;
};

export const getFormattedDate = (
  date: Date | string | number,
  dateTimeFormat: string,
) => {
  const { dateFormat } = extractDateTimeFormats(dateTimeFormat);
  const dateType = typeof date;
  if (date) {
    switch (dateType) {
      case "function":
        return getDateFromPromise(date, dateFormat || "dd-MM-yyyy");
      case "string":
        return getDateStringFromISO(date, dateFormat || "dd-MM-yyyy");
      case "object":
        return getDateStringFromObj(date, dateFormat || "dd-MM-yyyy");
      case "number":
        return getDateStringFromTime(date, dateFormat || "dd-MM-yyyy");
      default:
        return date;
    }
  }

  return "";
};

const findIndexes = (arr: string[], el: string) => {
  let idxArr = [];
  arr.forEach((c, idx) => {
    if (c === el) idxArr.push(idx);
  });
  return idxArr;
};

const getValue = (date: string, format: string, char: string) => {
  if (!format) return null;
  const dateArr = date.split("");
  const formatArr = format.split("");
  const idxArr = findIndexes(formatArr, char);
  return idxArr.map((idx) => dateArr.find((_, id) => id === idx)).join("");
};

const getDatePartsValues = (dateObj: Date, dateTimeFormat: string) => {
  const { dateFormat, timeFormat } = extractDateTimeFormats(dateTimeFormat);
  const day = getValue(dateObj.date, dateFormat, "d") || "01";
  const month = getValue(dateObj.date, dateFormat, "M") || "01";
  const year = getValue(dateObj.date, dateFormat, "y") || "1970";
  const hours = getValue(dateObj.time, timeFormat, "H") || "00";
  const minutes = getValue(dateObj.time, timeFormat, "m") || "00";
  return {
    day,
    month,
    year,
    hours,
    minutes,
  };
};

const getSqlDateString = (dateObj: Date, dateTimeFormat: string) => {
  const { day, month, year, hours, minutes } = getDatePartsValues(
    dateObj,
    dateTimeFormat,
  );
  return `${year}-${month}-${day}T${hours}:${minutes}:00`;
};

const extractDateTimeFormats = (dateTimeFormat: string) => {
  const parts = dateTimeFormat.split(" ");
  let dateFormat = null;
  let timeFormat = null;

  for (const part of parts) {
    if (part.includes("-") || part.includes("/") || part.includes(".")) {
      dateFormat = part;
      break;
    }
  }

  for (const part of parts) {
    if (part.includes(":")) {
      timeFormat = part;
      break;
    }
  }

  return { dateFormat, timeFormat };
};

const getInitialDate = (
  initialDate: Date | string | number,
  dateTimeFormat: string,
) => {
  const { dateFormat, timeFormat } = extractDateTimeFormats(
    dateTimeFormat || "dd-MM-yyyy",
  );

  let result = { date: "", time: "" };
  if (initialDate === "") return { date: "", time: "" };

  try {
    const value = new Date(initialDate);
    const date = format(value, dateFormat || "dd-MM-yyyy");
    result = { ...result, date };

    if (timeFormat) {
      const time = format(value, timeFormat || "HH:mm");
      result = { ...result, time };
    }
  } catch {
    result = { date: "", time: "" };
  }

  return result;
};

export const useDate = (
  initialDate: Date | string | number,
  dateTimeFormat: string,
  callback: () => void,
) => {
  const [dateTimeValue, setDateTimeValue] = useState({
    date: "",
    time: "",
  });

  const handleDate = (dateObj) => {
    const { date, time } = dateObj;
    if (date !== dateTimeValue.date)
      setDateTimeValue((prev) => ({ ...prev, date }));

    if (dateTimeValue.time === "" && time !== dateTimeValue.time)
      setDateTimeValue((prev) => ({ ...prev, time }));
  };

  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (firstRenderRef.current === false) {
      let timeout;

      if (dateTimeValue.date || dateTimeValue.date === "") {
        timeout = setTimeout(() => {
          try {
            if (dateTimeValue.date === "") {
              callback("");
            } else {
              const sqlDateString = getSqlDateString(
                dateTimeValue,
                dateTimeFormat || "dd-MM-yyyy",
              );

              callback(sqlDateString);
            }
          } catch (e) {
            console.error(e);
          }
        }, 600);
        // }
      }

      return () => clearTimeout(timeout);
    }

    firstRenderRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateTimeValue, dateTimeFormat]);

  useEffect(() => {
    if (initialDate === "") return;

    if (initialDate && initialDate !== "") {
      const date = getInitialDate(initialDate, dateTimeFormat);
      handleDate(date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { dateTimeValue, handleDate };
};
