import { useEffect, useState } from "react";
import { showErrorToast } from "../../../../../../shared/echo-error-toast";
import { getData } from "../util/source";

export const useGraphSource = (elementsFunc, connectionsFunc) => {
  const [source, setSource] = useState(null);

  useEffect(() => {
    if (source === null) {
      Promise.all([getData(elementsFunc), getData(connectionsFunc)])
        .then(([elements, connections]) => {
          setSource({ elements, connections });
        })
        .catch((err) =>
          showErrorToast({
            reasonTitle: "Error",
            location: "Graph designer",
            shortMessage:
              "Error. Cannot load graph source data. Please check elements and connections source diagrams.",
            message: err.toString(),
          })
        );
    }
  }, [elementsFunc, connectionsFunc, source]);

  return [source || { elements: [], connections: [] }, setSource];
};
