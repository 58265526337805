import { Map, useApiLoadingStatus } from "@vis.gl/react-google-maps";
import { MarkerWithInfoWindow } from "./marker-with-info-window";
import PropTypes from "prop-types";
import { useGeocoding } from "../hooks/use-geocoding";
import { LoadingScreen } from "./loading-screen";
import { useGoogleMap } from "../hooks/use-google-map";
import { Box } from "@chakra-ui/react";
import { SearchBar } from "./search-bar";
import { INITIAL_MAP_CENTER, INITIAL_MAP_ZOOM } from "../utils/variables";

export const GoogleMap = ({ id, defaultCenter, value, searchBar }) => {
  const geoLib = useGeocoding();
  const apiLoadingStatus = useApiLoadingStatus();

  const { mapProps, onCameraChange, onSelectionChange, selected } =
    useGoogleMap(value, defaultCenter);

  const onSearch = async (val) => {
    const location = await geoLib.getLocationByAddress(val);

    if (location) onSelectionChange(location);
  };

  if (!mapProps.center || apiLoadingStatus === "LOADING") {
    return <LoadingScreen />;
  }

  return (
    <>
      {searchBar && <SearchBar onSearch={onSearch} />}
      <Map
        {...mapProps}
        mapId={id}
        draggingCursor="move"
        draggableCursor="default"
        onCameraChanged={onCameraChange}
        defaultCenter={INITIAL_MAP_CENTER}
        defaultZoom={INITIAL_MAP_ZOOM}
      >
        {selected && (
          <MarkerWithInfoWindow defaultIsOpen marker={selected}>
            <Box>{selected.address}</Box>
          </MarkerWithInfoWindow>
        )}
      </Map>
    </>
  );
};

GoogleMap.propTypes = {
  id: PropTypes.string,
  defaultCenter: PropTypes.string,
  defaultZoom: PropTypes.number,
  value: PropTypes.string,
  searchBar: PropTypes.bool,

  markers: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
      }),
    }),
  ),
};
