import { showErrorToast } from "../../components/shared/echo-error-toast";
import { calculateProp } from "../../echo-component/function-wrapper/calculate-prop";
import { getCallerComponentPropDefinition } from "./utils/get-caller-component-prop-definition";

const handleError = (err, reject) => {
  window.console.error(err);
  showErrorToast(err);
  reject(err);
};

export const executeCallerComponentProp = (block, componentContext) => ({
  definition: block,
  execute: (staticParams, params, systemParams) =>
    new Promise((resolve, reject) => {
      try {
        const propDefinition = getCallerComponentPropDefinition(
          systemParams,
          componentContext,
          staticParams.name
        );

        if (
          !propDefinition ||
          !propDefinition.prop ||
          propDefinition.prop.type !== "PROCESS"
        ) {
          // TODO error type
          reject("Execute caller component prop: Bad prop type");
        }

        const value = calculateProp(
          propDefinition.prop,
          componentContext,
          propDefinition.parent.componentProps,
          propDefinition.callerId,
          propDefinition.callerGuid,
          propDefinition.callerNodeId
        );

        Promise.resolve(value())
          .then((result) =>
            resolve({
              ...staticParams,
              variables: [
                ...(staticParams.variables || []),
                {
                  name: staticParams.toName || staticParams.name,
                  value: result,
                },
              ],
            })
          )
          .catch((err) => handleError(err, reject));
      } catch (err) {
        handleError(err, reject);
      }
    }),
});
