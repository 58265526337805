import { showErrorToast } from "../../components/shared/echo-error-toast";
import { calculateProp } from "../../echo-component/function-wrapper/calculate-prop";
import {
  parseCommaSeparatedParams,
  validateParametersString,
} from "./utils/comma-separated-params";
import { validateUrlParameters } from "./utils/url-params";

const afterCreateEventsDescriptions = {
  OVERRIDE_EVENT_PROP: ["callbackEvent", "componentName", "propertyName"],
  SAVE_CALLBACK: [],
  DEFAULT: [],
};

const afterCreateEventsNames = Object.keys(afterCreateEventsDescriptions);

const validateAfterCreateEvent = (
  afterCreateEventName,
  afterCreateEventParams,
  callbackEvent,
) => {
  if (afterCreateEventName && callbackEvent) {
    const isNameValid = afterCreateEventsNames.includes(afterCreateEventName);
    const isParamsObjectValid =
      (Array.isArray(afterCreateEventsDescriptions[afterCreateEventName]) &&
        afterCreateEventsDescriptions[afterCreateEventName].length === 0) ||
      (typeof afterCreateEventParams === "object" &&
        !Array.isArray(afterCreateEventParams) &&
        Array.isArray(afterCreateEventsDescriptions[afterCreateEventName]) &&
        !validateParametersString(afterCreateEventParams) &&
        (afterCreateEventParams || "")
          .split(",")
          .map((kvp) => kvp.split("=")[0])
          .every((key) =>
            afterCreateEventsDescriptions[afterCreateEventName].includes(key),
          ));
    const isCallbackEventValid =
      typeof callbackEvent === "object" &&
      !Array.isArray(callbackEvent) &&
      callbackEvent.id &&
      callbackEvent.type === "PROCESS";
    return isNameValid && isParamsObjectValid && isCallbackEventValid;
  }
  return true;
};

const getAfterCreateEvent = (
  url,
  callbackEvent,
  afterCreateEventName,
  afterCreateEventParams,
  context,
  callerId,
  callerGuid,
  callerNodeId,
) => {
  if (!afterCreateEventName || !callbackEvent) {
    return null;
  }
  const event = {
    ...callbackEvent,
    source: { context, callerId, callerGuid, callerNodeId },
  };
  const eventParams = {};
  const customParams = {};
  Object.keys(afterCreateEventParams || {}).forEach((key) => {
    if (afterCreateEventsNames.includes(key)) {
      eventParams[key] = afterCreateEventParams[key];
    } else {
      customParams[key] = afterCreateEventParams[key];
    }
  });
  return {
    afterCreateEventName,
    ...afterCreateEventParams,
    customParams,
    callbackEvent:
      url === "diagramDesigner"
        ? calculateProp(
            callbackEvent,
            context,
            { name: "undefined" },
            callerId,
            callerGuid,
            callerNodeId,
            "",
          )
        : event,
  };
};

const getModelsCollection = (
  forwardModel,
  modelName,
  componentContext,
  contextModel,
) => {
  const existingCollection =
    componentContext?.routerContext?.getActivePage()?.state?.modelsCollection;

  return forwardModel
    ? {
        ...(existingCollection || {}),
        [modelName]:
          contextModel || componentContext?.functions?.getModel()?.formSource,
      }
    : {};
};

const getParams = (params) => params.filter((p) => !p?.target);

const getUrl = (url, urlParameters, componentContext) => {
  if (urlParameters && urlParameters.startsWith("/")) {
    const formSource = componentContext?.functions?.getModel()?.formSource;
    if (formSource) {
      const paramValue = [formSource[urlParameters.substring(1)]];
      if (paramValue) {
        return url.endsWith("/")
          ? `${url}/${paramValue}`
          : `/${url}/${paramValue}`;
      }
    }
  } else if (urlParameters) {
    return `${url}?${urlParameters}`;
  }
  return url;
};

const getSourceContextStack = (componentContext, modelName) => {
  const existingCollection =
    componentContext?.routerContext?.getActivePage()?.state?.sourceContextStack;

  return existingCollection && Array.isArray(existingCollection)
    ? [...existingCollection, { name: modelName, componentContext }]
    : [{ name: modelName, componentContext }];
};

const getTabState = ({
  url,
  forwardModel,
  modelName,
  callbackEvent,
  afterCreateEventName,
  afterCreateEventParams,
  params,
  componentContext,
  callerId,
  callerGuid,
  callerNodeId,
  contextModel,
  staticParams,
}) => ({
  params: [...getParams(params), staticParams],
  modelsCollection: getModelsCollection(
    forwardModel,
    modelName,
    componentContext,
    contextModel,
  ),
  sourceContextStack: getSourceContextStack(componentContext, modelName),
  afterCreateEvent: getAfterCreateEvent(
    url,
    callbackEvent,
    afterCreateEventName,
    afterCreateEventParams,
    componentContext,
    callerId,
    callerGuid,
    callerNodeId,
  ),
});

const validateParams = (staticParams, params, reject) => {
  const resultErrors = [];
  if (typeof params[1]?.url !== "string" && !staticParams.url) {
    const msg = 'The "url" parameter must be specified.';
    resultErrors.push(msg);
  }
  if (!validateParametersString(staticParams.afterCreateEventParams)) {
    resultErrors.push(
      "The 'afterCreateEventParams' parameter value is not valid.",
    );
  }

  if (
    !validateAfterCreateEvent(
      staticParams.afterCreateEventName,
      staticParams.afterCreateEventParams,
      staticParams.callbackEvent,
    )
  ) {
    resultErrors.push(
      `Invalid after create event parameters. Name should be equal one of element: ${Object.keys(
        afterCreateEventsDescriptions,
      ).join(", ")}.\nEvent parameters and callback event must be specified.`,
    );
  }

  if (!validateUrlParameters(staticParams.urlParameters)) {
    resultErrors.push("The 'urlParameters' parameter value is not valid.");
  }

  if (resultErrors && resultErrors.length) {
    showErrorToast({
      reasonTitle: "Error. Cannot open tab",
      location: "openURL block",
      shortMessage: "Validation error.",
      message: resultErrors.join("\n"),
    });
    reject(resultErrors.join("\n"));
  }
};

const calculateParams = (staticParams, params) => {
  const url =
    typeof params[1]?.url === "string"
      ? params[1].url
      : staticParams.url
        ? staticParams.url
        : params[0];
  const urlParameters = staticParams?.urlParameters;
  const forwardModel = staticParams?.forwardModel;
  const tabName = staticParams?.tabName;
  const modelName = staticParams.modelName || "Parent";

  const callbackEvent = staticParams?.callbackEvent;
  const afterCreateEventName = staticParams?.afterCreateEventName || "DEFAULT";
  const afterCreateEventParams = parseCommaSeparatedParams(
    staticParams?.afterCreateEventParams,
  );

  return {
    url,
    urlParameters,
    forwardModel,
    tabName,
    modelName,
    callbackEvent,
    afterCreateEventName,
    afterCreateEventParams,
  };
};

export const openTabBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: (staticParams, params, systemParams) =>
      new Promise((resolve, reject) => {
        const { callerId, callerGuid, callerNodeId } = systemParams;

        const {
          url,
          urlParameters,
          tabName,
          forwardModel,
          modelName,
          callbackEvent,
          afterCreateEventName,
          afterCreateEventParams,
        } = calculateParams(staticParams, params);
        validateParams(staticParams, params, reject);

        const pageUrl = getUrl(url, urlParameters, componentContext);
        const tabState = getTabState({
          url,
          urlParameters,
          forwardModel,
          modelName,
          callbackEvent,
          afterCreateEventName,
          afterCreateEventParams,
          params,
          componentContext,
          callerId,
          callerGuid,
          callerNodeId,
          contextModel: staticParams?.__model,
          staticParams,
        });

        const key = componentContext.routerContext.openPage(
          pageUrl,
          tabState,
          tabName,
          true,
          getParams(params),
          staticParams.componentProps,
        );

        resolve({ openedKey: key });
        return params;
      }),
  };
};
