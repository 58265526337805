const getStoredPages = (itemName) =>
  JSON.parse(sessionStorage.getItem(itemName));

const storePages = (itemName, pages) =>
  sessionStorage.setItem(itemName, JSON.stringify(pages));

const clearPages = (itemName) => sessionStorage.removeItem(itemName);

export const pagesStore = (name) => {
  const storedPages = getStoredPages(name);
  const pages =
    storedPages || (storedPages && storedPages.length) > 0
      ? storedPages
      : [null];

  const addPage = (newPage, storageName) => {
    const storedPages = getStoredPages(storageName);
    const pages =
      storedPages || (storedPages && storedPages.length) > 0
        ? storedPages
        : [null];
    if (newPage && !pages.find((p) => p === newPage)) {
      const newPages = [...pages, newPage];
      storePages(name, newPages);
    }
  };

  const clearStore = (name) => clearPages(name);

  return { pages, addPage, clearStore };
};
