import { uuidv4 } from "@echo/tools";
import { toast } from "react-toastify";
import { saveBlock } from "../../../services/diagram-service";

const validate = (block) => {
  let error = false;
  if (!block.name || !block.description) {
    if (!block.private) {
      toast.error(
        "Cannot save diagram. Please fill all required fields in diagram settings"
      );
      error = true;
    } else {
      if (!block.name) {
        block.name = uuidv4();
      }
      if (!block.description) {
        block.description = uuidv4();
      }
    }
  }
  if (!block.blocks || !block.blocks.length) {
    toast.error("Cannot save diagram without any block.");
    error = true;
  }
  if (
    !block.links ||
    (!block.links.length && block.blocks.length) > 1 ||
    (block.blocks.some((processBlock) =>
      block.links.includes(
        (l) =>
          l.sourceProcessBlockId === processBlock.designerData.id ||
          l.destinationProcessBlockId === processBlock.designerData.id
      )
    ) &&
      block.blocks.length > 1)
  ) {
    toast.error("Cannot save diagram with non connected blocks.");
    error = true;
  }
  return !error;
};

export const saveDiagram = (block) =>
  new Promise((resolve, reject) => {
    if (validate(block)) {
      saveBlock(block).then(resolve).catch(reject);
    }
  });
