import PropTypes from "prop-types";
import { Box } from "@chakra-ui/react";
import DisplayCell from "./display-cell/display-cell";
import EditCell from "./edit-cell/edit-cell";
import { useCallback, useEffect, useState } from "react";
import { executeBlock } from "../../../../../../../../process-executor/process-executor";

const DataGridRowCell = //memo(
  ({
    row,
    width,
    name,
    editName,
    onEdit,
    onRowEdit,
    mode,
    type,
    editType,
    fontSize,
    format,
    options,
    editable,
    context,
    editDataSource,
    onClick,
    whiteSpace,
    noOfLines,
  }) => {
    // const [hover, setHover] = useState(false);
    const [editTypeState, setEditTypeState] = useState("input");

    const handleOnEdit = useCallback(
      (newValue) => {
        if (onEdit) {
          onEdit({ ...row, [editName || name]: newValue });
        }

        if (editTypeState === "checkbox") {
          onRowEdit({ ...row, [editName || name]: newValue });
        }
      },
      [editName, name, onEdit, row, editTypeState, onRowEdit],
    );

    useEffect(() => {
      let isMounted = true;
      if (context && editType && editType.type === "PROCESS") {
        executeBlock(context, editType.id, editType.staticParams ?? {}, [row])
          .then((result) => {
            if (isMounted) {
              if (
                Array.isArray(result) &&
                result.length &&
                typeof result[0] === "object"
              ) {
                setEditTypeState(Object.values(result[0])[0]);
              } else if (typeof result === "object") {
                setEditTypeState(Object.values(result)[0]);
              } else {
                setEditTypeState(result);
              }
            }
          })
          .catch(() => {
            //showErrorToast(err);
          });
      } else {
        setEditTypeState(editType);
      }
    }, [context, editType, editTypeState, row]);

    const defaultTextAlign = type === "number" ? "right" : "left";

    return (
      <Box
        as="td"
        // onMouseEnter={() => (editable ? setHover(true) : undefined)}
        // onMouseLeave={() => (editable ? setHover(false) : undefined)}
        display="table-cell"
        fontSize={fontSize}
        width={width || "auto"}
        minWidth={width || "auto"}
        maxWidth={width || "auto"}
        style={{
          position: "relative",
          overflow: "hidden",
          borderRight: "1px solid black",
          padding: "0 3px",
          textAlign: options?.align || defaultTextAlign,
        }}
        onDoubleClick={(e) => {
          if (mode === "edit") {
            e.stopPropagation();
            e.preventDefault();
          }
        }}
        onClick={onClick}
      >
        <Box
          style={{
            textOverflow: "ellipsis",
            whiteSpace: whiteSpace || "nowrap",
            overflow: "hidden",
          }}
          className="cell-box"
          noOfLines={noOfLines}
        >
          {(mode === "edit" || editTypeState === "checkbox") && editable ? (
            <EditCell
              type={editTypeState}
              value={
                typeof row === "object" && !Array.isArray(row)
                  ? row[editName || name]
                  : ""
              }
              onChange={handleOnEdit}
              context={context}
              options={options}
              dataSource={editDataSource}
              formatStr={format}
              row={row}
            />
          ) : (
            <DisplayCell
              type={type}
              value={
                typeof row === "object" && !Array.isArray(row) ? row[name] : ""
              }
              formatStr={format}
            />
          )}
          {/* {mode !== "edit" && editable && hover && (
            <Box
              position="absolute"
              height="12px"
              width="12px"
              top="4px"
              right="4px"
            >
              <FiEdit3 />
            </Box>
          )} */}
        </Box>
      </Box>
    );
  };

DataGridRowCell.displayName = "DataGridRowCell";

DataGridRowCell.propTypes = {
  onClick: PropTypes.func,
  row: PropTypes.object,
  name: PropTypes.string,
  width: PropTypes.string,
  editName: PropTypes.string,
  mode: PropTypes.oneOf(["display", "edit"]),
  type: PropTypes.string,
  editType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.bool,
  ]),
  format: PropTypes.string,
  onEdit: PropTypes.func,
  onRowEdit: PropTypes.func,
  options: PropTypes.shape({
    align: PropTypes.oneOf(["left", "center", "right"]),
  }),
  editable: PropTypes.bool,
  context: PropTypes.any,
  editDataSource: PropTypes.any,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  whiteSpace: PropTypes.string,
  noOfLines: PropTypes.string,
};

export default DataGridRowCell;
