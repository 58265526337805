export const checkOrder = (element, ignorePredicate) => {
  if (element && element?.parentNode?.childNodes) {
    return Array.prototype.slice
      .call(element.parentNode.childNodes)
      .filter(ignorePredicate || (() => true))
      .findIndex((e) => e === element);
  }

  return -1;
};
