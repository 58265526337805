export const treeStateReducer = (prevState, action) => {
  switch (action.type) {
    case 'SET_SELECTION':
      return {
        ...prevState,
        __v: (prevState.__v || 0) + 1,
        selection: action.payload.selection,
      };
    case 'UNSET_SELECTION':
      return { ...prevState, __v: (prevState.__v || 0) + 1, selection: null };
    default:
      return prevState;
  }
};
