export const getParent = (
  definitionRoot,
  componentId,
  componentGuid,
  componentNodeId
) => {
  if (
    definitionRoot.id === componentId &&
    definitionRoot.guid === componentGuid &&
    definitionRoot.nodeId === componentNodeId
  ) {
    return null; // component is root and does not have any parents
  }

  // search in related component too
  if (definitionRoot.component) {
    const component = getParent(
      definitionRoot.component,
      componentId,
      componentGuid,
      componentNodeId
    );
    if (component !== null) {
      return component;
    }
  }

  if (
    !definitionRoot.childrenComponents ||
    !definitionRoot.childrenComponents.length
  ) {
    // not found
    return null;
  }

  for (let i = 0; i < definitionRoot.childrenComponents.length; i++) {
    const component = definitionRoot.childrenComponents[i];
    if (
      component?.id === componentId &&
      component?.guid === componentGuid &&
      component?.nodeId === componentNodeId
    ) {
      // found! return last parent found
      return definitionRoot;
    } else if (
      component?.component?.id === componentId &&
      component?.component?.guid === componentGuid &&
      component?.component?.nodeId === componentNodeId
    ) {
      return component;
    } else {
      const nestedSearch = getParent(
        component,
        componentId,
        componentGuid,
        componentNodeId
      );
      if (nestedSearch === null) {
        // not found, continue search
        continue;
      } else {
        return nestedSearch;
      }
    }
  }

  return null;
};
