import PropTypes from "prop-types";
import { Input } from "@chakra-ui/react";

const InputEditCell = ({ value, onChange, options }) => {
  const { align } = options || {};

  return (
    <Input
      // autoFocus
      style={{ minWidth: "50px", maxWidth: "auto", width: "100%" }}
      size="sm"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      value={value || ""}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      textAlign={align}
    />
  );
};

InputEditCell.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  props: PropTypes.any,
  options: PropTypes.shape({
    align: PropTypes.oneOf(["left", "center", "right"]),
  }),
  formatStr: PropTypes.any,
};

export default InputEditCell;
