import { useMemo } from "react";
import { useSearch } from "./use-search";
import {
  getComponentsByType,
  getDataComponents,
} from "../utils/get-components-by-type";

export const useToolboxComponents = (items, formComponents) => {
  const { getFilteredArr, handleSearchValue, searchValue } = useSearch();

  const { components, containers, templates } = useMemo(
    () => getComponentsByType(items),
    [items],
  );

  const dataComponents = useMemo(
    () =>
      formComponents && formComponents.length > 0
        ? getDataComponents(formComponents, items)
        : [],
    [formComponents, items],
  );

  const filteredComponents = useMemo(
    () => getFilteredArr(components, "name"),
    [components, getFilteredArr],
  );

  const filteredContainers = useMemo(
    () => getFilteredArr(containers, "name"),
    [containers, getFilteredArr],
  );

  const filteredTemplates = useMemo(
    () => getFilteredArr(templates, "name"),
    [templates, getFilteredArr],
  );

  const filteredDataComponents = useMemo(
    () => getFilteredArr(dataComponents, "displayName"),
    [dataComponents, getFilteredArr],
  );

  const componentsList = useMemo(
    () => ({
      title: "Komponenty Echo",
      type: "standardComponents",
      items: filteredComponents,
    }),
    [filteredComponents],
  );

  const containersList = useMemo(
    () => ({
      title: "Kontenery Echo",
      type: "standardComponents",
      items: filteredContainers,
    }),
    [filteredContainers],
  );

  const templatesList = useMemo(
    () => ({
      title: "Formatki Echo",
      type: "standardComponents",
      items: filteredTemplates,
    }),
    [filteredTemplates],
  );

  const dataComponentsList = useMemo(
    () => ({
      title: "Kontrolki źródła",
      type: "formComponents",
      items: filteredDataComponents,
    }),
    [filteredDataComponents],
  );

  return {
    componentsList,
    containersList,
    templatesList,
    dataComponentsList,
    filterList: handleSearchValue,
    filterValue: searchValue,
  };
};
