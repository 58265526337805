import { Box } from "@chakra-ui/react";
import { Layout } from "./layout";
import { RowLabels } from "./row-labels";
import { Event, EventsList } from "./events-list";
import {
  DAY_HOURS,
  GRID_TEMPLATE_COLUMNS,
  GRID_TEMPLATE_ROWS,
  mapEventToSql,
} from "../utils/calendar-utils";
import PropTypes from "prop-types";
import { addHours, format } from "date-fns";
import { useCalendarDragContext } from "../hooks/use-calendar-drag-context";

export const CalendarBody = (props) => {
  const {
    weekDays,
    events,
    onEventAdd,
    onEventEdit,
    onEventMove,
    availableCalendars,
  } = props;
  const { draggedElement, dragState } = useCalendarDragContext();

  const handleEventAdd = (eventDate) => {
    const startDate = eventDate;
    const endDate = addHours(startDate, 1);

    const sqlEvent = mapEventToSql({
      id: null,
      from: startDate,
      to: endDate,
    });

    if (onEventAdd) onEventAdd(sqlEvent);
  };

  const handleEventEdit = (event) => {
    const sqlEvent = mapEventToSql(event);
    if (onEventEdit) onEventEdit({ ...sqlEvent });
  };

  return (
    <Box
      height="100%"
      width="100%"
      position="relative"
      display="flex"
      gap="5px"
      cursor={() => {
        if (!dragState) return "pointer";
        if (dragState === "drag") return "move";
        if (dragState.includes("resize-")) return "n-resize";
        return "pointer";
      }}
    >
      {/* labels for rows */}
      <RowLabels labels={DAY_HOURS} />

      {/* actual grid */}
      <Box
        width="100%"
        height="100%"
        display="grid"
        gridTemplateRows={GRID_TEMPLATE_ROWS}
        gridTemplateColumns={GRID_TEMPLATE_COLUMNS}
      >
        {/* layout */}
        <Layout
          onCellClick={handleEventAdd}
          onEventMove={onEventMove}
          columns={weekDays}
          rows={DAY_HOURS.length * 4}
        />

        {/* events */}
        <EventsList
          events={events}
          onEventClick={handleEventEdit}
          onEventMove={onEventMove}
          availableCalendars={availableCalendars}
        />

        {/* drag placeholder event element */}
        {draggedElement && (
          <Event
            eventsList={events}
            background={
              availableCalendars.find((c) => c.id === draggedElement.calendarId)
                .background
            }
            zIndex="100"
            event={draggedElement}
            onClick={() => {}}
            onMove={() => {}}
          />
        )}
      </Box>
    </Box>
  );
};

CalendarBody.propTypes = {
  weekDays: PropTypes.array,
  events: PropTypes.array,
  onEventAdd: PropTypes.func,
  onEventEdit: PropTypes.func,
  onEventMove: PropTypes.func,
  availableCalendars: PropTypes.array,
};
