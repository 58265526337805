import { withEchoComponent } from "../../../../../../../../echo-component/function-wrapper/function-wrapper";
import { getDesignerStyleOptions } from "../../../../../../../echo-components/component-designer/utils/get-designer-style-options";
import EchoComponent from "../../../../../echo-component";
import DesignerChild from "../../../flex-component/designer-child";
import { getBelowContainerId } from "../../../../../../../echo-components/component-designer/utils/get-below-container-id";
import PropTypes from "prop-types";

export const ListDesignerView = (props) => {
  const {
    id,
    path,
    childs,
    onComponentClick,
    designerMode,
    onMoveElement,

    activeAreaId,
    activePropertiesComponentId,
    dragMode,
    onDropAreaChange,
  } = props;

  const handleOnDrag = (child, onAreaChange, e) => {
    const id = getBelowContainerId(e, child.id);
    onAreaChange(id);
  };

  return childs.map((child) => {
    return (
      <DesignerChild
        id={child.id}
        key={`child-${child.id}`}
        freeze={
          child.component.source !== "!?echo-defined" &&
          child.component.type !== "Container" &&
          child.component.type !== "Template"
        }
        onStop={(e, ui) => {
          if (Math.abs(ui.lastX) > 5 || Math.abs(ui.lastX) > 5) {
            Promise.all([onMoveElement(child)]);
          }
        }}
        onDrag={(e) => {
          handleOnDrag(child, onDropAreaChange, e);
        }}
      >
        {(childNodeRef) => {
          return withEchoComponent(
            <EchoComponent
              source={child.component.source}
              componentProps={child.componentProps}
              childs={child.childrenComponents}
              styleOptions={{
                ...child.styleOptions,
                ...getDesignerStyleOptions(
                  dragMode,
                  designerMode,
                  activePropertiesComponentId,
                  activeAreaId,
                  child.id,
                ),
              }}
              systemProps={{
                id: child.id,
                guid: child.guid,
                nodeId: child.nodeId,
                depth: child.depth,
                dragMode,
                onMoveElement,
                designerMode,
                component: child.component,
                styleOptions: {
                  ...child.styleOptions,
                  ...getDesignerStyleOptions(
                    dragMode,
                    designerMode,
                    activePropertiesComponentId,
                    activeAreaId,
                    child.id,
                  ),
                },
                childs: child.childrenComponents,
                onClick: (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onComponentClick(child.id);
                },
                onComponentClick,
                activeAreaId,
                onDropAreaChange,
                activePropertiesComponentId,
                onMouseLeave: () => onDropAreaChange(id),
                nodeRef: childNodeRef,
                path,
              }}
            />,
            "designer",
          );
        }}
      </DesignerChild>
    );
  });
};

ListDesignerView.propTypes = {
  id: PropTypes.number,
  path: PropTypes.string,
  childs: PropTypes.array,
  onComponentClick: PropTypes.func,
  designerMode: PropTypes.bool,
  onMoveElement: PropTypes.func,
  activeAreaId: PropTypes.number,
  activePropertiesComponentId: PropTypes.number,
  dragMode: PropTypes.bool,
  onDropAreaChange: PropTypes.func,
};
