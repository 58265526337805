import React, { useContext, useRef, useState } from "react";
import Toolbox from "./components/toolbox";
import BlockComponent from "./components/block-component";
import { useParams } from "../echo-router/hooks";
import { Box, useDisclosure } from "@chakra-ui/react";
import { getProcessLinkComponent } from "./utils/process-link";
import { useDesignerInit } from "./hooks/use-designer-init";
import { createLink } from "./utils/create-link";
import { FiSave, FiSettings } from "react-icons/fi";
import ProcessBlockEdit from "./components/modals/process-block-edit";
import { createBlock } from "./utils/create-block";
import { toast } from "react-toastify";
import { saveDiagram } from "./utils/save-diagram";
import { serializeDiagram } from "./utils/serializer";
import BlockEdit from "./components/modals/block-edit";
import { getRandomIntInclusive } from "@echo/tools";
import EchoRouterContext from "../echo-router/echo-router-context";
import {
  PPZArea,
  PanPinchZoomDrag,
  PPZDraggable,
} from "@echo/pan-pinch-zoom-drag";
import { usePage } from "../echo-router/hooks/use-page";

const DiagramDesigner = () => {
  const { id } = useParams();
  const { getActivePage, closePage } = useContext(EchoRouterContext);
  const [callback, setCallback] = useState(null);
  const containerRef = useRef();

  const [diagramState, setDiagramState] = useState({
    processBlocks: [],
    processLinks: [],
  });

  const setProcessBlocks = (newProcessBlocks) =>
    setDiagramState({ ...diagramState, processBlocks: newProcessBlocks });

  const setProcessLinks = (newProcessLinks) =>
    setDiagramState({ ...diagramState, processLinks: newProcessLinks });

  const [block, setBlock] = useState({
    name: "",
    description: "",
    location: "",
    inParameters: {},
    returnType: {},
    executeType: 0,
    type: 0,
  });

  const [createLinkState, setCreateLinkState] = useState({
    sourceProcessBlockId: null,
  });

  const {
    isOpen: isSettingsOpen,
    onOpen: onSettingsOpen,
    onClose: onSettingsClose,
  } = useDisclosure();

  const [stateProps] = useDesignerInit(
    id,
    block,
    setBlock,
    setDiagramState,
    getActivePage,
    setCallback,
  );

  const handleUpdatePosition = (dragData, block) => {
    const newProcessBlocks = [...diagramState.processBlocks];
    const elemIndex = newProcessBlocks.findIndex((b) => b.id === block.id);
    newProcessBlocks[elemIndex].position = { x: dragData.x, y: dragData.y };
    setProcessBlocks(newProcessBlocks);
  };

  const handleOnLinkClick = (processBlock) => {
    createLink(processBlock, {
      createLinkState,
      setCreateLinkState,
      processLinks: diagramState.processLinks,
      setProcessLinks,
    });
  };

  const handleSuccessSave = (result) => {
    if (callback) {
      Promise.all([
        toast.success("Successfully saved"),
        callback(result),
        closePage(),
      ]);
    } else {
      Promise.all([toast.success("Successfully saved"), closePage()]);
    }
  };

  const handleProcessBlockUpdate = (newProcessBlock) => {
    const newProcessBlocks = [...diagramState.processBlocks];
    const changedElementId = newProcessBlocks.findIndex(
      (e) => e.id === newProcessBlock.id,
    );
    newProcessBlocks[changedElementId] = newProcessBlock;
    setProcessBlocks(newProcessBlocks);
  };

  const handleProcessLinkUpdate = (newProcessLink) => {
    const newProcessLinks = [...diagramState.processLinks];
    const changedElementId = newProcessLinks.findIndex(
      (link) =>
        link.sourceProcessBlockId === newProcessLink.sourceProcessBlockId &&
        link.destinationProcessBlockId ===
          newProcessLink.destinationProcessBlockId,
    );
    newProcessLinks[changedElementId] = newProcessLink;
    setProcessLinks(newProcessLinks);
  };

  const handleProcessLinkDelete = (toDelete) => {
    const processLinks = [...diagramState.processLinks];
    const newProcessLinks = processLinks.filter(
      (link) =>
        !Object.keys(toDelete).every((key) => link[key] === toDelete[key]),
    );

    setProcessLinks(newProcessLinks);
  };

  const handleOnBlockDelete = (processBlock) => {
    setDiagramState({
      processLinks: diagramState.processLinks.filter(
        (link) =>
          link.sourceProcessBlockId !== processBlock.id &&
          link.destinationProcessBlockId !== processBlock.id,
      ),
      processBlocks: diagramState.processBlocks.filter(
        (block) => block.id !== processBlock.id,
      ),
    });
  };

  const convertProcessToBlock = (process) => {
    const processBlock = {
      body: "",
      displayName: "",
      flowSettings: {
        inParameters: {},
        returnType: {},
      },
      isAsynchronus: false,
      id: -getRandomIntInclusive(1, 999999999),
      position: { x: 360, y: 260 },
      relatedBlock: process,
      staticParams: {},
    };
    const { state: tabState } = getActivePage();
    setBlock({
      inParameters: {},
      returnType: {},
      executeType: 0,
      type: 0,
      ...tabState?.afterCreateEvent?.customParams,
    });
    setDiagramState({ processBlocks: [processBlock], processLinks: [] });
  };

  const { state } = usePage();

  const saveBlock = async (block, type) => {
    let process;

    switch (type) {
      case "public":
        process = { ...block, private: false };
        break;
      case "private":
      default:
        process = block;
        break;
    }

    const diagram = serializeDiagram(
      process,
      diagramState.processBlocks,
      diagramState.processLinks,
      [],
    );

    await saveDiagram(diagram)
      .then((res) => {
        if (
          res.private === false &&
          state?.afterCreateEvent &&
          type !== "private"
        ) {
          convertProcessToBlock(res);
          toast.success("Successfully saved.");
        } else {
          handleSuccessSave(res);
        }
      })
      .catch(() => {
        toast.error("Error while saving the diagram.");
      });
  };

  return (
    <Box
      display="flex"
      height="90%"
      width="100%"
      flex={0.95}
      justifyContent="center"
      alignItems="center"
      position="relative"
    >
      <Box
        ref={containerRef}
        position="absolute"
        top={0}
        left={0}
        height="100%"
        width="100%"
        className="diagram-designer-modal container-modal"
      />
      {isSettingsOpen && (
        <BlockEdit
          isOpen={isSettingsOpen}
          onClose={onSettingsClose}
          block={block}
          onEdit={setBlock}
          modalProps={{ portalProps: { containerRef } }}
        />
      )}
      <PanPinchZoomDrag
        border="1px solid var(--chakra-colors-lightGrayCa)"
        borderRadius={5}
        flex={0.98}
        flexDirection="row"
        width="100%"
        height="98%"
        customNavigation={[
          {
            key: "block-settings-action",
            onClick: () => onSettingsOpen(),
            icon: <FiSettings />,
            type: "button",
          },
          {
            key: "block-save-action",
            onClick: () => saveBlock(block, "private"),
            icon: <FiSave />,
            type: "group",
            children: [
              {
                key: "public-block-save-action",
                isDisabled: !state.afterCreateEvent,
                onClick: () => saveBlock(block, "public"),
                icon: (
                  <span style={{ padding: "5px 10px" }}>save as public</span>
                ),
              },
            ],
          },
        ]}
        toolboxComponent={
          <Toolbox
            onDrop={(position, processBlock) =>
              setProcessBlocks([
                ...diagramState.processBlocks,
                {
                  id: -getRandomIntInclusive(1, 999999999),
                  position,
                  relatedBlock: processBlock.data,
                  ...createBlock(processBlock.data),
                },
              ])
            }
          />
        }
      >
        <PPZArea
          display="flex"
          width="10000px"
          height="10000px"
          backgroundColor="red.50"
        >
          {diagramState.processLinks.map((link) => (
            <React.Fragment
              key={`${link.sourceProcessBlockId}-${link.destinationProcessBlockId}`}
            >
              {getProcessLinkComponent(
                link,
                diagramState.processBlocks,
                handleProcessLinkUpdate,
                handleProcessLinkDelete,
                stateProps,
              )}
            </React.Fragment>
          ))}
          {diagramState.processBlocks.map((processBlock) => (
            <PPZDraggable
              key={`${processBlock.relatedBlock.name}-${processBlock.id}`}
              position={processBlock.position}
              onStop={(_, dragData) =>
                handleUpdatePosition(dragData, processBlock)
              }
            >
              <BlockComponent
                highlightLink={
                  processBlock.id === createLinkState.sourceProcessBlockId
                }
                editModalComponent={(stearingModalProps) => (
                  <ProcessBlockEdit
                    {...stearingModalProps}
                    modalProps={{ portalProps: { containerRef } }}
                    processBlock={processBlock}
                    onEdit={handleProcessBlockUpdate}
                    onDelete={handleOnBlockDelete}
                  />
                )}
                onLinkClick={() => handleOnLinkClick(processBlock)}
                data={{
                  ...processBlock.relatedBlock,
                  displayName: processBlock.displayName,
                }}
              />
            </PPZDraggable>
          ))}
        </PPZArea>
      </PanPinchZoomDrag>
    </Box>
  );
};

export default DiagramDesigner;
