import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { ColumnsList } from "./columns-list";

export const DatagridColumnsEditor = (props) => {
  const { propValue, designerPortalRef, onChange } = props;
  const [columns, setColumns] = useState(propValue);
  const { onOpen, onClose, isOpen } = useDisclosure();

  const addColumn = () => setColumns((prev) => [...prev, {}]);

  const updateColumn = (col, idx) => {
    setColumns((prev) => prev.map((el, id) => (id === idx ? col : el)));
  };

  const deleteColumn = (col, idx) => {
    setColumns((prev) => prev.filter((el, id) => id !== idx));
  };

  const restoreColumns = () => {
    setColumns(propValue);
  };

  const saveColumns = () => {
    onChange(columns);
    onClose();
    toast.success("Saved successfully.");
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <Button onClick={onOpen} color="none" variant="ghost" size="md">
        {columns.length}
        ...
      </Button>
      <Modal
        portalProps={{ containerRef: designerPortalRef }}
        isOpen={isOpen}
        onClose={onClose}
        size="5xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton onClick={restoreColumns} />
          <ModalHeader></ModalHeader>
          <ModalBody>
            <ColumnsList
              columns={columns}
              onColumnChange={updateColumn}
              deleteColumn={deleteColumn}
            />
            <Button onClick={addColumn} variant="ghost">
              Add new column
            </Button>
          </ModalBody>
          <ModalFooter gap="20px">
            <Button
              onClick={() => {
                restoreColumns();
                onClose();
              }}
              variant="ghost"
            >
              Cancel
            </Button>
            <Button onClick={saveColumns} colorScheme="blue">
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

DatagridColumnsEditor.propTypes = {
  onChange: PropTypes.func,
  propName: PropTypes.string,
  propType: PropTypes.string,
  propValue: PropTypes.string,
  designerPortalRef: PropTypes.any,
};
