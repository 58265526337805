import PropTypes from "prop-types";
import { withBaseComponent } from "../../../with-base-component";
import { DatagridV2 } from "@echo/datagrid";
import { Box, useDisclosure } from "@chakra-ui/react";
import { useDatagrid } from "./hooks/use-datagrid-v2";
import { useSelection } from "./hooks/use-selection";
import ColumnVisibilityManager from "../datagrid-component/header/column-visibility-manager/column-visibility-manager";
import { ColumnSettings } from "../datagrid-component/column-settings/column-settings";
import { getDatagridColumnsPropType } from "./prop-type";
import { useComponentRegister } from "../../../../../../components-registry/hooks/use-component-register";
import { useCallback, useContext, useEffect, useMemo } from "react";
import UserContext from "../../../../../../user-context";
import { mobileCheck } from "@echo/ui";
import { catchEventError } from "../datagrid-component/utils/catch-event-error";
import { echoPortal } from "../../../../../echo-portal/echo-portal";
import QuestionAlert from "../question-alert-component/question-alert.component";

const DatagridV2Component = (props) => {
  const {
    rootProps,
    nodeRef,
    onRowClick,
    onRowDoubleClick,
    onSelectChange,
    selectionMode,
    disableNavOnLoading,
    context,
    pageSizeSelectOptions,
    headerFontSize,
    rowFontSize,
    cellWhiteSpace,
    cellLinesNumber,
    isCombo,
    filterColumnName,
    filterValue,
  } = props;

  const { userId, contextId, companyId, permissions, isSuperuser } =
    useContext(UserContext);

  const roleId = (permissions.length && permissions[0]?.role?.id) || null;
  const { state, api } = useDatagrid(props);

  const {
    onColumnChange,
    onFilterChange,
    onItemsPerPageChange,
    onHeaderVisibilityChange,
    onFiltersVisibilityChange,
    onNavPositionChange,
    onPageChange,
    onSortChange,
    refreshData,
    getData,
    onRowEdit,
    onRowAdd,
  } = api;

  const { selectedItems, selectSingle, selectMany, selectAll, unselectAll } =
    useSelection(state.data, onSelectChange, selectionMode);

  const {
    isOpen: isColumnsManagerOpen,
    onClose: onColumnsManagerClose,
    onOpen: onColumnsManagerOpen,
  } = useDisclosure();

  const {
    isOpen: isColumnsSettingsOpen,
    onClose: onColumnsSettingsClose,
    onOpen: onColumnsSettingsOpen,
  } = useDisclosure();

  const clearFilters = useCallback(() => onFilterChange([]), [onFilterChange]);

  const getId = (obj) =>
    obj[Object.keys(obj).filter((k) => k.toLowerCase() === "id")];

  const {
    settings: { disableHeader, disableFilters, navPosition },
    itemsCount,
  } = state;

  const selectAllAction = useCallback(async () => {
    if (itemsCount > 3000) {
      const promise = () =>
        new Promise((resolve) =>
          echoPortal.render((container) => (
            <QuestionAlert
              open={true}
              header={"Zaznaczanie wszystkich wierszy."}
              question={`Próbujesz zaznaczyć ${itemsCount} rekordów. Czy na pewno chcesz kontynuować?`}
              alertMode={"Tak/Nie"}
              onAction={(type) => {
                container.unmount();
                resolve(type.toLowerCase());
              }}
            />
          )),
        );

      const res = await promise();
      if (res === "nie") return;
    }

    const { data } = await getData({ page: 0, itemsPerPage: itemsCount });
    selectAll(data.map((i) => getId(i)?.toString()));
  }, [itemsCount, getData, selectAll]);

  const selectAllRowsAction = useMemo(
    () => ({
      label:
        selectedItems.length === itemsCount
          ? `Odznacz wszystkie (${itemsCount} wierszy)`
          : `Zaznacz wszystkie (${itemsCount} wierszy)`,
      action:
        selectedItems.length === itemsCount ? unselectAll : selectAllAction,
    }),
    [itemsCount, selectedItems, unselectAll, selectAllAction],
  );

  const selectionContextActions = [selectAllRowsAction];

  const headerVisilibityAction = useMemo(
    () => ({
      label: `${disableHeader ? "Show" : "Hide"} header`,
      action: () => onHeaderVisibilityChange(!disableHeader),
    }),
    [disableHeader, onHeaderVisibilityChange],
  );

  const filtersVisibilityAction = useMemo(
    () => ({
      label: `${disableFilters ? "Show" : "Hide"} filters`,
      action: () => onFiltersVisibilityChange(!disableFilters),
    }),
    [onFiltersVisibilityChange, disableFilters],
  );

  const navPositionAction = useMemo(
    () => ({
      label: `Change footer position: ${navPosition === "bottom" ? "top" : "bottom"}`,
      action: () =>
        onNavPositionChange(navPosition === "bottom" ? "top" : "bottom"),
    }),
    [navPosition, onNavPositionChange],
  );

  const columnsManagerAction = useMemo(
    () => ({
      label: "Manage columns",
      action: onColumnsManagerOpen,
      color: "red",
      position: ["header"],
    }),
    [onColumnsManagerOpen],
  );

  const columnSettingsAction = useMemo(
    () => ({
      label: "Advanced columns settings",
      action: onColumnsSettingsOpen,
      position: ["header"],
    }),
    [onColumnsSettingsOpen],
  );

  const clearFiltersAction = useMemo(
    () => ({
      label: "Clear filters",
      action: clearFilters,
      position: ["header"],
    }),

    [clearFilters],
  );

  const refreshDataAction = useMemo(
    () => ({
      label: "Refresh data",
      action: refreshData,
      position: ["header"],
    }),
    [refreshData],
  );

  const contextActions = [
    columnsManagerAction,
    isSuperuser && columnSettingsAction,
    clearFiltersAction,
    refreshDataAction,
    headerVisilibityAction,
    filtersVisibilityAction,
    navPositionAction,
  ];

  const component = useMemo(
    () => ({
      name,
      getColumns: () => state.settings.columns,
      getData: () => state.data,
      getSelectedRows: () => selectedItems,
      refreshData,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, state.settings.columns, state.data, selectedItems],
  );

  useComponentRegister(context, component);

  const isMobile = mobileCheck();
  const handleRowClick = (e) => {
    if (!onRowClick) {
      if (isMobile) onRowDoubleClick(e).catch(catchEventError);
    } else {
      onRowClick(e);
    }
  };

  const handleColumnChange = (columns) =>
    onColumnChange(columns, userId, null, "user", companyId);

  const actions = {
    onRowClick: handleRowClick,
    onRowDoubleClick,
    onFilterChange: (val) => {
      onFilterChange(val);
      unselectAll();
    },
    onItemsPerPageChange: (val) => {
      onItemsPerPageChange(val);
      unselectAll();
    },
    onPageChange,
    onSortChange: (val) => {
      onSortChange(val);
      unselectAll();
    },
    onColumnWidthChange: handleColumnChange,
    onColumnOrderChange: handleColumnChange,
    onColumnVisibilityChange: handleColumnChange,
    onColumnsSettingsChange: onColumnChange,
    onRowSelect: selectSingle,
    onSelectAll: selectMany,
    onRowEdit,
    onRowAdd,
  };

  useEffect(() => {
    if (filterColumnName) {
      onFilterChange([
        {
          name: filterColumnName,
          values: [filterValue === "" ? null : filterValue],
          type: "contains",
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterColumnName, filterValue]);

  const resetAction = async () =>
    await onColumnChange([], contextId || userId, roleId, null);

  return (
    <Box
      display="flex"
      ref={nodeRef}
      className={`$data-grid`}
      {...rootProps}
      height="100%"
      width="100%"
      overflowX="hidden"
      flexFlow="column"
      justifyContent="space-between"
      padding="5px"
    >
      <ColumnVisibilityManager
        isOpen={isColumnsManagerOpen}
        onClose={onColumnsManagerClose}
        columns={state.settings.columns}
        onChange={handleColumnChange}
      />
      <ColumnSettings
        isOpen={isColumnsSettingsOpen}
        onClose={onColumnsSettingsClose}
        defaultColumns={state.settings.columns}
        settingsId={state.settings.id}
        settingsKey={state.settings.key}
        onChange={onColumnChange}
        sourceObject={state.source}
        isCombo={isCombo}
        actions={[
          {
            name: "Reset",
            buttonProps: { colorScheme: "red" },
            action: resetAction,
          },
        ]}
      />
      <DatagridV2
        data={state.data}
        columns={state.columns}
        actions={actions}
        page={state.page}
        filters={state.filters}
        sort={state.settings.sorting}
        itemsCount={state.itemsCount}
        loading={state.loading}
        selectionMode={props.selectionMode}
        enableAdding={props.enableAdding}
        enableEdit={props.enableEdit}
        settings={state.settings}
        contextActions={contextActions}
        selectedItems={selectedItems}
        disableFilters={state.settings.disableFilters}
        disableHeader={state.settings.disableHeader}
        disablePagination={disableNavOnLoading && state.loading}
        pageSizeSelectOptions={pageSizeSelectOptions}
        headerFontSize={headerFontSize}
        rowFontSize={rowFontSize}
        cellWhiteSpace={cellWhiteSpace}
        cellLinesNumber={cellLinesNumber}
        navPosition={state.settings.navPosition}
        selectionContextActions={selectionContextActions}
      />
    </Box>
  );
};

DatagridV2Component.propTypes = {
  name: PropTypes.string,
  dataSource: PropTypes.any,
  columns: getDatagridColumnsPropType(PropTypes.object),
  context: PropTypes.any,
  rootProps: PropTypes.any,
  nodeRef: PropTypes.any,
  onRowClick: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  onRowAdd: PropTypes.func,
  onRowEdit: PropTypes.func,
  onSelectChange: PropTypes.func,
  disableHeader: PropTypes.bool,
  disableFilters: PropTypes.bool,
  disableFooter: PropTypes.bool,
  disableNavOnLoading: PropTypes.bool,
  selectionMode: PropTypes.oneOf(["single", "multiple", "none"]),
  enableAdding: PropTypes.bool,
  enableEdit: PropTypes.bool,
  columnKey: PropTypes.string,
  designerMode: PropTypes.bool,

  rowColor: PropTypes.func,
  pageSizeSelectOptions: PropTypes.array,

  headerFontSize: PropTypes.string,
  rowFontSize: PropTypes.string,
  cellWhiteSpace: PropTypes.string,
  cellLinesNumber: PropTypes.string,

  isCombo: PropTypes.bool,
  filterColumnName: PropTypes.string,
  filterValue: PropTypes.string,
};

export default withBaseComponent(DatagridV2Component);
