import PropTypes from "prop-types";
import { Box } from "@chakra-ui/react";
import { useState } from "react";
import DatagridFooterDataTypeSelector from "./data-type-selector";
import { getColumns, selectColumnWidth } from "../utils/columns/column-utility";

const footerBorder = "1px solid var(--chakra-colors-gray-200)";

const checkColumnForSummary = (data, name) => {
  if (!data || !Array.isArray(data) || data.length === 0) {
    return false;
  }
  return data.every(
    (elem) =>
      typeof elem === "object" &&
      (elem[name] === null ||
        !isNaN(elem[name]) ||
        typeof elem[name] === "undefined"),
  );
};

const getSummary = (data, name, summaryType) => {
  const getSum = () =>
    data.reduce(
      (partialSum, elem) => (partialSum += parseFloat(elem[name] || 0)),
      0,
    );

  const defaultValue = "---";
  switch (summaryType) {
    case "none":
      return "";
    case "sum":
      return getSum();
    case "average": {
      if (!data.length) {
        return defaultValue;
      }
      return getSum() / data.length;
    }
    case "filled-cells-average": {
      const filledCellsCount = data.filter(
        (elem) => elem[name] !== null && typeof elem[name] !== "undefined",
      ).length;
      if (!filledCellsCount) {
        return defaultValue;
      }
      return getSum() / filledCellsCount;
    }
    default:
      return "---";
  }
};

const DatagridDataFooter = ({
  data,
  columnDefinitions,
  selectionMode,
  tableDimensions,
}) => {
  const [summaryTypeSelections, setSummaryTypeSelections] = useState({});

  const handleSummaryTypeChange = (colName, newValue, selected) => {
    if (colName && newValue) {
      setSummaryTypeSelections({
        ...summaryTypeSelections,
        [colName]: selected ? newValue : null,
      });
    }
  };

  const visibleColumns = columnDefinitions.filter(
    (col) => col.isVisible !== false,
  );

  const visibleColumnWithFixedSizes = getColumns(
    visibleColumns,
    selectionMode,
    selectColumnWidth,
    tableDimensions,
  );

  return (
    <Box
      width="fit-content"
      boxSizing="border-box"
      height="35px"
      display="flex"
      flexFlow="row"
      borderTop={footerBorder}
      borderRight={footerBorder}
      marginRight="-1px"
    >
      {(selectionMode === "single" || selectionMode === "multiple") && (
        <Box width={selectColumnWidth} borderRight={footerBorder} />
      )}
      {visibleColumnWithFixedSizes.map((columnDef, index) => (
        <Box
          key={`footer-summary-${columnDef.name}`}
          width={columnDef.pxWidth}
          display="flex"
          flexFlow="row"
          alignContent="center"
          alignItems="center"
          borderRight={
            index < visibleColumnWithFixedSizes.length - 1
              ? footerBorder
              : undefined
          }
        >
          {!columnDef.disableSummary &&
            checkColumnForSummary(data, columnDef.name) && (
              <>
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  alignContent="center"
                  alignItems="center"
                >
                  {getSummary(
                    data,
                    columnDef.name,
                    typeof summaryTypeSelections[columnDef.name] === "undefined"
                      ? columnDef.defaultSummary || "none"
                      : summaryTypeSelections[columnDef.name],
                  )}
                </Box>
                <DatagridFooterDataTypeSelector
                  value={
                    typeof summaryTypeSelections[columnDef.name] === "undefined"
                      ? columnDef.defaultSummary || "none"
                      : summaryTypeSelections[columnDef.name]
                  }
                  onChange={(newValue, selected) =>
                    handleSummaryTypeChange(columnDef.name, newValue, selected)
                  }
                />
              </>
            )}
        </Box>
      ))}
    </Box>
  );
};

DatagridDataFooter.propTypes = {
  data: PropTypes.array,
  columnDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
  ),
  selectionMode: PropTypes.oneOf(["single", "multiple", "none"]),
  tableDimensions: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
  }),
};

export default DatagridDataFooter;
