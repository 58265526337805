import { Box, FormControl, FormLabel, IconButton } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useCallback, useRef, useState } from "react";
import { withBaseComponent } from "../../../with-base-component";
import { FiXCircle } from "react-icons/fi";
import ConfirmDialog from "./confirm-dialog/confirm-dialog";
import { useDebouncedValueState } from "../../../../../hooks/shared/use-debounced-value-state";

const SignatureComponent = ({
  rootProps,
  value,
  onChange,
  name,
  label = "Signature",
  variant = "floating",
}) => {
  const [signatureValue, setSignatureValue] = useDebouncedValueState(
    value,
    onChange,
    name,
  );

  const { nodeRef, style, ...restRootProps } = rootProps;
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const width = style?.width;
  const height = style?.height;

  const eventRef = useRef({ mouseX: 0, mouseY: 0, isDrawing: false });
  const canvasRef = useRef();

  const getMouseCoordinates = (event) => {
    const boundingRect = canvasRef.current.getBoundingClientRect();

    return {
      mouseX: event.clientX - boundingRect.left,
      mouseY: event.clientY - boundingRect.top,
    };
  };

  const handleClear = useCallback(() => {
    setShowConfirmDialog(true);
  }, []);

  const handleConfirmClear = useCallback(() => {
    const context = canvasRef?.current?.getContext("2d");
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    Promise.all([setShowConfirmDialog(false), setSignatureValue(null)]);
  }, [setSignatureValue]);

  const handleCancelClear = useCallback(() => {
    setShowConfirmDialog(false);
  }, []);

  const setBrush = useCallback((context) => {
    context.strokeStyle = "black"; // initial brush color
    context.lineWidth = 1;
  }, []);

  const handlePointerDown = useCallback(
    (event) => {
      eventRef.isDrawing = true;

      const context = canvasRef?.current?.getContext("2d");

      setBrush(context);

      const { mouseX, mouseY } = getMouseCoordinates(event);

      context.beginPath();
      context.moveTo(mouseX, mouseY);
    },
    [setBrush],
  );

  const handlePointerMove = useCallback((event) => {
    const context = canvasRef?.current?.getContext("2d");

    if (eventRef.isDrawing) {
      const { mouseX, mouseY } = getMouseCoordinates(event);

      context.lineTo(mouseX, mouseY);
      context.stroke();
    }
  }, []);

  const handlePointerUp = useCallback(() => {
    eventRef.isDrawing = false;

    const signature = canvasRef.current.toDataURL();

    setSignatureValue(signature);
  }, [setSignatureValue]);

  return (
    <FormControl
      border="1px solid black"
      style={{ ...style, height, width }}
      borderColor="var(--chakra-colors-chakra-border-color)"
      borderRadius="var(--chakra-radii-md)"
      variant={variant}
      ref={nodeRef}
      {...restRootProps}
    >
      <Box
        className="signature"
        position="relative"
        height={height}
        width={width}
      >
        <canvas
          style={{ cursor: "crosshair", touchAction: "none" }}
          ref={canvasRef}
          height={height}
          width={width}
          onPointerDown={handlePointerDown}
          onPointerMove={handlePointerMove}
          onPointerUp={handlePointerUp}
        />
        <IconButton
          size="16px"
          variant="ghost"
          position="absolute"
          top="4px"
          left="4px"
          onClick={handleClear}
        >
          <FiXCircle size="16px" />
        </IconButton>
        {showConfirmDialog && (
          <ConfirmDialog
            top={0}
            left={0}
            position="absolute"
            height={height}
            width={width}
            onCancel={handleCancelClear}
            onConfirm={handleConfirmClear}
          />
        )}
      </Box>
      {label && <FormLabel>{label}</FormLabel>}
    </FormControl>
  );
};

SignatureComponent.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(["filled", "outlined", "floating"]),
  rootProps: PropTypes.any,
};

export default withBaseComponent(SignatureComponent);
