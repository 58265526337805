import PropTypes from "prop-types";
import ToolboxItem from "../toolbox-item/toolbox-item";

export const ToolboxItemsList = ({
  items,
  dragMode,
  onItemDragStart,
  onItemDragStop,
  onItemDrag,
  onItemDrop,
}) => {
  return items.map((i, idx) => {
    const { defaultProps, ...item } = i;
    return (
      <ToolboxItem
        item={item}
        defaultProps={defaultProps}
        dragMode={dragMode}
        onStart={onItemDragStart}
        onStop={onItemDragStop}
        onDrag={onItemDrag}
        onDropItem={onItemDrop}
        key={`toolbox-item-${idx}`}
      />
    );
  });
};

ToolboxItemsList.propTypes = {
  items: PropTypes.array,
  dragMode: PropTypes.bool,
  onItemDragStart: PropTypes.func,
  onItemDragStop: PropTypes.func,
  onItemDrag: PropTypes.func,
  onItemDrop: PropTypes.func,
  type: PropTypes.oneOf(["standardComponents", "formComponents"]),
};
