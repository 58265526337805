import { useCallback, useRef } from "react";

/**
 *
 * @param {object} options Event options
 * @returns {object} Html element event handlers
 */
export const useDataGridRowEvents = ({ clickOptions, editOptions }) => {
  const { handlers: clickHandlers } = clickOptions;
  const { handlers: editHandlers, editable } = editOptions;
  const { onEditModeChange } = editHandlers;
  const { onClick, onDoubleClick } = clickHandlers;
  const timer = useRef();

  const handleOnClick = useCallback(
    (e, ...args) => {
      if (e.detail === 1 && (onClick || (editable && onEditModeChange))) {
        if (editable && onEditModeChange) {
          timer.current = setTimeout(onEditModeChange, 200, ...[e, ...args]);
        } else if (onClick) {
          timer.current = setTimeout(onClick, 200, ...[e, ...args]);
        }
      } else if (e.detail === 2) {
        clearTimeout(timer.current);
      }
    },
    [editable, onClick, onEditModeChange],
  );

  const handleOnDoubleClick = useCallback(
    (...args) => {
      if (onDoubleClick) {
        onDoubleClick(...args);
      }
    },
    [onDoubleClick],
  );

  return {
    onClick: handleOnClick,
    onDoubleClick: handleOnDoubleClick,
  };
};
