/* eslint-disable react/prop-types */
import { testStyleProps } from "../../../../../../utils/css/base-echo-style-props";
import PropTypes from "prop-types";
import { shimFunctions } from "../../../../../../utils/shims/shims";
import { getComponent } from "../../../../../../utils/echo-component/echo-component-utils";
import PropertiesShim from "../../../../../shared/shim-components/properties-shim/properties-shim";
import PropertiesTable from "../../../../../shared/properties/properties-table/properties-table";
import { IconButton } from "@chakra-ui/react";
import { ChevronUpIcon, DeleteIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";

const camelToKebab = (camelCaseString) =>
  camelCaseString?.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();

const StyleProperties = ({
  component,
  onAction,
  id = null,
  onModelChange = shimFunctions.shimFunction1,
  model = {},
}) => {
  const [cssProperties, setCssProperties] = useState();

  const getCssProperties = async () => {
    const gistUrl =
      "https://api.github.com/gists/9e890e2fef9d7f5b819e0550c26566b4";

    try {
      const result = await fetch(gistUrl);
      const data = await result.json();
      const properties = JSON.parse(data.files["css-properties.json"].content);
      properties.gap = { values: ["[length]"] };
      properties.width = {
        ...properties.width,
        values: [...properties.width.values, "fit-content"],
      };
      properties.height = {
        ...properties.height,
        values: [...properties.height.values, "fit-content"],
      };
      setCssProperties(properties);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getCssProperties();
  }, []);

  const getOptionType = (type) => {
    switch (type) {
      case "combo":
        return "combo";
      default:
      case "number":
      case "text":
        return "input";
    }
  };

  const getComboOptions = (option) => {
    const splitOptions = option.valuePattern.split("|");
    const splitNames = option.displayName.split("|");
    if (splitOptions.length !== splitNames.length) {
      throw new Error("Invalid valuePattern or displayName.");
    }
    return splitOptions.map((s, index) => ({
      value: s,
      label: splitNames[index],
    }));
  };

  const getSubOptions = (availableValues, propName) => {
    if (availableValues.length > 1) {
      if (model && model["opt-" + propName]) {
        const value = availableValues.find(
          (av) => av.displayName === model["opt-" + propName],
        );
        return value
          ? [
              {
                propName,
                propType: getOptionType(value.valueType),
                options:
                  value.valueType === "combo"
                    ? getComboOptions(value)
                    : undefined,
              },
            ]
          : undefined;
      }
    }
    return undefined;
  };

  const getTooltip = (prop) => {
    const kebabProp = camelToKebab(prop);
    const obj = cssProperties?.[kebabProp];
    let tooltip = obj?.values.join(", ");

    if (obj?.syntax?.includes("{")) {
      tooltip = tooltip.replace(
        "[length], [percent]",
        "[px px px px], [% % % %]",
      );
    }

    return tooltip;
  };

  const schema = testStyleProps.map((s) => ({
    propName:
      s?.availableValues?.length > 1
        ? "opt-" + s.styleProperty
        : s.styleProperty,
    propType:
      s?.availableValues?.length > 1
        ? "combo"
        : getOptionType(s.availableValues[0].valueType),
    options:
      s?.availableValues?.length > 1
        ? s.availableValues.map((av) => ({
            label: av.displayName,
            value: av.styleProperty,
          }))
        : s.availableValues[0].valueType === "combo"
          ? getComboOptions(s.availableValues[0])
          : undefined,
    subOptions: getSubOptions(s.availableValues, s.styleProperty),
    tooltip: getTooltip(s.styleProperty),
  }));

  const getPropTypes = (component) => {
    const source = component?.component?.source;

    if (!source) {
      return null;
    }

    if (source === "!?echo-defined") {
      return {
        type: "!?echo-defined",
        properties: component.component.properties,
      };
    } else {
      return {
        type: "built-in",
        properties: getComponent(source).propTypes,
      };
    }
  };
  const propTypes = getPropTypes(component);

  return id && model !== null && component ? (
    <div
      style={{
        height: "100%",
        overflowY: "auto",
        padding: "16px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          {"\t"}
          {component?.component?.displayName},{"\t"}name:{" "}
          {component?.componentProps?.name
            ? component?.componentProps?.name
            : "<unknown_name>"}
          , <br />
          {"\t"}id: {component.id > 0 ? component.id : "<new>"}
          {"\t"}depth: {component.depth},
        </div>
        <div>
          <IconButton
            color="secondary"
            variant="contained"
            onClick={() => onAction(id, { type: "GO_TO_PARENT" })}
          >
            <ChevronUpIcon />
          </IconButton>
          <IconButton
            onClick={() => onAction(id, { type: "DELETE" })}
            color="secondary"
            variant="contained"
          >
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
      {propTypes ? (
        <PropertiesTable
          options={schema}
          model={model}
          onModelChange={onModelChange}
          // customTypes={customTypes}
        />
      ) : null}
    </div>
  ) : (
    <PropertiesShim />
  );
};

StyleProperties.propTypes = {
  id: PropTypes.any,
  model: PropTypes.object,
  onModelChange: PropTypes.funcOf(PropTypes.object),
};

export default StyleProperties;
