import { getParent } from "../../components/echo-components/utils/definition-tree-utils/get-parent";
import { showErrorToast } from "../../components/shared/echo-error-toast";

export const conditionBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: (staticParams, params, systemParams) =>
      new Promise((resolve, reject) => {
        try {
          const { source } = systemParams;
          let { callerId, callerGuid, callerNodeId } = systemParams;
          let context = componentContext;

          if (source?.context && source?.callerId) {
            callerId = source.callerId;
            context = source.context;
            callerGuid = source.callerGuid;
            callerNodeId = source.callerNodeId;
          }

          const parent = getParent(
            context.component,
            callerId,
            callerGuid,
            callerNodeId
          );

          const componentChild =
            parent?.childrenComponents &&
            Array.isArray(parent?.childrenComponents)
              ? parent.childrenComponents.find((cmp) => cmp.id === callerId)
              : null;

          const body = staticParams?.__processBlock?.body || block.body;
          const funcDefinition = JSON.parse(body);
          funcDefinition[2] = `return Boolean(${funcDefinition[2]});`;
          const func = JSON.parse(
            JSON.stringify(funcDefinition),
            Function.deserialize
          );

          resolve(
            func(componentContext, block.id, params, {
              ...staticParams,
              __caller: componentChild,
            })
              ? { ...staticParams, __resultCondition: "true" }
              : { ...staticParams, __resultCondition: "false" }
          );
        } catch (err) {
          showErrorToast(err);
          reject(err);
        }
      }),
  };
};
