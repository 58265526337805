import { createLogger } from "../logger/logger";
import { getBlock, getBlockByGroup } from "../services/diagram-service";
import { getBlockExecutor } from "./get-block";

export const executeBlock = (
  ctx,
  blockId,
  staticParams,
  params,
  systemParams,
) =>
  new Promise((resolve, reject) => {
    const logger = createLogger(ctx.logSeverity);
    const date = Date.now();
    getBlock(blockId)
      .then((block) => {
        var blockExecutor = getBlockExecutor(block, ctx);
        Promise.resolve(
          blockExecutor.execute(
            {
              ...staticParams,
              ...(block.staticParams ? JSON.parse(block.staticParams) : {}),
            },
            params.filter((p) => !p?.target),
            systemParams,
          ),
        )
          .then((res) => {
            logger.logInfo(
              "process-executor.js",
              `Executed frontend block. Id: ${blockId}`,
              { input: staticParams, id: blockId },
              blockId,
              date.toString(),
              Date.now().toString(),
            );
            resolve(res);
          })
          .catch(reject);
      })
      .catch(reject);
  });

export const executeBlockByGroup = (
  ctx,
  { group, tag },
  staticParams,
  params,
  systemParams,
) => {
  return new Promise((resolve, reject) => {
    getBlockByGroup(group, tag)
      .then((block) => {
        var blockExecutor = getBlockExecutor(block, ctx);
        Promise.resolve(
          blockExecutor.execute(
            staticParams,
            params.filter((p) => !p?.target),
            systemParams,
          ),
        )
          .then((res) => {
            resolve(res);
          })
          .catch(reject);
      })
      .catch(reject);
  });
};

export const getBlockMetadata = (blockId, staticParams, params) =>
  new Promise((resolve, reject) => {
    getBlock(blockId)
      .then((block) => {
        var blockExecutor = getBlockExecutor(block);
        Promise.resolve(
          blockExecutor.getMetadata(
            staticParams,
            params.filter((p) => !p?.target),
          ),
        )
          .then((res) => resolve(res))
          .catch(reject);
      })
      .catch(reject);
  });

export const getBlockMetadataByGroup = ({ group, tag }, staticParams, params) =>
  new Promise((resolve, reject) => {
    getBlockByGroup(group, tag)
      .then((block) => {
        var blockExecutor = getBlockExecutor(block);
        Promise.resolve(
          blockExecutor.getMetadata(
            staticParams,
            params.filter((p) => !p?.target),
          ),
        )
          .then((res) => resolve(res))
          .catch(reject);
      })
      .catch(reject);
  });
