export const getMapperProps = (schema, baseLocation, namePath) => {
  const result = [];

  const keys = Object.keys(schema || {});
  for (let key of keys) {
    const location = `${baseLocation || "$"}.${key}`;
    const name = `${(namePath !== "__model" && namePath) || ""}${namePath !== "__model" && namePath ? "." : ""}${key}`;
    const prop = schema[key];

    if (prop?.type === "object" && !Array.isArray(prop)) {
      result.push(...getMapperProps(prop.properties, location, name));
    } else {
      result.push({ name: name, locator: location, ...prop });
    }
  }

  return result;
};
