import { Box, Button, IconButton, Input } from '@chakra-ui/react';
import React, { useState } from 'react';
import { FiSave, FiX } from 'react-icons/fi';
import PropTypes from 'prop-types';

const AddSchemaPropComponent = ({ onAdd }) => {
  const [name, setName] = useState('');
  const [addMode, setAddMode] = useState(false);
  const [error, setError] = useState(false);

  const handleOnAdd = () => {
    if (onAdd) {
      if (name) {
        Promise.all([
          onAdd(name),
          setError(false),
          setName(''),
          setAddMode(false),
        ]);
      } else {
        setError(true);
      }
    }
  };

  const handleCancel = () => {
    Promise.all([setError(false), setName(''), setAddMode(false)]);
  };

  const handleKeyPress = (event) => {
    if (event.charCode === 13) {
      handleOnAdd();
    }
  };

  return (
    <Box display='flex' flexDirection='row' alignItems='center'>
      {addMode ? (
        <>
          <Input
            value={name}
            marginLeft='32px'
            textAlign='left'
            isInvalid={error && !name}
            onChange={(e) => setName(e.target.value)}
            size='sm'
            placeholder='Type name'
            onKeyPress={handleKeyPress}
          />
          <IconButton onClick={handleOnAdd} variant='ghost' size='sm'>
            <FiSave />
          </IconButton>
          <IconButton onClick={handleCancel} variant='ghost' size='sm'>
            <FiX />
          </IconButton>
        </>
      ) : (
        <Button
          marginLeft='32px'
          size='sm'
          fontStyle='normal'
          fontWeight='light'
          fontSize='12px'
          textAlign='left'
          height='16px'
          onClick={() => setAddMode(!addMode)}
        >
          Add property
        </Button>
      )}
    </Box>
  );
};

AddSchemaPropComponent.propTypes = {
  onAdd: PropTypes.func.isRequired,
};

export default AddSchemaPropComponent;
