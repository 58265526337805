import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useState } from "react";
import { getNumberFormat } from "@echo/datagrid/src/utils/get-number-format";

const getValue = (defaultValue, inputValue) => {
  if (typeof inputValue === "undefined" || inputValue === null) {
    return defaultValue;
  }

  return inputValue;
};

export const NumberEditComponent = (props) => {
  const { value, onChange, format } = props;
  const [inputValue, setInputValue] = useState(getValue(0.0, value));

  const handleBlurChange = (e) => {
    const value = e.target.value;
    const numberValue = parseFloat(value.replace(",", "."));
    onChange(numberValue);
  };

  const formatValueToLocal = (value, precision, separator) =>
    parseFloat(value)
      .toFixed(precision || 2)
      .toString()
      .replace(".", separator)
      .replace(",", separator);

  const formatFunc = (value) => {
    if (value === "") return "";

    const { precision, separator } = getNumberFormat(format);
    if (!value) return formatValueToLocal(0, precision, separator);

    if (value && typeof value === "string") {
      return value.replace(".", separator).replace(",", separator);
    }

    return formatValueToLocal(value, precision, separator);
  };

  const parseFunc = (value) => value.replace(",", ".");

  const getIsValidChar = (val) => /^[Ee0-9+\-,.]$/.test(val);

  return (
    <NumberInput
      value={inputValue}
      onBlur={handleBlurChange}
      onChange={setInputValue}
      format={formatFunc}
      parse={parseFunc}
      isValidCharacter={getIsValidChar}
      precision={2}
    >
      <NumberInputField />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  );
};

NumberEditComponent.propTypes = {
  value: PropTypes.string,
  format: PropTypes.string,
  onChange: PropTypes.func,
};
