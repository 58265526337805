// @flow
import React, { useEffect, useMemo } from "react";
import { DatagridContextProvider } from "./context/datagrid-context";
import { DatagridSimple } from "./components/datagrid-simple";
import { DragContextProvider } from "./context/drag-context";
import { ScrollContextProvider } from "./context/scroll-context";

type FilterType =
  | "equal"
  | "not equal"
  | "lower than"
  | "higher than"
  | "lower or equal"
  | "higher or equal";

export type Record = { [key: string]: string | number };

export type Process = {
  id: number,
  type: "PROCESS",
};

export type ColumnType =
  | "string"
  | "number"
  | "numeric"
  | "date"
  | "datetime"
  | "boolean"
  | "checkbox"
  | "combo";

export type Column = {
  name: string,
  width: string,
  isVisible: boolean,
  type: "string" | "date",
  editType: ColumnType | Function | null | void,
  format: string,
  order: number,
  editDataSource: Function,
};

export type SortObj = { name: string, value: "asc" | "desc" };
export type Filter = { name: string, type: FilterType, value: string };
export type ContextMenuAction = {
  label: string,
  action: () => void,
  color: string,
};
export type Actions = {
  onPageChange: (page: number) => void,
  onFilterChange: (filters: Filter[]) => void,
  onItemsPerPageChange: (itemsPerPage: number) => void,
  onRowClick: (row: Record) => void,
  onRowDoubleClick: (row: Record) => void,
  onColumnWidthChange: (column: Column) => void,
  onColumnOrderChange: (columns: Column[]) => void,
  onSortChange: (sort: SortObj[]) => void,
  onRowSelect: (id: number, type: "select" | "unselect") => void,
  onSelectAll: (idArr: number[], type: "select" | "unselect") => void,
  onRowAdd: (row: Record) => void,
  onRowEdit: (row: Record) => void,
};

export type DatagridProps = {
  data: Record[],
  columns: Column[],
  visibleColumns: Column[],
  groups: [],
  sort: SortObj[],
  filters: Filter[],
  page: number,
  itemsPerPage: number,
  itemsCount: number,
  actions: Actions,
  selectionMode?: "checkbox",
  selectedItems?: Record[],
  disableFilters?: boolean,
  enableAdding?: boolean,
  enableEdit?: boolean,
  contextActions?: ContextMenuAction[],
};

type Props = {
  data: Record[],
  columns: Column[],
  rows: string[],
  measures: { name: string, filter?: string }[],
  selectionMode: "single" | "multiple",
  onItemMove: () => void,
  onContextMenu: () => void,
};

const DatagridV2 = (props: Props): React.Node => {
  const { columns, selectionMode } = props;

  const newColumns = useMemo(
    () =>
      selectionMode === "single" || selectionMode === "multiple"
        ? [
            {
              name: "checkbox",
              width: "48px",
              isVisible: true,
            },
            ...columns,
          ]
        : [...columns],
    [columns],
  );

  const visibleColumns = useMemo(
    () => newColumns.filter((c) => c.isVisible),
    [newColumns],
  );

  const newProps = useMemo(
    () => ({
      ...props,
      columns: newColumns,
      visibleColumns,
    }),
    [newColumns, props, visibleColumns],
  );

  return (
    <DatagridContextProvider value={{ ...newProps }}>
      <DragContextProvider>
        <ScrollContextProvider>
          <DatagridSimple />
        </ScrollContextProvider>
      </DragContextProvider>
    </DatagridContextProvider>
  );
};

export default DatagridV2;
