import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Box, IconButton, useDisclosure, Collapse } from "@chakra-ui/react";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";

const MenuElement = ({
  keyValue,
  path,
  displayName,
  children,
  onSelect,
  activePath,
  color,
}) => {
  const { isOpen, onToggle } = useDisclosure();

  const hasChildren = useMemo(
    () => children && Array.isArray(children) && children.length > 0,
    [children],
  );

  const handleOnClick = useCallback(
    (e) => {
      if (typeof onSelect === "function" && keyValue && path) {
        onSelect(e, { key: keyValue, path });
        e.preventDefault();
        e.stopPropagation();
      } else {
        onToggle();
      }
    },
    [keyValue, onSelect, onToggle, path],
  );

  const CollapseButton = hasChildren ? (
    <IconButton color={color} variant="ghost" size="sm" onClick={onToggle}>
      {isOpen ? <FiChevronDown /> : <FiChevronRight />}
    </IconButton>
  ) : (
    <span style={{ width: "32px", height: "32px", flexShrink: 0 }} />
  );

  return (
    <Box color={color}>
      <Box
        display="flex"
        flexFlow="row"
        alignItems="center"
        alignContent="center"
        fontWeight={
          activePath?.replace("/", "") === path?.replace("/", "")
            ? "bold"
            : undefined
        }
      >
        {CollapseButton}
        <Box
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          cursor="pointer"
          _hover={{ color: "var(--chakra-colors-gray-600)" }}
          onClick={handleOnClick}
        >
          {displayName}
        </Box>
      </Box>
      {hasChildren && (
        <Collapse in={isOpen} animateOpacity>
          <Box paddingLeft="8px">
            {children.map((structureElement) => (
              <MenuElement
                color="color"
                key={structureElement.key}
                {...structureElement}
                keyValue={structureElement.key}
                onSelect={onSelect}
                activePath={activePath}
              />
            ))}
          </Box>
        </Collapse>
      )}
    </Box>
  );
};

MenuElement.propTypes = {
  keyValue: PropTypes.string.isRequired,
  path: PropTypes.string,
  displayName: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      path: PropTypes.string,
      children: PropTypes.array,
    }),
  ),
  onSelect: PropTypes.func,
  activePath: PropTypes.string,
  color: PropTypes.string,
};

export default MenuElement;
