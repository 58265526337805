import PropTypes from "prop-types";
import { withBaseComponent } from "../../../with-base-component";
import { Box } from "@chakra-ui/react";
import { MozillaPdf } from "@echo/ui";
import { useResolveProp } from "../../../../../hooks/resolve-prop/use-resolve-prop";
import { useMemo } from "react";

const MozillaPfdComponent = ({ rootProps, src, onSave }) => {
  const { nodeRef, ...restRootProps } = rootProps;

  const srcValue = useResolveProp(src, true);

  const pdfSrc = useMemo(() => {
    if (!srcValue) {
      return undefined;
      // eslint-disable-next-line no-undef
    } else if (srcValue.includes(process.env.API_URL)) {
      return srcValue;
    } else {
      return (
        // eslint-disable-next-line no-undef
        process.env.API_URL +
        "File/proxyFileFromUrl?url=" +
        encodeURIComponent(srcValue)
      );
    }
  }, [srcValue]);

  return (
    <Box ref={nodeRef} {...restRootProps}>
      <MozillaPdf src={pdfSrc} onSave={onSave} />
    </Box>
  );
};

MozillaPfdComponent.propTypes = {
  src: PropTypes.string,
  onSave: PropTypes.func,
  rootProps: PropTypes.any,
};

export default withBaseComponent(MozillaPfdComponent);
