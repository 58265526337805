// @flow

import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import { FilterInput, FilterInputProps } from "./filter-input";
import { FilterOptionType } from "./filter-options";

type NumberFilterInputProps = {
  type: FilterOptionType,
  onChange: (value) => void,
  values: string[],
};

export const NumberFilterInput = (props: NumberFilterInputProps) => {
  const { type, onChange, values } = props;
  return type === "between" || type === "excludeRange" ? (
    <Box display="flex" alignItems="center" gap="5px">
      <FilterInput
        onChange={(val) => onChange(val, 0)}
        value={values[0] || ""}
      />
      <FilterInput
        onChange={(val) => onChange(val, 1)}
        value={values[1] || ""}
      />
    </Box>
  ) : (
    <FilterInput onChange={onChange} value={values[0] || ""} />
  );
};
