import PropTypes from "prop-types";
import EchoStateValueCell from "../../echo-state-value-cell";
import EchoInputStateValueCell from "../../echo-input-state-value-cell";
import EchoBoolValueCell from "../../../properties-table/value-cell/echo-bool-value-cell";
import EchoComboValueCell from "../../../properties-table/value-cell/echo-combo-value-cell";
import PermissionEditorEditCell from "../../../../permission-editor/permission-editor-edit-cell";
import { Box, Button, Input, ListItem, Text } from "@chakra-ui/react";
import { RiCloseFill } from "react-icons/ri";
import PropertiesTable from "../../../properties-table/properties-table";

export const ColumnsListItem = (props) => {
  const { onChange, deleteColumn, ...rest } = props;
  const { name } = rest;

  const propTypes = [
    {
      propName: "formatType",
      propType: "combo",
      options: [
        { value: "auto", label: "auto" },
        { value: "datetime", label: "dateTime" },
        { value: "boolean", label: "boolean" },
        { value: "string", label: "string" },
        { value: "number", label: "numeric" },
      ],
    },
    {
      propName: "format",
      propType: "inputSelect",
    },
    {
      propName: "editable",
      propType: "boolSelect",
    },
    {
      propName: "editType",
      propType: "comboSelect",
      options: [
        { value: "auto", label: "auto" },
        { value: "datetime", label: "datetime" },
        { value: "checkbox", label: "boolean" },
        { value: "input", label: "input" },
        { value: "numeric", label: "numeric" },
        { value: "combo", label: "combo" },
      ],
    },
    {
      propName: "editDataSource",
      propType: "processSelect",
    },
    {
      propName: "onChangeEvent",
      propType: "processSelect",
    },
  ];

  const customTypes = [
    {
      name: "processSelect",
      component: ({ propValue, onChange, propName }) => (
        <EchoStateValueCell
          propName={propName}
          propValue={propValue}
          onChange={onChange}
        />
      ),
    },
    {
      name: "inputSelect",
      component: ({ propValue, onChange, propName }) => (
        <EchoInputStateValueCell
          propName={propName}
          propValue={propValue}
          onChange={onChange}
        />
      ),
    },
    {
      name: "boolSelect",
      component: ({ propName, propValue, onChange }) => (
        <EchoBoolValueCell
          propName={propName}
          propValue={propValue}
          onChange={onChange}
        />
      ),
    },
    {
      name: "comboSelect",
      component: ({ propValue, onChange, options, propType }) => {
        return (
          <EchoComboValueCell
            propValue={propValue}
            propType={propType}
            onChange={(selection) => onChange(selection || null)}
            options={options}
            enableProcessSelect
          />
        );
      },
    },
    {
      name: "permissionEditorPropSelect",
      component: ({ propValue, onChange, model }) => (
        <PermissionEditorEditCell
          propValue={propValue}
          propTypes={[{ propName: "isVisible" }]}
          onChange={onChange}
          model={model}
        />
      ),
    },
  ];

  return (
    <ListItem
      maxWidth="300px"
      flexShrink="0"
      flexGrow="0"
      display="flex"
      flexDirection="column"
      gap="10px"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center" gap="10px">
          <Text>Name:</Text>
          <Input
            value={name}
            onChange={(e) => onChange({ ...rest, name: e.target.value })}
          />
        </Box>
        <Button padding="0" margin="0" onClick={deleteColumn} variant="ghost">
          <RiCloseFill
            style={{
              padding: "0",
              margin: "0",
              fontSize: "24px",
            }}
          />
        </Button>
      </Box>
      <PropertiesTable
        options={propTypes}
        model={rest}
        onModelChange={onChange}
        customTypes={customTypes}
      />
    </ListItem>
  );
};

ColumnsListItem.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  deleteColumn: PropTypes.func,
};
