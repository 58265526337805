import React from "react";
import PropTypes from "prop-types";

const EventsInfo = () => {
  return <div>EXTENSION COMPONENT</div>;
};

EventsInfo.propTypes = {};

export default EventsInfo;
