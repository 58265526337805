// @flow
import * as React from "react";
import type { Column } from "../../../datagrid-v2";
import { Cell } from "./cell";
import { TypedEditCell } from "./edit-cell/typed-edit-cell";

type InputCellProps = {
  column: Column,
  value: string,
  onChange: (value: any, columnName: Column) => void,
  autoFocus: boolean,
  style: string,
  row: Object,
};

export const EditCell = (props: InputCellProps): React.Node => {
  const { column, onChange, value, autoFocus, style, row } = props;
  const { type, editDataSource: options } = column;

  const handleChange = (value: any): void => {
    if (value === "") onChange(null, column);
    else onChange(value, column);
  };

  return (
    <Cell
      column={column}
      width={column.width}
      key={`${column.name}`}
      style={style}
    >
      <TypedEditCell
        onChange={handleChange}
        column={column}
        value={value}
        autoFocus={autoFocus}
        row={row}
      />
    </Cell>
  );
};
