import PropTypes from "prop-types";
import { withBaseComponent } from "../../../with-base-component";
import { shimFunctions } from "../../../../../../utils/shims/shims";
import { useDebouncedValueState } from "../../../../../hooks/shared/use-debounced-value-state";
import { Input } from "@echo/ui";
import { useState, useEffect } from "react";
import { getDefinition } from "./definition-loader";
import { generateNumeration } from "./generate-numeration";

const NumeratorComponent = ({
  variant,
  type,
  name,
  value,
  rootProps,
  dataSource,
  numeratorDefinitionId,
  context,
  className = "",
  onChange = shimFunctions.shimFunction1,
  label = "",
  required = false,
  disabled = false,
}) => {
  const [inputValue, setInputValue] = useDebouncedValueState(
    value,
    onChange,
    name,
  );
  const [definition, setDefinition] = useState(null);
  useEffect(() => {
    if (!definition && dataSource && dataSource.id) {
      getDefinition({ numeratorDefinitionId, dataSource, context }).then(
        (result) => {
          if (result.length) {
            setDefinition(result[0]);
          }
        },
      );
    }
  }, [context, dataSource, numeratorDefinitionId, definition]);

  useEffect(() => {
    if (!value) {
      if (definition) {
        const newNumeration = generateNumeration(
          context.state.formSource,
          definition,
        );
        if (newNumeration !== inputValue) {
          setInputValue(newNumeration);
        }
      }
    }
  }, [context?.state, definition, setInputValue, inputValue]);

  return (
    <Input
      {...rootProps}
      className={className}
      type={type}
      variant={variant}
      value={inputValue ?? ""}
      label={label}
      disabled={disabled}
      onChange={() => {}}
      required={required}
      isReadOnly
    />
  );
};

NumeratorComponent.propTypes = {
  numeratorDefinitionId: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  dataSource: PropTypes.func,
  value: PropTypes.any,
  onChange: PropTypes.funcOf(PropTypes.string),
  className: PropTypes.string,
  rootProps: PropTypes.any,
  variant: PropTypes.oneOf([""]),
  type: PropTypes.oneOf(["text"]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  context: PropTypes.any,
};

export default withBaseComponent(NumeratorComponent);
