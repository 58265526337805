export const initializePanPinchZoomDragState = () => ({
  toolbox: {},
  area: {},
  container: {},
});

export const panPinchZoomDragReducer = (prevState, action) => {
  switch (action.type) {
    case 'UPDATE_AREA':
      return { ...prevState, area: { ...prevState.area, ...action.payload } };
    case 'UPDATE_TOOLBOX':
      return {
        ...prevState,
        toolbox: { ...prevState.toolbox, ...action.payload },
      };
    case 'UPDATE_CONTAINER':
      return {
        ...prevState,
        container: { ...prevState.container, ...action.payload },
      };
    default:
      return prevState;
  }
};
