export const resultMapper = (items) => {
  if (!items) return [];

  return items
    .map((i) => {
      const { name, type, editType, editDataSource, format } = i;
      return {
        name,
        type,
        editType,
        editDataSource,
        format,
        isVisible: true,
        value: null,
      };
    })
    .filter((i) => i.name.toLowerCase() !== "id");
};
