import { showErrorToast } from "../../../components/shared/echo-error-toast";
export const editCalendarEvent = (block) => {
  return {
    definition: block,
    execute: () => {
      showErrorToast({
        reasonTitle: "Error. Wrong api/clientId key",
        location: "Incorrect api key or client ID",
        shortMessage: "Wrong api/clientId key",
        message: "Wrong api/clientId key",
      });
    },
  };
};
