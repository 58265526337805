import { useEffect, useState } from "react";

const createStructureElement = (dataElement) => ({
  key: dataElement.key,
  displayName: dataElement.displayName,
  path: dataElement.path,
  children: null,
});

const createStructure = (data, root) => {
  const childrenElements = data
    .filter((dataElement) => dataElement.parentKey === root.key)
    .map((dataElement) => {
      const structureElement = createStructureElement(dataElement);
      return createStructure(data, structureElement);
    });

  root.children = childrenElements;

  return root;
};

export const useMenu = ({ data }) => {
  const [structure, setStructure] = useState();

  useEffect(() => {
    if (data && Array.isArray(data)) {
      const availableKeys = data.map((elem) => elem.key).filter((key) => !!key);

      const roots = data.filter(
        (elem) => !elem.parentKey || !availableKeys.includes(elem.parentKey)
      );

      const structureTree = roots.map((dataElement) => {
        const structureElement = createStructureElement(dataElement);
        return createStructure(data, structureElement);
      });

      setStructure(structureTree);
    }
  }, [data]);

  return [structure];
};
