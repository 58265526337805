import { Box } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useCalendarDragContext } from "../hooks/use-calendar-drag-context";

export const EventResizer = (props) => {
  const { event, position } = props;
  const { handleDragStart } = useCalendarDragContext();

  return (
    <Box
      position="absolute"
      top={position === "top" ? "0" : ""}
      bottom={position === "bottom" ? "0" : ""}
      height="3px"
      width="100%"
      cursor="n-resize"
      draggable
      onPointerDown={() => {
        handleDragStart(event, `resize-${position}`);
      }}
      onDragStart={(e) => {
        e.preventDefault();
      }}
      onDrag={(e) => {
        e.preventDefault();
      }}
      onDragEnd={(e) => {
        e.preventDefault();
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      zIndex="20"
    />
  );
};

EventResizer.propTypes = {
  event: PropTypes.object,
  position: PropTypes.oneOf(["top", "bottom"]),
  date: PropTypes.instanceOf(Date),
  onResize: PropTypes.func,
  applyChange: PropTypes.func,
};
