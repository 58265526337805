import { useEffect, useState } from "react";

export const useSelection = (data, callback, selectionMode) => {
  const [selectedItems, setSelectedItems] = useState([]);

  const selectSingle = (id, type) => {
    if (type === "select") {
      if (selectionMode === "single") setSelectedItems([id]);
      else setSelectedItems((prev) => [...prev, id]);
    }

    if (type === "unselect") {
      setSelectedItems((prev) => prev.filter((el) => el !== id));
    }
  };

  const selectMany = (idArr, type) => {
    if (type === "select")
      setSelectedItems((prev) => [
        ...prev.filter((p) => !idArr.find((i) => p === i)),
        ...idArr,
      ]);

    if (type === "unselect")
      setSelectedItems((prev) =>
        prev.filter((p) => !idArr.find((i) => p === i)),
      );
  };

  const unselectAll = () => setSelectedItems([]);
  const selectAll = (arr) => setSelectedItems(arr);

  useEffect(() => {
    if (callback) {
      callback(selectedItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  return { selectedItems, selectSingle, selectMany, selectAll, unselectAll };
};
