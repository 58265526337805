import { Box } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { getProps } from "../../utils/row-utils";
import { EchoListItemElement } from "./list-item-element";
import { useEffect, useMemo, useState } from "react";

export const EchoListItem = (props) => {
  const { childs, values, context, rowEventListeners, ...restRootProps } =
    props;

  const [rowState, setRowState] = useState();

  const { rowEvent } = props;

  const rowEditEvent = useMemo(
    () => ({
      callback: (state) => rowEvent(state),
      listeners: rowEventListeners,
    }),
    [rowEvent, rowEventListeners],
  );

  useEffect(() => {
    setRowState(values);
  }, [values]);

  return (
    <Box display="flex" {...childs[0].styleOptions}>
      {childs[0].childrenComponents.map((child) => {
        const childProps = getProps(
          child,
          context,
          rowState,
          setRowState,
          rowEditEvent,
        );

        return (
          <EchoListItemElement
            key={child.key || `child-${child.id}-${context?.page?.key}}`}
            {...props}
            child={child}
            childProps={childProps}
            rootProps={restRootProps}
          />
        );
      })}
    </Box>
  );
};

EchoListItem.propTypes = {
  rowEvent: PropTypes.func,
  rowEventNames: PropTypes.string,
  rowEventListeners: PropTypes.string,
  childs: PropTypes.array,
  context: PropTypes.any,
  state: PropTypes.any,
  stateDispatch: PropTypes.any,
  localContext: PropTypes.any,
  path: PropTypes.string,
  designerMode: PropTypes.bool,
  mode: PropTypes.string,
  values: PropTypes.array,
};
