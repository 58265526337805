// @flow

import { Box, Button, Tfoot, Tr } from "@chakra-ui/react";
import React, { useState } from "react";
import { Cell } from "../cell/cell";
import { useDatagridContext } from "../../hooks/useDatagridContext";
import {
  DATAGRID_FOOTER_CELL_TESTID,
  DATAGRID_FOOTER_TESTID,
} from "../../utils/test-ids";
import { getResultData } from "../../utils/row-utils";
import { useScrollContext } from "../../hooks/use-scroll-context";
import { EditCell } from "../cell/edit-cell";
import { DisplayCell } from "../cell/display-cell";
import { FooterCell } from "../cell/footer-cell";

export const Footer = ({ columns }): React.Node => {
  const {
    enableAdding,
    actions: { onRowAdd },
  } = useDatagridContext();

  const { footerRef } = useScrollContext();

  const [rowState, setRowState] = useState({});

  const handleChange = async (value, column) => {
    const { name } = column;
    setRowState((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (column.onChangeEvent) {
      const { onChangeEvent } = column;
      const state = { ...rowState, [name]: value };
      const res = await onChangeEvent(state);
      const result = getResultData(res);
      setRowState((prev) => {
        return { ...prev, ...result };
      });
    }
  };

  const handleClick = async () => {
    if (onRowAdd) {
      try {
        await onRowAdd(rowState);
        setRowState({});
      } catch (e) {
        console.error("Error while adding new row.");
      }
    }
  };

  return (
    <Tfoot
      width="100%"
      display="block"
      overflow="hidden"
      borderTop="1px solid"
      minHeight="fit-content"
      data-testid={DATAGRID_FOOTER_TESTID}
      ref={footerRef}
    >
      <Tr>
        {columns.map((col) =>
          col.name === "checkbox" ? (
            <Cell
              column={col}
              width={col.width}
              key={`${col.name}-footer`}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              data-testid={DATAGRID_FOOTER_CELL_TESTID}
            >
              {enableAdding && (
                <Button
                  variant="unstyled"
                  onClick={handleClick}
                  height="100%"
                  width="100%"
                >
                  +
                </Button>
              )}
            </Cell>
          ) : (
            <FooterCell
              column={col}
              value={rowState[col.name]}
              row={rowState}
              onChange={handleChange}
              autoFocus={false}
              key={col.name}
              data-testid={DATAGRID_FOOTER_CELL_TESTID}
            />
          ),
        )}
      </Tr>
    </Tfoot>
  );
};
