import { toast } from "react-toastify";
import { getEchoVersion } from "../../services/echo-version-service";
import { clearLocalStorageWithException } from "../local-storage/local-storage";
import { localStorageKeys } from "../local-storage/local-storage-keys";

const key = "version";
const getCurrentVersion = () => localStorage.getItem(key);
const updateCurrentVersion = (version) => localStorage.setItem(key, version);
const reload = async () => {
  const exceptions = [
    localStorageKeys.GOOGLE_AUTH_INFO,
    localStorageKeys.GOOGLE_AUTH_STATUS,
    localStorageKeys.OPENED_PAGES,
  ];
  await clearLocalStorageWithException(exceptions);
  window.location.reload();
};

const reloadAndUpdate = (version) => {
  reload();
  updateCurrentVersion(version);
};

const handleToastChange = (t, version) => {
  if (t.status !== "removed") return;
  reloadAndUpdate(version);
};

export const checkVersion = async () => {
  const currentVersion = getCurrentVersion();
  const newestVersion = await getEchoVersion();

  if (!newestVersion) return;

  const { code, tag } = newestVersion;

  if (code !== "active") return;
  if (currentVersion === tag) return;
  if (!currentVersion) {
    updateCurrentVersion(tag);
    return;
  }

  if (currentVersion && currentVersion !== tag) {
    window.addEventListener("click", () => {
      reloadAndUpdate(tag);
    });

    toast.info("New version available. You will be logged out.", {
      autoClose: 3000,
      toastId: "new-version-toast",
    });

    toast.onChange((t) => handleToastChange(t, tag));
  }
};
