const getComponentByName = (component, name) => {
  if (component.name === name) {
    return component;
  } else if (
    component?.childrenComponents &&
    component?.childrenComponents?.length
  ) {
    for (let i = 0; i < component.childrenComponents.length; i += 1) {
      const comp = component?.childrenComponents[i];
      if (comp.name === name) {
        return comp;
      } else {
        const searchComp = getComponentByName(comp, name);
        if (searchComp) {
          return searchComp;
        }
      }
    }
  }
  return null;
};

const overrideEvent = (component, afterCreate) => {
  const toUpdateComponent = getComponentByName(
    component,
    afterCreate.componentName,
  );
  if (toUpdateComponent) {
    toUpdateComponent.componentProps[afterCreate.propertyName] =
      afterCreate.callbackEvent;
  }

  return component;
};

export const initializeAfterCreate = (component, afterCreate, setComponent) => {
  switch (afterCreate.afterCreateEventName) {
    case "OVERRIDE_EVENT":
      setComponent(overrideEvent(component, afterCreate));
      break;
    case "SAVE_CALLBACK":
      if (!component.callback) {
        setComponent({ ...component, callback: afterCreate.callbackEvent });
      }
      break;
    case "DEFAULT":
      if (!component?.callbackEvents?.DEFAULT) {
        setComponent({
          ...component,
          callbackEvents: { DEFAULT: afterCreate.callbackEvent },
        });
      }
      break;
  }
};
