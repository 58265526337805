import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Grid } from "@chakra-ui/react";
import ExtensionComponent from "./extension-component";
import ComponentNode from "./tree-element";

const ComponentTree = ({ extensions, structure }) => {
  const [selectedExtensionName] = useState("EVENTS_INFO");

  return (
    <Grid templateColumns="auto 300px">
      <Box>
        <ComponentNode structure={structure} />
      </Box>
      {selectedExtensionName && (
        <ExtensionComponent
          extensions={extensions}
          selectedExtensionName={selectedExtensionName}
        />
      )}
    </Grid>
  );
};

ComponentTree.propTypes = {
  extensions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      icon: PropTypes.node,
      extensionComponent: PropTypes.node,
    }),
  ),
  structure: PropTypes.shape({
    name: PropTypes.string,
    childrenComponents: PropTypes.array,
  }),
};

export default ComponentTree;
