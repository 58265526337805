import React, { useCallback, useContext } from "react";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import FlowSettingsTab from "../flow-settings-tab";
import EditorTab from "../editor-tab/editor-tab";
import ProcessBlockEditContext from "./process-block-edit-context";
import ParamsSetting from "../../../../shared/params-setting/params-settings";

const ProcessBlockEditTabs = () => {
  const modelContext = useContext(ProcessBlockEditContext);

  const handleUpdate = useCallback(
    (newStaticParams, schema) => {
      modelContext.updateModel({
        ...modelContext.model,
        flowSettings: {
          ...modelContext.model.flowSettings,
          inParameters: schema || modelContext.model.flowSettings.inParameters,
        },
        staticParams: newStaticParams,
      });
    },
    [modelContext],
  );

  return (
    <Tabs
      height="100%"
      minWidth={0}
      display="flex"
      flexFlow="column"
      flex="2 1 auto"
      boxSizing="border-box"
      minHeight={0}
    >
      <TabList>
        <Tab>Input parameters</Tab>
        <Tab>Input parameter types</Tab>
        <Tab>Return type</Tab>
        <Tab>Editor</Tab>
      </TabList>

      <TabPanels minHeight={0} width="100%" height="100%">
        <TabPanel>
          <ParamsSetting
            onAction={() => {}}
            parametersSchema={modelContext.model.flowSettings.inParameters}
            model={modelContext.model.staticParams ?? {}}
            onUpdate={handleUpdate}
          />
        </TabPanel>
        <TabPanel height="100%">
          <FlowSettingsTab
            {...modelContext}
            editEnabled
            compact
            modelName="inParameters"
          />
        </TabPanel>
        <TabPanel>
          <FlowSettingsTab
            {...modelContext}
            editEnabled
            compact
            modelName="returnType"
          />
        </TabPanel>
        <TabPanel height="100%" width="100%" minHeight="0">
          <EditorTab {...modelContext} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default ProcessBlockEditTabs;
