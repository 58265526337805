import { Box } from "@chakra-ui/react";
import { useApiContext } from "../../../../../echo-components/api-context/use-api-context";
import PropTypes from "prop-types";
import { withBaseComponent } from "../../../with-base-component";
import ChildrenComponent from "../children-component/children.component";
import DesignerShim from "../../../../shim-components/designer-shim/designer-shim";
import { useDataSource } from "../../hooks/use-datasource";
import { uuidv4 } from "@echo/tools";
import { useEffect } from "react";
import { showErrorToast } from "../../../../echo-error-toast";

const EnumeratorComponent = (props) => {
  const { id, guid, nodeId, rootProps, dataSource, context } = props;

  const { nodeRef, ...restRootProps } = rootProps;
  const [apiContext, component, mode] = useApiContext(id, guid, nodeId);

  const [enumerableElements, error] = useDataSource(
    dataSource,
    context,
    {
      callerId: component?.id,
      callerGuid: component?.guid,
      callerNodeId: component?.nodeId,
    },
    {}, //staticParams,
    [], //params
    true,
    (prev, next) => Array.isArray(next) && prev?.length !== next?.length,
  );

  useEffect(() => {
    if (error)
      showErrorToast({
        ...error,
        message: "Enumerator datasource. " + error.message,
      });
  }, [error]);

  const handleOnMouseOver = (e) => {
    if (mode === "designer") {
      e.preventDefault();
      e.stopPropagation();
      apiContext.api.ChangeDropAreaId(id);
    }
  };

  const hasChildren =
    component &&
    component?.childrenComponents &&
    Array.isArray(component?.childrenComponents) &&
    component?.childrenComponents.length > 0;

  if (hasChildren && component?.childrenComponents.length > 1) {
    window.console.warn(
      `Too many components applied to container component: ${component?.childrenComponents.length}`,
    );
  }

  const DesignerView =
    component &&
    component?.childrenComponents &&
    Array.isArray(component?.childrenComponents) &&
    component?.childrenComponents.length > 0 ? (
      <ChildrenComponent
        {...props}
        forceRendererMode
        onMouseOver={handleOnMouseOver}
        definition={component?.childrenComponents[0]}
      />
    ) : (
      <DesignerShim />
    );

  const RenderView = (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {enumerableElements &&
      Array.isArray(enumerableElements) &&
      enumerableElements.length > 0
        ? enumerableElements.map((element) => (
            <ChildrenComponent
              key={element?.id || uuidv4()}
              {...props}
              forceRendererMode
              localContext={element}
              onMouseOver={handleOnMouseOver}
              definition={component?.childrenComponents[0]}
            />
          ))
        : null}
    </>
  );

  return (
    <Box
      data-value={
        mode === "designer"
          ? JSON.stringify({ depth: component.depth, id })
          : undefined
      }
      ref={nodeRef}
      {...restRootProps}
    >
      {mode === "designer" ? DesignerView : RenderView}
    </Box>
  );
};

EnumeratorComponent.propTypes = {
  id: PropTypes.number,
  dataSource: PropTypes.any,
  rootProps: PropTypes.any,
  guid: PropTypes.string,
  nodeId: PropTypes.string,
  context: PropTypes.any,
};

export default withBaseComponent(EnumeratorComponent);
