export const addFilter = (arr, name, value) => [
  ...arr,
  { name, values: value && value !== "" ? [value] : [], type: "contains" },
];

export const removeFilter = (arr, name) => arr.filter((el) => el.name !== name);

export const changeFilter = (arr, name, value, idx) =>
  arr.map((i) => {
    if (i.name === name) {
      if (!idx) idx = 0;

      if (!i.values[idx] && i.values[idx] !== null) {
        if (!value || value === "") return i;

        return { ...i, values: [...i.values, value] };
      }

      const newValues = i.values.map((el, id) => {
        if (id === idx) {
          if (value === "" || !value) return null;
          return value;
        }

        return el;
      });

      return { ...i, values: newValues };
    }

    return i;
  });
