export const checkParamExists = (param) => {
  if (!param) {
    return false;
  }
  const paramType = typeof param;
  switch (paramType) {
    case 'string':
      return !!param;
    case 'bigint':
    case 'boolean':
    case 'function':
    case 'number':
    case 'object':
      return true;
    default:
      return false;
  }
};

export const validateParamExists = (param, paramName, asMessage = false) => {
  const paramExists = checkParamExists(param);
  let message = null;
  if (!paramExists) {
    message = `'${paramName} not exists'`;

    if (!asMessage) {
      throw new Error(message);
    }
  }

  return message;
};
