import React from "react";
import { Box } from "@chakra-ui/react";
import { StepperBtn } from "./stepper-btn";
import { useInputContext } from "../hooks/use-input-context";
import PropTypes from "prop-types";

export const InputStepper = (props) => {
  const { isDisabled, tabIndex } = props;
  const { stepperActions, step } = useInputContext();
  const { increment, decrement } = stepperActions;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      height="100%"
      position="absolute"
      right="0"
      zIndex={1}
    >
      <StepperBtn
        type="increment"
        isDisabled={isDisabled}
        onClick={() => increment(step)}
        tabIndex={tabIndex}
      />
      <StepperBtn
        type="decrement"
        isDisabled={isDisabled}
        onClick={() => decrement(step)}
        tabIndex={tabIndex}
      />
    </Box>
  );
};

InputStepper.propTypes = {
  isDisabled: PropTypes.bool,
  tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
