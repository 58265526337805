import { executeBlock, executeBlockByGroup } from "../process-executor";

export const createFormBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: (staticParams, params, systemParams) =>
      new Promise((resolve, reject) => {
        const action = componentContext.functions.getCreateAction();
        const formSource =
          componentContext.functions.getStateProp("formSource");
        if (action.type === "PROCESS") {
          Promise.resolve([
            executeBlock(
              componentContext,
              action.id,
              { ...staticParams, model: formSource },
              params,
              systemParams
            ),
          ])
            .then(([res]) => resolve(res))
            .catch(reject);
        } else if (action.type === "BLOCK_GROUP") {
          Promise.resolve([
            executeBlockByGroup(
              componentContext,
              { group: action.name, tag: "CREATE" },
              { ...staticParams, ...action.staticParams, model: formSource },
              params,
              systemParams
            ),
          ])
            .then(([res]) => resolve(res))
            .catch(reject);
        } else {
          throw new Error("Unexpected action type");
        }
      }),
  };
};
