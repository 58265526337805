import { ACTIONS } from "../mapping-reducer";

export const detectAction = (sourceListKey, destinationListKey) => {
  if (sourceListKey === destinationListKey) {
    return ACTIONS.CHANGE_ORDER;
  } else if (destinationListKey === "mappings") {
    return ACTIONS.BIND;
  } else if (
    destinationListKey === "destinationProps" ||
    (!destinationListKey && sourceListKey !== "destinationProps")
  ) {
    return ACTIONS.UNBIND;
  }
  return null;
};
