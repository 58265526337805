import { getBlock } from '../../../../../../services/diagram-service';

const deleteIdProps = (componentSchema) => {
  delete componentSchema['id'];
  const childrenComponentsLength =
    componentSchema?.childrenComponents?.length || 0;
  for (let i = 0; i < childrenComponentsLength; i++) {
    deleteIdProps(componentSchema.childrenComponents[i]);
  }
  return componentSchema;
};

const storeComponentRelations = (componentSchema) => {
  const schema = JSON.parse(JSON.stringify(componentSchema));
  const storedComponentRelations = [];
  if (schema.component) {
    const name = schema.component.name;
    storedComponentRelations.unshift(
      JSON.parse(exportSchema(schema.component))
    );
    schema.component = { __name: name };
  }
  const childrenLength = schema?.childrenComponents?.length || 0;
  let storedChildrenComponentRelations = [];
  for (let i = 0; i < childrenLength; i++) {
    const [newChild, childrenRelations] = storeComponentRelations(
      schema.childrenComponents[i]
    );
    schema.childrenComponents[i] = newChild;
    storedChildrenComponentRelations = [
      ...storedChildrenComponentRelations,
      ...childrenRelations.filter(
        (cmp) =>
          !storedChildrenComponentRelations
            .map((dcr) => dcr.component.name)
            .includes(cmp.component.name)
      ),
    ];
  }

  return [
    schema,
    [
      ...storedComponentRelations,
      ...storedChildrenComponentRelations.filter(
        (cmp) =>
          !storedComponentRelations
            .map((dcr) => dcr.component.name)
            .includes(cmp.component.name)
      ),
    ],
  ];
};

const deleteProcessRelations = (componentSchema) => {
  const schema = JSON.parse(JSON.stringify(componentSchema));
  const storedProcessRelations = [];
  if (schema.componentProps) {
    const propKeys = Object.keys(schema.componentProps);
    for (let i = 0; i < propKeys.length; i++) {
      const prop = schema.componentProps[propKeys[i]];
      if (prop && typeof prop === 'object' && prop.type === 'PROCESS') {
        schema.componentProps[propKeys[i]] = { __name: prop.name };
        storedProcessRelations.unshift({ name: prop.name, id: prop.id });
      }
    }
  }
  const childrenLength = schema?.childrenComponents?.length || 0;
  let storedChildrenProcessRelations = [];

  for (let i = 0; i < childrenLength; i++) {
    const [newChild, processRelations] = deleteProcessRelations(
      schema.childrenComponents[i]
    );
    schema.childrenComponents[i] = newChild;
    storedChildrenProcessRelations = [
      ...storedChildrenProcessRelations,
      ...processRelations.filter(
        (cmp) =>
          !storedProcessRelations.map((dcr) => dcr.name).includes(cmp.name)
      ),
    ];
  }

  return [
    schema,
    [
      ...storedProcessRelations,
      ...storedChildrenProcessRelations.filter(
        (cmp) =>
          !storedProcessRelations.map((dcr) => dcr.name).includes(cmp.name)
      ),
    ],
  ];
};

const deleteRelations = (componentSchema) => {
  const relations = {
    components: [],
    process: [],
  };

  const [withComponentRelations, componentRelations] =
    storeComponentRelations(componentSchema);

  relations.components = componentRelations;

  const [withoutProcessRelations, processRelations] = deleteProcessRelations(
    withComponentRelations
  );

  relations.process = processRelations;
  return [withoutProcessRelations, relations];
};

export const fillProcessRelations = (exportJson) =>
  new Promise((resolve, reject) => {
    const exportObject = JSON.parse(exportJson);
    Promise.all(
      exportObject.relations.process.map((relation) => getBlock(relation.id))
    )
      .then((resultBlocks) => {
        resolve(
          JSON.stringify({
            ...exportObject,
            relations: { ...exportObject.relations, process: resultBlocks },
          })
        );
      })
      .catch(reject);
  });

export const exportSchema = (componentSchema) => {
  const schemaClone = JSON.parse(JSON.stringify(componentSchema));

  const withoutId = deleteIdProps(schemaClone);

  const [component, relations] = deleteRelations(withoutId);

  const exportJsonString = JSON.stringify({ component, relations });
  return exportJsonString;
};
