const defaultFormat = { precision: 2, separator: "," };

const getSeparator = (format) => {
  const arr = format.split("");
  const f = arr.find((c) => c === "," || c === ".");
  return f;
};

const getPrecision = (format, separator) => {
  const arr = format.split(separator);
  return arr[1].length;
};

export const getNumberFormat = (format) => {
  if (!format || format === "") return defaultFormat;

  const separator = getSeparator(format);

  if (!separator) return { separator: "", precision: 0 };

  const precision = getPrecision(format, separator);
  return { precision, separator };
};
