export const useSelection = () => {
  const getIndexesForField = (arr) => {
    const regex = /[^a-zA-Z0-9]+/;
    let tempArr = [];
    let idxArr = [];

    arr.forEach((el, idx) => {
      if (!regex.test(el)) tempArr.push(idx);
      if (regex.test(el) || idx === arr.length - 1) {
        idxArr.push(tempArr);
        tempArr = [];
      }
    });

    return idxArr;
  };

  const getSelectionFields = (input) => {
    const charArr = input.split("");
    let fields = getIndexesForField(charArr);
    return fields;
  };

  const getSelectionRange = (string, clickedPos) => {
    const fields = getSelectionFields(string);
    let selectedField;

    fields.forEach((field) => {
      if (clickedPos >= field[0] && clickedPos <= field[field.length - 1] + 1) {
        selectedField = field;
      }
    });

    const start = selectedField ? selectedField[0] : string.length;
    const end = selectedField
      ? selectedField[selectedField.length - 1] + 1
      : string.length;

    return { start, end };
  };

  return { getSelectionRange };
};
