import React, { useRef, useEffect, useState } from "react";
import { EditorState, basicSetup } from "@codemirror/basic-setup";
import { EditorView, keymap } from "@codemirror/view";
import { defaultKeymap, indentWithTab } from "@codemirror/commands";
import { javascript } from "@codemirror/lang-javascript";
import { sql } from "@codemirror/lang-sql";
import { oneDark } from "@codemirror/theme-one-dark";
import { Box } from "@chakra-ui/react";
import PropTypes from "prop-types";
import "./styles/echo-react-codemirror.css";

const EchoReactCodemirror = ({
  value = "",
  type = "sql",
  onChange,
  ...props
}) => {
  const editor = useRef();
  const [view, setView] = useState(null);

  const onUpdate = EditorView.updateListener.of((v) => {
    if (onChange) {
      onChange(v.state.doc.toString());
    }
  });

  useEffect(() => {
    const state = EditorState.create({
      doc: "",
      extensions: [
        basicSetup,
        keymap.of([defaultKeymap, indentWithTab]),
        oneDark,
        type === "sql" ? sql() : javascript(),
        onUpdate,
      ],
    });

    const editorView = new EditorView({
      state,
      parent: editor.current,
    });

    setView(editorView);

    return () => {
      editorView.destroy();
    };
  }, []);

  useEffect(() => {
    if (view && (view.state.doc.toString() ?? "") === "") {
      view.dispatch(view.state.update({ changes: { from: 0, insert: value } }));
    }
  }, [value, view]);

  return <Box {...props} ref={editor} />;
};

EchoReactCodemirror.propTypes = {
  type: PropTypes.oneOf(["sql", "javascript"]),
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default EchoReactCodemirror;
