import { authenticateToGoogle } from "../../../services/email-service/utils/google-auth";

export const googleAuthBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: async () => {
      const userId = componentContext.userContext.userId;
      try {
        const data = await authenticateToGoogle(userId, true);
        return data;
      } catch (e) {
        throw new Error(e);
      }
    },
  };
};
