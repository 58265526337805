import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { AddIcon, CheckIcon } from "@chakra-ui/icons";

const LabeledCheckboxFormControl = forwardRef(
  (
    {
      label,
      value,
      indeterminate,
      defaultValue,
      onChange,
      nodeRef,
      onClick,
      rootProps,
      isReadOnly,
      // isRequired,
      // isInvalid,
      ...props
    },
    ref,
  ) => {
    const getValue = (value) => {
      const parsedValue =
        value === true || value == 1 || value === "true" ? true : false;

      if (isReadOnly) {
        return parsedValue || defaultValue || false || indeterminate === true;
      } else {
        return parsedValue || false || indeterminate === true;
      }
    };

    const handleOnChange = (e, ...args) => {
      e.preventDefault();
      e.stopPropagation();
      if (!isReadOnly && onChange) {
        const parsedValue = getValue(value);
        onChange(!parsedValue, e, ...args);
      }
    };

    return (
      <label
        className="chakra-checkbox echo-checkbox chakra-form-control"
        ref={nodeRef}
        onClick={onClick}
        {...rootProps}
        data-testid="checkbox-label-test"
      >
        <input
          className="chakra-checkbox__input"
          style={{
            border: "0px none",
            clip: "rect(0px, 0px, 0px, 0px)",
            height: "1px",
            width: "1px",
            margin: "-1px",
            padding: "0px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            position: "absolute",
          }}
          ref={ref}
          name={label}
          type="checkbox"
          checked={getValue(value)}
          onChange={handleOnChange}
          value={label}
          {...props}
          data-testid="checkbox-input-test"
        />
        <span
          onClick={handleOnChange}
          className="chakra-checkbox__control"
          style={{
            width: "var(--chakra-sizes-4)",
            transitionProperty: "property: box-shadow",
            transitionDuration:
              "duration: var(--chakra-transition-duration-normal)",
            border: "2px solid",
            borderRadius: "var(--chakra-radii-sm)",
            color: "var(--chakra-colors-white)",
            height: "var(--chakra-sizes-4)",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            verticalAlign: "top",
            userSelect: "none",
            flexShrink: "0",
            background: getValue(value)
              ? "var(--chakra-colors-blue-500)"
              : undefined,
            borderColor: getValue(value)
              ? "var(--chakra-colors-blue-500)"
              : "inherit",
          }}
        >
          {getValue(value) && (indeterminate ? <AddIcon /> : <CheckIcon />)}
        </span>
        {label && (
          <span
            className="chakra-checkbox__label"
            onClick={handleOnChange}
            style={{
              marginInlineStart: "0.5rem",
              userSelect: "none",
            }}
          >
            {label}
          </span>
        )}
      </label>
    );
  },
);

LabeledCheckboxFormControl.displayName = "LabeledCheckboxFormControl";

LabeledCheckboxFormControl.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
  indeterminate: PropTypes.bool,
  defaultValue: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  isInvalid: PropTypes.bool,
  nodeRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  rootProps: PropTypes.object,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
};

export default LabeledCheckboxFormControl;

// old checkbox code
{
  /* <label
        className="chakra-checkbox echo-checkbox chakra-form-control"
        ref={nodeRef}
        onClick={onClick}
        {...rootProps}
      >
        <input
          className="chakra-checkbox__input"
          style={{
            border: "0px none",
            clip: "rect(0px, 0px, 0px, 0px)",
            height: "1px",
            width: "1px",
            margin: "-1px",
            padding: "0px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            position: "absolute",
          }}
          ref={ref}
          name={label}
          type="checkbox"
          checked={getValue()}
          onChange={handleOnChange}
          value={label}
          {...props}
        />
        <span
          onClick={handleOnChange}
          className="chakra-checkbox__control"
          style={{
            width: "var(--chakra-sizes-4)",
            transitionProperty: "property: box-shadow",
            transitionDuration:
              "duration: var(--chakra-transition-duration-normal)",
            border: "2px solid",
            borderRadius: "var(--chakra-radii-sm)",
            color: "var(--chakra-colors-white)",
            height: "var(--chakra-sizes-4)",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            verticalAlign: "top",
            userSelect: "none",
            flexShrink: "0",
            background: getValue()
              ? "var(--chakra-colors-blue-500)"
              : undefined,
            borderColor: getValue()
              ? "var(--chakra-colors-blue-500)"
              : "inherit",
          }}
        >
          {getValue() && (indeterminate ? <AddIcon /> : <CheckIcon />)}
        </span>
        {label && (
          <span
            className="chakra-checkbox__label"
            onClick={handleOnChange}
            style={{
              marginInlineStart: "0.5rem",
              userSelect: "none",
            }}
          >
            {label}
          </span>
        )}
      </label> */
}

// new checkbox

{
  /* <FormControl
        ref={nodeRef}
        onClick={onClick}
        width="fit-content"
        className="chakra-checkbox echo-checkbox chakra-form-control"
        {...rootProps}
      >
        <Checkbox
          className="chakra-checkbox__input"
          isChecked={getValue()}
          ref={ref}
          name={label}
          value={label}
          onChange={handleOnChange}
          isInvalid={isInvalid}
          isRequired={isRequired}
          {...props}
        >
          {label}
        </Checkbox>
      </FormControl> */
}
