const checkProperties = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  const boolArr = keys1.map((k, idx) => k === keys2[idx]);
  return boolArr.some((b) => b === true);
};

const checkValues = (obj1, obj2) => {
  const keys = Object.keys(obj1);
  const boolArr = keys.map((k) => obj1[k] !== obj2[k]);
  return boolArr.some((b) => b === true);
};

export const areSameObjects = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  const hasSameProperties = checkProperties(obj1, obj2);
  const hasDiff = checkValues(obj1, obj2);
  if (hasSameProperties && !hasDiff) return true;

  return false;
};
