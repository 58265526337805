import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { executeBlock } from "../../../../../../../process-executor/process-executor";
import { areSameArrays } from "../utils/are-same-arrays";
import { sortByNameAndVisibility } from "../utils/sort-items";
import { resultMapper } from "../utils/result-mapper";

export const useItems = (dataSource, context) => {
  const [items, setItems] = useState();

  const getValues = async (dataSource, context) => {
    try {
      const res = await executeBlock(context, dataSource.id, {}, []);
      const items = resultMapper(res);
      setItems((prev) => (areSameArrays(prev, items) ? prev : items));
    } catch (e) {
      toast.error(e);
    }
  };

  const handleItemChange = (item) =>
    setItems((prev) =>
      prev.map((i) => {
        if (i.name === item.name) return item;

        return i;
      }),
    );

  useEffect(() => {
    if (context) {
      getValues(dataSource, context);
    }
  }, [dataSource, context]);

  const sortedItems = useMemo(() => {
    if (!items) return [];
    return items.sort(sortByNameAndVisibility);
  }, [items]);

  return {
    items: sortedItems,
    onItemChange: handleItemChange,
  };
};
