import { ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import PropTypes from "prop-types";
import { Box, IconButton, Input } from "@chakra-ui/react";
import { useMemo } from "react";

const DataGridFooter = ({
  page,
  count,
  itemsPerPage,
  onReversePage,
  onPageChange,
  onForwardPage,
  pageSizeSelect,
}) => {
  const pagesCount = Math.ceil(count / itemsPerPage);
  const currentPage = useMemo(() => page + 1, [page]);

  const handlePageChange = (e) => {
    const page = parseInt(e.target.value) - 1;
    onPageChange(page);
  };

  return (
    <Box
      display="flex"
      width="auto"
      padding="5px 0 0 0"
      justifyContent="space-between"
    >
      <Box
        display="flex"
        alignItems="center"
        alignContent="center"
        textAlign="right"
        color="var(--chakra-colors-grayCa)"
      >
        Total: {count}
      </Box>

      <Box display="flex" width="auto" justifyContent="flex-end" gap="8px">
        {pageSizeSelect}
        <IconButton
          variant="ghost"
          isDisabled={!page || page === 0}
          onClick={onReversePage}
        >
          <ChevronLeftIcon />
        </IconButton>

        <Box
          minWidth="60px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <h4 style={{ padding: "0 5px", fontSize: "16px" }}>
            <Input
              width="40px"
              textAlign="center"
              margin="0"
              value={currentPage || ""}
              onChange={handlePageChange}
              variant="unstyled"
              fontSize="16px"
            />
            of {pagesCount}
          </h4>
        </Box>
        <IconButton
          variant="ghost"
          isDisabled={page * itemsPerPage + itemsPerPage > count}
          onClick={onForwardPage}
        >
          <ChevronRightIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

DataGridFooter.propTypes = {
  itemCount: PropTypes.number,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  onReversePage: PropTypes.func.isRequired,
  onForwardPage: PropTypes.func.isRequired,
  onPageChange: PropTypes.func,
  pageSizeSelect: PropTypes.node,
};

export default DataGridFooter;
