export const createBlock = (relatedBlock) => {
  return {
    isAsynchronous: false,
    flowSettings: {
      inParameters: JSON.parse(
        relatedBlock.inParameters ? relatedBlock.inParameters : '{}'
      ),
      returnType: JSON.parse(
        relatedBlock.returnType ? relatedBlock.returnType : '{}'
      ),
    },
    body: relatedBlock.body ?? '',
  };
};
