import PropTypes from "prop-types";
import PropertiesValueInput from "../cell-components/properties-table-input";
import EditorComponent from "../cell-components/editor-component";

const EchoEditorValueCell = ({
  propValue = "",
  onChange,
  onAction,
  propType,
}) => {
  const handleOnAction = () => {
    if (onAction) {
      onAction({
        type: "open-editor",
        component: (
          <EditorComponent
            value={propValue}
            onChange={(newValue) =>
              Promise.all([onChange(newValue), onAction({ type: "save" })])
            }
            options={{
              mode:
                propType === "postgresql-editor" ? "text/x-pgsql" : "text/text",
              lineNumbers: true,
            }}
          />
        ),
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: propValue ? "space-between" : "flex-end",
        flexDirection: "row",
        marginLeft: "8px",
      }}
    >
      <PropertiesValueInput
        value={propValue}
        clearOnEdit={typeof propValue === "object"}
        onChange={(e) => {
          onChange(e);
        }}
      />
      <button onClick={handleOnAction}>...</button>
    </div>
  );
};

EchoEditorValueCell.propTypes = {
  propValue: PropTypes.any,
  onChange: PropTypes.func,
  onAction: PropTypes.func,
  propType: PropTypes.string,
};

export default EchoEditorValueCell;
