import { forwardRef } from "react";
import PropTypes from "prop-types";
import { withBaseComponent } from "../../../with-base-component";
import { useDebouncedValueState } from "../../../../../hooks/shared/use-debounced-value-state";
import { useResolveProp } from "../../../../../hooks/resolve-prop/use-resolve-prop";
import { FormControl, FormLabel, Textarea } from "@chakra-ui/react";

const TextareaComponent = forwardRef(
  (
    {
      id,
      colorScheme,
      isDisabled,
      isInvalid,
      isReadOnly,
      isRequired,
      size,
      name,
      value,
      maxLength,
      onChange,
      rootProps,
      placeholder,
      label = "",
      variant = "floating",
    },
    ref,
  ) => {
    const [inputValue, setInputValue] = useDebouncedValueState(
      value,
      onChange,
      name,
    );
    const isReadOnlyState = useResolveProp(isReadOnly, true);

    const handleOnInputChange = (e) => setInputValue(e.target.value);

    const { nodeRef, style, ...restRootProps } = rootProps;

    return (
      <FormControl
        ref={nodeRef}
        width="248px"
        {...restRootProps}
        style={style}
        variant={variant}
        id={id}
        colorScheme={colorScheme}
        isDisabled={isDisabled}
        isInvalid={isInvalid}
        isReadOnly={isReadOnlyState}
        isRequired={isRequired}
        size={size}
      >
        <Textarea
          maxLength={maxLength}
          className={inputValue ? "text-area-value" : undefined}
          ref={ref}
          height="100%"
          fontSize={style?.fontSize}
          width="100%"
          maxHeight="100%"
          maxWidth="100%"
          resize="both"
          value={inputValue ?? ""}
          placeholder={placeholder}
          onChange={handleOnInputChange}
        />
        <FormLabel>{label}</FormLabel>
      </FormControl>
    );
  },
);

TextareaComponent.displayName = "TextareaComponent";

TextareaComponent.propTypes = {
  id: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.funcOf(PropTypes.string, PropTypes.string),
  defaultValue: PropTypes.string,
  maxLength: PropTypes.string,
  isReadOnly: PropTypes.bool,
  isDisabled: PropTypes.bool,
  variant: PropTypes.oneOf(["filled", "outlined", "floating"]),
  type: PropTypes.oneOf([undefined, "password", "number", "text"]),
  nodeRef: PropTypes.any,
  colorScheme: PropTypes.string,
  isInvalid: PropTypes.bool,
  isRequired: PropTypes.bool,
  size: PropTypes.string,
  rootProps: PropTypes.any,
};

export default withBaseComponent(TextareaComponent);
