import {
  Button,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/react";
import { MdOutlineMoreVert } from "react-icons/md";
import PropTypes from "prop-types";

export const InputTypeSwitch = ({ value, onChange }) => {
  const input = "input";
  const textarea = "textarea";
  const switchInput = () => onChange(input);
  const switchTextarea = () => onChange(textarea);

  return (
    <Popover>
      <PopoverTrigger>
        <Button variant="ghost" fontSize="18px" flexShrink="0">
          <MdOutlineMoreVert />
        </Button>
      </PopoverTrigger>
      <PopoverContent width="fit-content">
        <PopoverCloseButton />
        <PopoverHeader padding="5px 15px">Select input type</PopoverHeader>
        <PopoverBody
          padding="10px"
          display="flex"
          alignItems="center"
          gap="5px"
        >
          <SwitchBtn
            text="Input"
            isActive={value === input}
            onClick={switchInput}
          />
          <SwitchBtn
            text="Textarea"
            isActive={value === textarea}
            onClick={switchTextarea}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

InputTypeSwitch.propTypes = {
  value: PropTypes.oneOf(["input", "textarea"]),
  onChange: PropTypes.func,
};

const SwitchBtn = ({ text, isActive, onClick }) => {
  return (
    <Button
      isActive={isActive}
      variant="ghost"
      colorScheme="cyan"
      _hover={{}}
      textColor="black"
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

SwitchBtn.propTypes = {
  text: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
};
