import PropTypes from "prop-types";
import { datagridFooterDataTypes } from "../data-footer/data-type-selector";
import { ColumnsEditor } from "./columns-editor";

const ColumnSettings = ({
  isOpen,
  onClose,
  defaultColumns,
  onChange,
  containerRef,
  isCombo,
  settingsId,
  settingsKey,
  actions,
}) => {
  const handleChange = (newColumns, userId, roleId, type, company) => {
    if (onChange && typeof onChange === "function") {
      onChange(newColumns, userId, roleId, type, company);
    }
  };

  const handleClose = (e) => {
    onClose(e);
  };

  const propTypes = [
    {
      propName: "name",
      propType: "inputSelect",
    },
    {
      propName: "editName",
      propType: "inputSelect",
    },
    {
      propName: "displayName",
      propType: "inputSelect",
    },
    {
      propName: "order",
      propType: "inputSelect",
    },
    {
      propName: "isVisible",
      propType: "boolSelect",
    },
    {
      propName: "width",
      propType: "inputSelect",
    },
    {
      propName: "align",
      propType: "combo",
      options: [
        { value: "left", label: "left" },
        { value: "center", label: "center" },
        { value: "right", label: "right" },
      ],
    },
    {
      propName: "editable",
      propType: "boolSelect",
    },
    {
      propName: "disableSummary",
      propType: "boolSelect",
    },
    {
      propName: "defaultSummary",
      propType: "combo",
      options: datagridFooterDataTypes,
    },
    {
      propName: "editType",
      propType: "comboSelect",
      options: [
        { value: "auto", label: "auto" },
        { value: "datetime", label: "datetime" },
        { value: "checkbox", label: "boolean" },
        { value: "input", label: "input" },
        { value: "numeric", label: "numeric" },
        { value: "combo", label: "combo" },
      ],
    },
    {
      propName: "editDataSource",
      propType: "processSelect",
    },
    {
      propName: "formatType",
      propType: "combo",
      options: [
        { value: "auto", label: "auto" },
        { value: "datetime", label: "dateTime" },
        { value: "boolean", label: "boolean" },
        { value: "string", label: "string" },
        { value: "number", label: "numeric" },
      ],
    },
    {
      propName: "format",
      propType: "inputSelect",
    },
  ];

  return (
    isOpen && (
      <ColumnsEditor
        isLoading={false}
        columns={defaultColumns}
        onSave={handleChange}
        open={isOpen}
        onClose={handleClose}
        containerRef={!isCombo ? containerRef : null}
        propTypes={propTypes}
        propertiesSearch
        title="Column editor"
        settingsId={settingsId}
        settingsKey={settingsKey}
        actions={actions}
      />
    )
  );
};

ColumnSettings.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  defaultColumns: PropTypes.array,
  columnKey: PropTypes.string, //dodane na potrzeby pobrania modelu z kolumnami, których nie ma w user_setting
  sourceObject: PropTypes.any,
  containerRef: PropTypes.any,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  storeSettings: PropTypes.func,
  columnSettingKey: PropTypes.string,
  userColumnSettings: PropTypes.shape({
    isLoading: PropTypes.bool,
    settingsGlobalUserId: PropTypes.number,
    currentSettingsDetails: PropTypes.shape({
      id: PropTypes.number,
      key: PropTypes.string,
      columns: PropTypes.array,
      type: PropTypes.string,
      userId: PropTypes.number,
      roleId: PropTypes.number,
    }),
    actions: PropTypes.shape({
      updateColumnsWithModel: PropTypes.func,
      getColumnsSettings: PropTypes.func,
      handleSettingsId: PropTypes.func,
      handleKey: PropTypes.func,
      handleColumns: PropTypes.func,
      handleType: PropTypes.func,
      handleUserId: PropTypes.func,
      handleRoleId: PropTypes.func,
      getNewTableModel: PropTypes.func,
    }),
  }),
  isCombo: PropTypes.bool,
  settingsId: PropTypes.number,
  settingsKey: PropTypes.string,
  actions: PropTypes.array,
};

export { ColumnSettings };
