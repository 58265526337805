import PropTypes from "prop-types";
import { Box, IconButton } from "@chakra-ui/react";
import { shimFunctions } from "../../../../../../utils/shims/shims";
import PropertiesShim from "../../../../../shared/shim-components/properties-shim/properties-shim";
import { getComponent } from "../../../../../../utils/echo-component/echo-component-utils";
import ComponentPropertiesTable from "../../../../../shared/properties/component-properties-table/component-properties-table";
import { ChevronRightIcon, ChevronUpIcon, DeleteIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { Input } from "@echo/ui";

const getTableOptionType = (propTypeName) => {
  switch (propTypeName) {
    case "string":
      return "inputSelect";
    case "bool":
      return "boolSelect";
    case "oneOf":
      return "comboSelect";
    case "datagridColumnsArrayOf":
      return "datagridColumnsEditor";
    case "tabsArrayOf":
      return "tabsPropSelect";
    case "permissionEditorArrayOf":
      return "permissionEditorPropSelect";
    // TODO Add support to standard arrayOf
    // return 'arrayOfSelect';
    case "listEventListenersArrayOf":
      return "listEventListenersPropSelect";
    case "arrayOf":
    default:
      return "processSelect";
  }
};

const getPropertiesTableOptions = (propTypesModel) => {
  switch (propTypesModel?.type) {
    case "built-in": {
      const propTypes = propTypesModel.properties;
      let result = Object.keys(propTypes).map((propKey) => {
        return {
          propName: propKey,
          propType: getTableOptionType(propTypes[propKey]?.info?.propTypeName),
          propTypeObject: propTypes[propKey],
        };
      });
      return result.some((o) => o.propName === "name")
        ? result
        : [{ propName: "name", propType: "input" }, ...result];
    }
    case "!?echo-defined":
      return [
        { propName: "name", propType: "input" },
        { propName: "useRootContext", propType: "bool" },
        ...(propTypesModel?.properties || []).map(({ propName, propType }) => ({
          propName,
          propType: getTableOptionType(propType),
        })),
      ];
    default:
      return [];
  }
};

const getPropTypes = (component) => {
  const source = component?.component?.source;

  if (!source) {
    return null;
  }

  if (source === "!?echo-defined") {
    return {
      type: "!?echo-defined",
      properties: component.component.properties,
    };
  } else {
    return {
      type: "built-in",
      properties: getComponent(source).propTypes,
    };
  }
};

const Properties = ({
  modelSchema,
  component,
  onModelSchemaChange,
  onAction,
  designerPortalRef,
  componentInfo,
  id = null,
  onModelChange = shimFunctions.shimFunction1,
  model = {},
}) => {
  const propTypes = getPropTypes(component);
  const [componentId, setComponentId] = useState(component?.id);

  return id && model !== null && component ? (
    <div style={{ padding: "16px", height: "100%", overflowY: "auto" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          {"\t"}
          {component?.component?.displayName},{"\t"}name:{" "}
          {component?.componentProps?.name
            ? component?.componentProps?.name
            : "<unknown_name>"}
          , <br />
          {"\t"}
          <br />
          id: {component.id > 0 ? component.id : "<new>"}
        </div>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <Input
              style={{ backgroundColor: "white" }}
              value={componentId}
              onChange={(e) => setComponentId(e.target.value)}
            />
            <IconButton
              color="secondary"
              variant="contained"
              onClick={() =>
                onAction(id, { type: "GO_TO", id: Number(componentId) })
              }
            >
              <ChevronRightIcon />
            </IconButton>
          </Box>
          <IconButton
            color="secondary"
            variant="contained"
            onClick={() => onAction(id, { type: "GO_TO_PARENT" })}
          >
            <ChevronUpIcon />
          </IconButton>

          <IconButton
            onClick={() => onAction(id, { type: "DELETE" })}
            color="secondary"
            variant="contained"
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </div>
      {propTypes ? (
        <ComponentPropertiesTable
          key={component.id}
          options={getPropertiesTableOptions(propTypes)}
          stateSchema={modelSchema}
          onStateSchemaChange={onModelSchemaChange}
          model={model}
          onModelChange={onModelChange}
          designerPortalRef={designerPortalRef}
          componentId={component?.id}
          diagramParams={{ objectId: componentInfo.relatedObjectId }}
        />
      ) : null}
    </div>
  ) : (
    <PropertiesShim />
  );
};

Properties.propTypes = {
  id: PropTypes.any,
  model: PropTypes.object,
  modelSchema: PropTypes.object.isRequired,
  onModelSchemaChange: PropTypes.func.isRequired,
  onAction: PropTypes.funcOf(PropTypes.number),
  onModelChange: PropTypes.funcOf(PropTypes.object),
  designerPortalRef: PropTypes.shape({ current: PropTypes.any }),
  component: PropTypes.shape({
    id: PropTypes.number,
    depth: PropTypes.number,
    componentProps: PropTypes.any, // todo replace any
    component: PropTypes.shape({
      source: PropTypes.string.isRequired,
      displayName: PropTypes.string,
    }),
  }),
  componentInfo: PropTypes.shape({ relatedObjectId: PropTypes.any }),
};

export default Properties;
