import { theme } from "../../../../theme/themeCaldo";

export const getStyle = (
  activeValue,
  inactiveValue,
  modeCondition,
  highlightCondition
) => {
  if (modeCondition) {
    return highlightCondition ? activeValue : inactiveValue;
  }
  return undefined;
};

export const getDesignerStyleOptions = (
  dragMode,
  designerMode,
  selectedComponent,
  activeAreaId,
  id
) => {
  const modeCondition = designerMode;
  const highlightCondition =
    selectedComponent === id || (dragMode && activeAreaId === id);
  return {
    borderColor: getStyle(
      theme.colors.redCa,
      "grey",
      modeCondition,
      highlightCondition
    ),
    borderWidth: getStyle("3px", "1px", modeCondition, highlightCondition),
    borderStyle: getStyle("solid", "dashed", modeCondition, highlightCondition),
  };
};
