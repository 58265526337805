import { useCallback, useContext, useMemo } from "react";
import { useSettings } from "./use-settings";
import { useData } from "./use-data";
import { useColumns } from "./use-columns";
import { showErrorToast } from "../../../../../echo-error-toast";
import UserContext from "../../../../../../../user-context";
import { mapAndSortColumns } from "../utils/map-and-sort-columns";
import { mergeColumns } from "../utils/merge-columns";
import { getNumberFormatConfig } from "../../../../../../../app-config/find-config";

export const useDatagrid = (props) => {
  const { userId, companyId } = useContext(UserContext);
  const { id, name, context, dataSource, onRowEdit, onRowAdd, disableHeader } =
    props;

  const { resolvedColumns } = useColumns(props);

  const [settings, setSettings] = useSettings(id, dataSource, {
    disableHeader: !!disableHeader,
  });

  const args = [context, dataSource, settings, name];
  const { state, onChange, refreshData, getData } = useData(...args);

  const settingsValue = useMemo(() => {
    // eslint-disable-next-line no-unused-vars
    const { id, key, loading, ...restSettings } = settings;
    return restSettings;
  }, [settings]);

  const onFilterChange = (value) => onChange("filters", value);
  const onPageChange = (value) => onChange("page", value);

  const onSortChange = (sortArr) =>
    setSettings({ ...settingsValue, sorting: sortArr }, userId, companyId);

  const onColumnChange = (columns, userId, roleId, type, companyId) =>
    setSettings({ ...settingsValue, columns }, userId, companyId, type);

  const onItemsPerPageChange = (itemsPerPage) =>
    setSettings({ ...settingsValue, itemsPerPage }, userId, companyId);

  const onHeaderVisibilityChange = (bool) =>
    setSettings({ ...settingsValue, disableHeader: bool }, userId, companyId);

  const onFiltersVisibilityChange = (bool) =>
    setSettings({ ...settingsValue, disableFilters: bool }, userId, companyId);

  const onNavPositionChange = (pos) =>
    setSettings({ ...settingsValue, navPosition: pos }, userId, companyId);

  const checkColumnTypeNumber = (col) => {
    if (
      col?.type === "number" ||
      col?.type === "numeric" ||
      col?.editType === "number" ||
      col?.editType === "numeric"
    ) {
      return true;
    }

    return false;
  };

  const mapNumberColumnFormat = useCallback((c) => {
    const defaultNumberFormat = getNumberFormatConfig();
    if (checkColumnTypeNumber(c)) {
      return { ...c, format: c.format || defaultNumberFormat };
    }

    return c;
  }, []);

  const mergedColumns = useMemo(
    () =>
      mergeColumns(state.columns, settings.columns).map(mapNumberColumnFormat),
    [state.columns, settings.columns, mapNumberColumnFormat],
  );

  const mappedAndSortedColumns = useMemo(
    () => mapAndSortColumns(mergedColumns, resolvedColumns),
    [mergedColumns, resolvedColumns],
  );

  const handleRowAdd = async (row) => {
    try {
      await onRowAdd(row);
    } catch (e) {
      showErrorToast(e);
    } finally {
      await refreshData();
    }
  };

  const handleRowEdit = async (row) => {
    try {
      await onRowEdit(row);
    } catch (e) {
      showErrorToast(e);
    } finally {
      await refreshData();
    }
  };

  const api = {
    onColumnChange,
    onPageChange,
    refreshData,
    getData,
    onHeaderVisibilityChange,
    onFiltersVisibilityChange,
    onNavPositionChange,
    onRowEdit: handleRowEdit,
    onRowAdd: handleRowAdd,
    onSortChange: (val) => {
      onSortChange(val);
      onPageChange(0);
    },
    onItemsPerPageChange: (val) => {
      onItemsPerPageChange(val);
      onPageChange(0);
    },
    onFilterChange: (val) => {
      onFilterChange(val);
      onPageChange(0);
    },
  };

  return {
    state: {
      ...state,
      data: state.data,
      columns: mappedAndSortedColumns,
      settings: {
        ...settings,
        columns: mergedColumns,
        itemsPerPage: { value: settings.itemsPerPage },
      },
    },
    api,
  };
};
