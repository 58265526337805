import { Button, Input, InputGroup, InputRightAddon } from "@chakra-ui/react";
import { FaSearch } from "react-icons/fa";
import PropTypes from "prop-types";
import { useState } from "react";

export const SearchBar = ({ onSearch }) => {
  const [value, setValue] = useState("");
  return (
    <InputGroup size="md">
      <Input
        pr="4.5rem"
        type="text"
        placeholder="Szukaj..."
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <InputRightAddon padding="0">
        <Button
          height="100%"
          width="100%"
          variant="ghost"
          onClick={() => onSearch(value)}
        >
          <FaSearch />
        </Button>
      </InputRightAddon>
    </InputGroup>
  );
};

SearchBar.propTypes = {
  onSearch: PropTypes.func,
};
