import { getSeparators } from "./split-format";

const digitsFormatCharacters = ["#", "0"];

const lettersDigitsMinusRegex = /^[a-zA-Z0-9-]+$/;
const zeroHashRegex = /^[#0]+$/;

export const showAlways = (c) => c === "0";
export const showGreaterThanZero = (c) => c === "#";

export const testLettersDigitsMinus = (str) =>
  lettersDigitsMinusRegex.test(str);

export const testZeroHash = (str) => zeroHashRegex.test(str);

export const isKeyAllowed = (k, format) => {
  const { decimal, group } = getSeparators(format);

  if (
    k === group ||
    k === decimal ||
    (decimal && k === ",") ||
    testLettersDigitsMinus(k)
  )
    return true;

  return false;
};
