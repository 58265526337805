import { executeBlock } from '../../../../../../../process-executor/process-executor';

const getTreeData = (dataSource, componentContext) =>
  executeBlock(
    componentContext,
    dataSource.id,
    {
      ...dataSource.staticParams,
    },
    [],
    {
      callerId: componentContext?.component?.id,
      callerGuid: componentContext?.component?.guid,
      callerNodeId: componentContext?.component?.nodeId,
    }
  );

const findPropByNameCaseInsensitive = (object, propName) => {
  if (typeof object !== 'object' || Array.isArray(object)) {
    throw new Error('Cannot get property. Wrong object type');
  }

  for (let propKey of Object.keys(object)) {
    if (propKey.toLowerCase() === propName.toLowerCase()) {
      return object[propKey];
    }
  }

  return undefined;
};

const convertToSchema = (treeData) => {
  const schema = {};
  for (let dataElement of treeData) {
    const elementKeys = Object.keys(dataElement);

    let actual = schema;
    for (let key of elementKeys) {
      if (!key.toLowerCase().endsWith('value') && dataElement[key]) {
        if (!actual[dataElement[key]]) {
          actual[dataElement[key]] = {
            __meta: [
              ...(actual.__meta || []),
              {
                path: key,
                label: dataElement[key],
                value: findPropByNameCaseInsensitive(
                  dataElement,
                  `${key}Value`
                ),
              },
            ],
          };
        }
        actual = actual[dataElement[key]];
      }
    }
  }

  return schema;
};

export const getTreeSchema = (dataSource, componentContext) =>
  new Promise((resolve, reject) => {
    if (dataSource && dataSource.id) {
      getTreeData(dataSource, componentContext)
        .then((treeData) => {
          resolve(convertToSchema(treeData));
        })
        .catch(reject);
    } else {
      resolve(null);
    }
  });
