import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  HStack,
  IconButton,
  ButtonGroup,
  Button,
  Popover,
  PopoverTrigger,
  PopoverBody,
  PopoverContent,
  PopoverAnchor,
  Portal,
} from "@chakra-ui/react";
import { FiMinus, FiMove, FiPlus } from "react-icons/fi";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

const PPZNavigation = ({ customNavigation, api }) => {
  return (
    <HStack bottom="24px" right="32px" position="absolute">
      <Box
        padding="3px"
        border="2px solid var(--chakra-colors-gray-200);"
        borderRadius="25px"
        height="fit-content"
      >
        <IconButton
          color="var(--chakra-colors-gray-600);"
          variant="ghost"
          onClick={() => {
            // temporary solution for not working .zoomIn
            const oldScale = api.instance.transformState.scale;
            const zoom = 0.2;
            api.zoomIn(zoom);
            api.setTransform(0, 0, oldScale + zoom * 16);
            // api.zoomIn(0.1);
          }}
        >
          <FiPlus />
        </IconButton>
        <IconButton
          color="var(--chakra-colors-gray-600);"
          variant="ghost"
          onClick={() => api.zoomOut()}
        >
          <FiMinus />
        </IconButton>
        <IconButton
          color="var(--chakra-colors-gray-600);"
          variant="ghost"
          onClick={() => api.resetTransform()}
        >
          <FiMove />
        </IconButton>
      </Box>
      {customNavigation.length && (
        <Box
          padding="3px"
          border="2px solid var(--chakra-colors-gray-200);"
          borderRadius="25px"
          height="fit-content"
          display="flex"
          alignItems="center"
        >
          {customNavigation?.map((actionButton) => {
            return actionButton.type === "button" ? (
              <IconButton
                isDisabled={actionButton.isDisabled}
                key={actionButton.key}
                color="var(--chakra-colors-gray-600);"
                variant="ghost"
                onClick={actionButton.onClick}
              >
                {actionButton.icon}
              </IconButton>
            ) : (
              <Popover
                width="max-content"
                placement="top"
                closeOnBlur
                key={actionButton.key}
              >
                <ButtonGroup isAttached variant="ghost" display="flex" gap="0">
                  <PopoverAnchor>
                    <Button
                      isDisabled={actionButton.isDisabled}
                      key={actionButton.key}
                      color="var(--chakra-colors-gray-600);"
                      onClick={actionButton.onClick}
                      padding="0"
                    >
                      {actionButton.icon}
                    </Button>
                  </PopoverAnchor>
                  <PopoverTrigger>
                    <IconButton
                      icon={<MdOutlineKeyboardArrowDown />}
                      fontSize="1.2rem"
                    />
                  </PopoverTrigger>
                </ButtonGroup>
                <Portal>
                  <PopoverContent width="max-content" position="top">
                    <PopoverBody display="flex" flexDirection="column">
                      {actionButton?.children?.map((btn) => (
                        <Button
                          onClick={btn.onClick}
                          key={btn.key}
                          variant={"ghost"}
                          isDisabled={btn.isDisabled}
                          color="var(--chakra-colors-gray-600);"
                        >
                          {btn.icon}
                        </Button>
                      ))}
                    </PopoverBody>
                  </PopoverContent>
                </Portal>
              </Popover>
            );
          })}
        </Box>
      )}
    </HStack>
  );
};

PPZNavigation.propTypes = {
  api: PropTypes.shape({
    instance: PropTypes.shape({
      transformState: PropTypes.shape({
        scale: PropTypes.number,
      }),
    }),
    zoomIn: PropTypes.func.isRequired,
    zoomOut: PropTypes.func.isRequired,
    resetTransform: PropTypes.func.isRequired,
    setTransform: PropTypes.func,
  }).isRequired,
  customNavigation: PropTypes.arrayOf(
    PropTypes.shape(
      {
        icon: PropTypes.node.isRequired,
        onClick: PropTypes.func,
        key: PropTypes.string.isRequired,
      }.isRequired,
    ),
  ),
};

export default PPZNavigation;
