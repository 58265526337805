import { getFirstDraggableBelow } from "../utils/get-first-draggable";
import { isOverFitGap } from "../utils/is-over-fit-gap";
import { checkOrder } from "../utils/check-order";
import { getDragOverColor } from "../utils/color";

export const getOnDragStartHandler = (state, onDragStart) => (event) => {
  state.isDrag = true;
  const draggableNode = event.target;
  state.draggableDimensions = draggableNode.getBoundingClientRect();
  state.storedDisplayValue = event.target.style.display;
  setTimeout(function () {
    event.target.style.display = "none";
  }, 1);

  if (typeof onDragStart === "function") {
    onDragStart(event);
  }
};

export const getOnDragHandler = (state, onDrag) => (event) => {
  if (event.clientX !== 0 && event.clientY !== 0) {
    event.target.style.display = "none";
    const overFitGap = isOverFitGap(event, state.activeGapNode);

    const nodeBelow = getFirstDraggableBelow(event);
    if (state.actualNode) {
      state.actualNode.setAttribute(
        "style",
        `background-color: ${state.actualNodeOriginalColor}`,
      );
    }
    state.actualNode = nodeBelow;
    if (state.actualNode) {
      state.actualNodeOriginalColor = window
        .getComputedStyle(state.actualNode, null)
        .getPropertyValue("background-color");

      state.actualNode.setAttribute(
        "style",
        `background-color: ${getDragOverColor(state.actualNodeOriginalColor)}`,
      );
    }

    if (!state.activeGapNode) {
      insertFitGap(state, event, state.actualNode);
    }

    if (overFitGap) {
      state.actualOrder = state.actualNode
        ? checkOrder(state.actualNode, (el) => el !== event.target)
        : checkOrder(state.activeGapNode, (el) => el !== event.target);
    } else {
      state.actualOrder = checkOrder(
        state.actualNode,
        (el) => el !== event.target,
      );
    }

    if (typeof onDrag === "function") {
      onDrag(event, { order: state.actualOrder });
    }
  }
};

export const getOnDragEndHandler = (state, onDragEnd) => (event) => {
  state.isDrag = false;
  event.target.style.display = state.storedDisplayValue;
  if (state.activeGapNode) {
    state.activeGapNode.remove();
    state.activeGapNode = null;
  }

  if (typeof onDragEnd === "function") {
    onDragEnd(event, { order: state.actualOrder });
  }
};

const insertFitGap = (state, event, nodeBelow) => {
  state.activeGapNode = document.createElement("th");
  state.activeGapNode.setAttribute(
    "style",
    `min-width:${state.draggableDimensions.width}px;min-height:${state.draggableDimensions.height}px;`,
  );
  (nodeBelow || event.target).parentNode.insertBefore(
    state.activeGapNode,
    nodeBelow,
  );
};
