import { executeBlock } from "../../../../../process-executor/process-executor";
import { useEffect, useState } from "react";

export const useDataSource = (
  dataSource,
  context,
  componentInfo,
  staticParams = {},
  params = [],
  listenToChanges = false,
  changedStateResolver,
) => {
  const [state, setState] = useState();
  const [error, setError] = useState();
  useEffect(() => {
    if (context?.state?.__mounted && context && dataSource && dataSource.id) {
      executeBlock(context, dataSource.id, staticParams, params, componentInfo)
        .then((res) => {
          const newState = Array.isArray(res) ? res : [res];
          if (
            !changedStateResolver ||
            (typeof changedStateResolver === "function" &&
              changedStateResolver(state, newState) === true)
          ) {
            setState(newState);
          }
        })
        .catch(setError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    context?.state?.__mounted,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    listenToChanges ? context : false,
    componentInfo?.id,
    dataSource?.id,
  ]);

  return [state, error];
};
