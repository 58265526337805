import { openFileDialog } from "../../open-file-dialog";

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const openFileBlock = (block, componentContext) => ({
  definition: block,
  execute: (staticParams, params, systemParams) =>
    new Promise((resolve, reject) => {
      const { name, callerId, callerGuid } = systemParams;
      const type = staticParams.type || "attachment";
      const outputFormat = staticParams.outputFormat || "js-file";
      let path = staticParams.storePath;
      const context = staticParams.useSourceContext
        ? systemParams?.source?.context || componentContext
        : componentContext;

      openFileDialog({ type }).then((result) => {
        switch (outputFormat) {
          case "base64":
            toBase64(result)
              .then((b64File) =>
                resolve({
                  ...staticParams,
                  file: {
                    name: result.name,
                    value: b64File,
                    size: result.size,
                    mimeType: result.type,
                  },
                }).catch(reject),
              )
              .catch(reject);
            break;
          case "js-file":
          default: {
            if (path) {
              path =
                path === undefined || path === null
                  ? path
                  : path.replace("<name>", name);
              if (!path.includes("formSource.") && !path.includes(".")) {
                path = `formSource${
                  !path && staticParams?.spreadObject ? "" : "."
                }${path}`;
              }

              context.functions.setStateProp(
                path,
                result,
                !!staticParams?.spreadObject,
                `CHANGE_STATE_BLOCK_[${name}:${callerId};${callerGuid}]`,
              );
            }
            resolve({
              ...staticParams,
              file: result,
              size: result.size,
              mimeType: result.type,
            });
            break;
          }
        }
      });
    }),
});
