export const getData = (sourceFunc) =>
  new Promise((resolve, reject) => {
    if (typeof sourceFunc === "function") {
      Promise.resolve(sourceFunc())
        .then((result) => {
          if (typeof result === "object" && Array.isArray(result)) {
            resolve(result);
          } else {
            reject(
              new Error(`Bad result type. Got ${typeof result}. Expected Array`)
            );
          }
        })
        .catch(reject);
    } else {
      //   reject("Source func is not a function");
    }
  });
