// @flow

export const useInputSelection = () => {
  const getIndexesForField = (arr: number[]) => {
    const regex = /\D+/;
    let tempArr: number[] = [];
    let idxArr: number[][] = [];

    arr.forEach((el, idx) => {
      if (!regex.test(el)) tempArr.push(idx);
      if (regex.test(el) || idx === arr.length - 1) {
        idxArr.push(tempArr);
        tempArr = [];
      }
    });

    return idxArr;
  };

  const getSelectionFields = (input: string) => {
    const charArr = input.split("");
    let fields = getIndexesForField(charArr);
    return fields;
  };

  const getSelectionRange = (string: string, clickedPos: number) => {
    const fields = getSelectionFields(string);
    let selectedField: number[] = [];

    fields.forEach((field) => {
      if (clickedPos >= field[0] && clickedPos <= field[field.length - 1] + 1) {
        selectedField = field;
      }
    });

    const start = selectedField[0];
    const end = selectedField[selectedField.length - 1] + 1;

    return { start, end };
  };

  return { getSelectionRange };
};
