import { localStorageKeys } from "../utils/local-storage/local-storage-keys";
import { getLocalStorageItem } from "../utils/local-storage/local-storage";

const getConfig = () => getLocalStorageItem(localStorageKeys.APP_CONFIG);

const findConfig = (el, code) => el.code === code;

const findNumberFormatConfig = (el) => findConfig(el, "number_format");
const findToastTimeConfig = (el) => findConfig(el, "toast_time");
const findTabMaxWidthConfig = (el) => findConfig(el, "tab_max_width");
const findTabMinWidthConfig = (el) => findConfig(el, "tab_min_width");

export const getNumberFormatConfig = () =>
  getConfig()?.find(findNumberFormatConfig).value;

export const getToastTimeConfig = () =>
  getConfig()?.find(findToastTimeConfig).value;

export const getTabMaxWidthConfig = () =>
  getConfig()?.find(findTabMaxWidthConfig).value;

export const getTabMinWidthConfig = () =>
  getConfig()?.find(findTabMinWidthConfig).value;
