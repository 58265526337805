import PropTypes from "prop-types";
import {
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import { PropertySetter } from "../property-setter";
import { useCallback } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";

const DesignerButtons = ({
  onAction = () => {},
  componentStructure,
  designerPortalRef,
  selectedComponent,
  moveButtons,
}) => {
  const handleStructureUpdate = useCallback(
    (newStructure) => onAction({ type: "update-structure", newStructure }),
    [onAction],
  );
  const handleSave = useCallback(() => onAction({ type: "save" }), [onAction]);

  const handleSaveAsTemplate = () => {
    if (selectedComponent) {
      onAction({ type: "save_as_template", payload: selectedComponent });
    }
  };

  const handleSaveAsNew = () => {
    if (selectedComponent) {
      onAction({ type: "save_as_new_component", payload: selectedComponent });
    }
  };

  const handleExport = useCallback(
    () => onAction({ type: "export" }),
    [onAction],
  );

  const handleUndo = useCallback(() => onAction({ type: "undo" }), [onAction]);

  const handleRedo = useCallback(() => onAction({ type: "redo" }), [onAction]);

  return (
    <HStack
      style={{
        justifyContent: "flex-end",
        padding: "4px 40px 4px 0px",
        transform: moveButtons ? "translateX(-360px)" : "",
        transition: "0.5s ease",
      }}
    >
      {/* <ButtonGroup isAttached>
        <Button
          color="primary"
          variant="contained"
          gap="4px"
          padding="2px 4px 2px 8px"
          onClick={handleUndo}
        >
          Undo
          <FiRotateCcw />
        </Button>
        <Button
          color="primary"
          variant="contained"
          gap="4px"
          padding="2px 4px 2px 8px"
          onClick={handleRedo}
        >
          Redo
          <FiRotateCw />
        </Button>
      </ButtonGroup> */}
      <Popover width="max-content">
        <ButtonGroup isAttached color="primary" variant="contained">
          <PopoverAnchor>
            <Button color="primary" onClick={handleSave}>
              Save&Exit
            </Button>
          </PopoverAnchor>
          <PopoverTrigger>
            <IconButton icon={<MdKeyboardArrowDown />} />
          </PopoverTrigger>
        </ButtonGroup>
        <Portal>
          <PopoverContent width="max-content">
            <PopoverBody display="flex" flexDirection="column">
              <Button color="primary" variant="ghost" onClick={handleSaveAsNew}>
                Save as new component
              </Button>
              <Button
                color="primary"
                variant="ghost"
                onClick={handleSaveAsTemplate}
              >
                Save as template
              </Button>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>

      <PropertySetter
        mainComponentStructure={componentStructure}
        modalProps={{ portalProps: { containerRef: designerPortalRef } }}
        onSave={handleStructureUpdate}
        openComponent={({ onOpen }) => (
          <Button variant="outlined" onClick={onOpen}>
            Advanced property setter
          </Button>
        )}
      />
      <Button color="primary" variant="contained" onClick={handleExport}>
        Export
      </Button>
    </HStack>
  );
};

DesignerButtons.propTypes = {
  onAction: PropTypes.func,
  componentStructure: PropTypes.any,
  designerPortalRef: PropTypes.shape({ current: PropTypes.any }),
  selectedComponent: PropTypes.object,
  moveButtons: PropTypes.bool,
};

export default DesignerButtons;
