const findComponentInChildrens = (
  childrenComponents,
  componentId,
  parentComponentId
) => {
  for (let index in childrenComponents) {
    const component = childrenComponents[index];
    if (component.id === componentId) {
      return parentComponentId;
    } else if (
      component.childrenComponents &&
      component.childrenComponents.length > 0
    ) {
      const id = findComponentInChildrens(
        component.childrenComponents,
        componentId,
        component.id
      );
      if (id) {
        return id;
      }
    }
  }
  return null;
};

export const findComponentParent = (componentState, componentId) => {
  if (!componentState || componentState.id === componentId) {
    return null;
  } else {
    return findComponentInChildrens(
      componentState.childrenComponents,
      componentId,
      componentState.id
    );
  }
};
