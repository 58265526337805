import { toast } from "react-toastify";

export const showToastBlock = (block) => {
  return {
    definition: block,
    execute: (staticParams) =>
      new Promise((resolve) => {
        switch (staticParams?.type) {
          case "error":
            toast.error(staticParams?.message);
            resolve(staticParams);
            return;
          case "warning":
            toast.warn(staticParams?.message);
            resolve(staticParams);
            return;
          case "success":
          default:
            toast.success(staticParams?.message);
            resolve(staticParams);
            return;
        }
      }),
  };
};
