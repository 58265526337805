import { useCallback, useMemo } from "react";
import { getOptions } from "../options";

const formatValue = (value) =>
  value
    ? Number.parseFloat(
        value
          ?.toString()
          .replace(",", ".")
          .split(".")
          .map((elem, index) => {
            if (index === 0 && (elem.startsWith("-") || elem.startsWith("+"))) {
              const sign = elem.substring(1);
              return `${elem.substring(1)}${elem.split(sign)[1] || 0}`;
            } else {
              return elem || 0;
            }
          })
          .join(".")
      )
    : 0;

const formatBack = (oldValue, newValue) =>
  Number.parseFloat(
    oldValue?.toString()?.includes(",") ? newValue.replace(".", ",") : newValue
  );

export const useNumeric = (value, onChange, formatStr) => {
  const config = useMemo(() => getOptions(formatStr), [formatStr]);

  const handleClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleChange = useCallback(
    (newValue) => {
      onChange(formatBack(value, newValue));
    },
    [onChange, value]
  );

  const handlers = {
    onClick: handleClick,
    onChange: handleChange,
  };

  return [formatValue(value), handlers, config];
};
