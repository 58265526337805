export const checkProperties = (model, deps) => {
  const fixedDepsString = (deps || '').replace(' ', '');

  const depsArrayKeys = fixedDepsString ? fixedDepsString.split(',') : [];

  return (
    depsArrayKeys.length === 0 ||
    (depsArrayKeys.length > 0 &&
      !depsArrayKeys.some((key) => typeof (model || {})[key] === 'undefined'))
  );
};
