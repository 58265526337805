import { getParsedValue } from "./get-parsed-value";

/**
 *
 * @param {number | string} value value to compare
 * @param {number | string} max value to compare to
 * @returns max if value is greater or value string or number
 */
export const compareToMax = (value, max) => {
  const parsedValue = getParsedValue(value);
  const parsedMax = getParsedValue(max);

  if (parsedMax < parsedValue)
    return {
      number: parsedMax,
      string: parsedMax.toString(),
    };

  return {
    number: parsedValue,
    string: parsedValue.toString(),
  };
};

/**
 *
 * @param {number | string} value value to compare
 * @param {number | string} min value to compare to
 * @returns min if value is lower or value string or number
 */
export const compareToMin = (value, min) => {
  const parsedValue = getParsedValue(value);
  const parsedMin = getParsedValue(min);

  if (parsedMin > parsedValue)
    return {
      number: parsedMin,
      string: parsedMin.toString(),
    };

  return {
    number: parsedValue,
    string: parsedValue.toString(),
  };
};

/**
 *
 * @param {string | number} value value that will be checked between range
 * @param {string | number} min minimum value in range
 * @param {string | number} max maximum value in range
 * @returns value if between range, max if value is greater than max, min if value is lower than min
 */
export const getValueForRange = (value, min, max) => {
  const parsedValue = getParsedValue(value);
  const parsedMin = getParsedValue(min);
  const parsedMax = getParsedValue(max);

  const comparedToMin = compareToMin(parsedValue, parsedMin).number;
  const resultValue = compareToMax(comparedToMin, parsedMax);

  return {
    number: resultValue.number,
    string: resultValue.string,
  };
};

/**
 *
 * @param {string | number} val1 value to check
 * @param {string | number} val2 value to check on
 * @returns boolean if val1 is greater or equal to val2
 */
export const isEqualOrGreater = (val1, val2) => {
  const parsedVal1 = getParsedValue(val1);
  const parsedVal2 = getParsedValue(val2);

  return parsedVal1 >= parsedVal2 ? true : false;
};

/**
 *
 * @param {string | number} val1 value to check
 * @param {string | number} val2 value to check on
 * @returns boolean if val1 is lower or equal to val2
 */
export const isEqualOrLower = (val1, val2) => {
  const parsedVal1 = getParsedValue(val1);
  const parsedVal2 = getParsedValue(val2);

  return parsedVal1 <= parsedVal2 ? true : false;
};
