import { Box } from "@chakra-ui/react";
import { useApiContext } from "../../../../../echo-components/api-context/use-api-context";
import PropTypes from "prop-types";
import { withBaseComponent } from "../../../with-base-component";
import ChildrenComponent from "../children-component/children.component";
import DesignerShim from "../../../../shim-components/designer-shim/designer-shim";
import { getEchoDefinedParent } from "../../../../../../components/echo-components/utils/definition-tree-utils/get-echo-defined-parent";

const ContainerComponent = (props) => {
  const { id, guid, nodeId, rootProps } = props;

  const { nodeRef, ...restRootProps } = rootProps;
  const [apiContext, , mode, rootComponent] = useApiContext(id, guid, nodeId);

  const handleOnMouseOver = (e) => {
    if (mode === "designer") {
      e.preventDefault();
      e.stopPropagation();
      apiContext.api.ChangeDropAreaId(id);
    }
  };

  var componentDefinition = getEchoDefinedParent(
    rootComponent,
    id,
    guid,
    nodeId,
  );

  const hasChildren =
    componentDefinition &&
    componentDefinition?.childrenComponents &&
    Array.isArray(componentDefinition?.childrenComponents) &&
    componentDefinition?.childrenComponents.length > 0;

  if (hasChildren && componentDefinition?.childrenComponents.length > 1) {
    window.console.warn(
      `Too many components applied to container component: ${componentDefinition?.childrenComponents.length}`,
    );
  }

  return (
    <Box ref={nodeRef} {...restRootProps}>
      {componentDefinition &&
      componentDefinition?.childrenComponents &&
      Array.isArray(componentDefinition?.childrenComponents) &&
      componentDefinition?.childrenComponents.length > 0 ? (
        <ChildrenComponent
          {...props}
          mode={mode}
          onMouseOver={handleOnMouseOver}
          definition={componentDefinition?.childrenComponents[0]}
        />
      ) : (
        <DesignerShim />
      )}
    </Box>
  );
};

ContainerComponent.propTypes = {
  id: PropTypes.number,
  rootProps: PropTypes.any,
  guid: PropTypes.string,
  nodeId: PropTypes.string,
};

export default withBaseComponent(ContainerComponent);
