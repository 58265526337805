function referenceObjectEquality(o1, o2) {
  return Object.is(o1, o2);
}

export function arrayEqual(a1, a2, equalityFunc = referenceObjectEquality) {
  if (a1.length !== a2.length) return false;
  for (let i = 0; i < a1.length; i++) {
    if (!equalityFunc(a1[i], a2[i])) {
      return false;
    }
  }
  return true;
}
