import { getBlock } from "../../services/diagram-service";
import { getBlockExecutor } from "../get-block";
import { toast } from "react-toastify";

const convertArrayToObj = (array, item) =>
  array.reduce((obj, prop) => ({ ...obj, [prop]: item[prop] }), {});

const executeProcess = async (item, executor, staticParams, params) => {
  const itemProperties = Object.keys(item);
  const newModel = convertArrayToObj(itemProperties, item);

  return await executor.execute(
    { ...staticParams, __model: { ...newModel } },
    params,
  );
};

export const loopBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: async (staticParams, params) => {
      const { dataSource, process } = staticParams;

      let data;
      try {
        const dataSourceBlock = await getBlock(dataSource.id);
        const executor = getBlockExecutor(dataSourceBlock, componentContext);
        const res = await executor.execute(staticParams, params);
        data = res;
      } catch {
        toast.error("Couldn't process datasource.");
      }

      if (data) {
        try {
          const processBlock = await getBlock(process.id);
          const executor = getBlockExecutor(processBlock, componentContext);
          const dataOperations = data.map((el) =>
            executeProcess(el, executor, staticParams, params),
          );

          await Promise.all(dataOperations);
        } catch {
          toast.error("Error when processing loop.");
        }
      }
    },
  };
};
