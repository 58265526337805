import { baseGet, basePost } from "./base-fetch";

export const createEchoObject = (definitionId, params) =>
  basePost("EchoObject/getNewObject", params, { definitionId });

export const getObjectDefinition = async (objectId) =>
  await baseGet("EchoObject/getDefinition", { definitionId: objectId });

export const getAvailableObjects = () =>
  baseGet("EchoObject/getAvailableObjects");
