const getColumnWithoutSort = (column) => {
  if (column.sortOrder || column.sortOrder === 0) {
    // eslint-disable-next-line no-unused-vars
    const { sortOrder, ...rest } = column;
    return rest;
  }

  return column;
};

const getColumnWithSort = (col, sortOrder) => {
  const { sort, ...rest } = col;
  if (!sort)
    return {
      ...col,
      sort: "asc",
      sortOrder: col.sortOrder ? col.sortOrder : sortOrder,
    };
  if (sort === "asc")
    return {
      ...col,
      sort: "desc",
      sortOrder: col.sortOrder ? col.sortOrder : sortOrder,
    };
  return getColumnWithoutSort(rest);
};

export const getSortingOrder = (columns) => {
  const hasSort = columns.some((col) => col.sortOrder || col.sortOrder === 0);

  if (hasSort) {
    const sortOrders = columns
      .map((el) => el.sortOrder)
      .filter((el) => el || el === 0);

    const lastSort = Math.max(...sortOrders);
    const nextSort = lastSort + 1;
    return nextSort;
  }

  return 0;
};

export const getColumnWithCtrl = (currentCol, clickedColName, sortOrder) => {
  if (currentCol.name === clickedColName)
    return getColumnWithSort(currentCol, sortOrder);

  return currentCol;
};

export const getColumnWithoutCtrl = (currentCol, clickedColName, sortOrder) => {
  // eslint-disable-next-line no-unused-vars
  const { sort, ...rest } = currentCol;
  if (currentCol.name === clickedColName)
    return getColumnWithSort(currentCol, sortOrder);

  return getColumnWithoutSort(rest);
};
