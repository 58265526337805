import { useState } from "react";
import PropTypes from "prop-types";
import LabeledSelectFormControl from "../../shared/labeled-select-form-control/labeled-select-form-control";
import { Button, Stack, ScaleFade } from "@chakra-ui/react";

const LoginCompanyStep = ({ show, companies, isInvalid, onSubmit }) => {
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);

  const handleSubmit = () => {
    if (companies && Array.isArray(companies)) {
      onSubmit(
        companies.find(
          (c) => c.id.toString() === selectedCompanyId?.toString(),
        ),
      );
    }
  };
  return (
    <ScaleFade in={show} unmountOnExit>
      <Stack width="248px">
        {companies && Array.isArray(companies) && companies.length > 1 && (
          <LabeledSelectFormControl
            isInvalid={isInvalid && !selectedCompanyId}
            value={selectedCompanyId?.toString() || ""}
            onChange={(e) => setSelectedCompanyId(e.target.value.toString())}
            label="Select company"
          >
            <option value=""> </option>
            {companies
              .sort((a, b) => a.id - b.id)
              .map((company) => (
                <option
                  key={`option-${company.id}`}
                  value={company.id?.toString()}
                >
                  {company.name}
                </option>
              ))}
          </LabeledSelectFormControl>
        )}
        <Button type="primary" onClick={handleSubmit}>
          Login
        </Button>
      </Stack>
    </ScaleFade>
  );
};

LoginCompanyStep.propTypes = {
  companies: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.any, name: PropTypes.string }),
  ).isRequired,
  isInvalid: PropTypes.bool,
  onSubmit: PropTypes.func,
  show: PropTypes.bool,
};

export default LoginCompanyStep;
