export const DEFAULT_CURVINESS = 0.33;
export const DEFAULT_AXIS_COLOR = "#333";
export const DEFAULT_TEXT_COLOR = "#000";
export const DEFAULT_TICK_COLOR_MAJOR = "#222";
export const DEFAULT_TICK_COLOR_MINOR = "#666";
export const DEFAULT_FONT_SIZE = 12;
export const DEFAULT_FONT_FAMILY = "Arial, Helvetica, sans-serif";
export const MIN_LOG_VALUE = 0.0000000000000000001;

export const TICK_COLORS = [DEFAULT_TICK_COLOR_MINOR, DEFAULT_TICK_COLOR_MAJOR];
