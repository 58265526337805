import PropTypes from "prop-types";
import { Box, Text, Tooltip } from "@chakra-ui/react";
import {
  getEventDay,
  getEventEnd,
  getEventMargin,
  getEventStart,
  getEventWidth,
  getEventZIndex,
  getFontColor,
} from "../utils/calendar-utils";
import { format } from "date-fns";
import { useCalendarDragContext } from "../hooks/use-calendar-drag-context";
import { useMemo } from "react";
import { EventResizer } from "../event-resizer/event-resizer";

export const EventsList = ({
  events,
  onEventClick,
  onEventMove,
  availableCalendars,
}) => {
  return events
    .sort((a, b) => {
      const aStart = getEventStart(a);
      const aEnd = getEventEnd(a);
      const bStart = getEventStart(b);
      const bEnd = getEventEnd(b);
      const aDiff = aEnd - aStart;
      const bDiff = bEnd - bStart;

      return bDiff - aDiff;
    })
    .map((ev, _, arr) => (
      <Event
        eventsList={arr}
        event={ev}
        background={
          availableCalendars.find((c) => c.id === ev.calendarId).background
        }
        onClick={onEventClick}
        onMove={onEventMove}
        key={ev.id}
      />
    ));
};

EventsList.propTypes = {
  events: PropTypes.array,
  onEventClick: PropTypes.func,
  onEventMove: PropTypes.func,
};

export const Event = ({ event, eventsList, onClick, background, zIndex }) => {
  const { draggedElement, handleDragStart } = useCalendarDragContext();

  const column = useMemo(() => getEventDay(event), [event]);
  const start = useMemo(() => getEventStart(event), [event]);
  const end = useMemo(() => getEventEnd(event), [event]);

  let timeout;

  const marginLeft = useMemo(
    () =>
      getEventMargin(eventsList, {
        id: event.id,
        date: event.from,
        column,
        start,
        end,
      }),
    [eventsList, event, column, start, end],
  );

  const width = useMemo(
    () =>
      getEventWidth(
        eventsList,
        { id: event.id, date: event.from, column, start, end },
        marginLeft,
      ),
    [eventsList, event, column, start, end, marginLeft],
  );

  const calculatedZIndex = useMemo(
    () => getEventZIndex({ id: event.id, column, start, end }),
    [event, column, start, end],
  );

  return (
    <Box
      width={zIndex ? "100%" : `${width}%`}
      padding="0 2px"
      marginLeft={zIndex ? "0" : `${marginLeft}%`}
      marginRight="5px"
      marginBottom="1px"
      gridRowStart={start}
      gridRowEnd={end}
      gridColumnStart={column}
      display="flex"
      alignItems="center"
      justifyContent="center"
      overflow="hidden"
      pointerEvents={draggedElement ? "none" : ""}
      position="relative"
      zIndex={zIndex ? zIndex : calculatedZIndex}
      onMouseOver={(e) => e.stopPropagation()}
    >
      <EventResizer event={event} position="top" />
      <Box
        border="2px solid white"
        height="100%"
        width="100%"
        borderRadius="12px"
        background={background.value}
        display="flex"
        flexDirection="column"
        padding="4px 10px"
        position="relative"
        cursor="pointer"
        draggable
        opacity={
          draggedElement && draggedElement.id === event.id && !zIndex
            ? "0.5"
            : "1"
        }
        pointerEvents={draggedElement ? "none" : ""}
        onDrag={(e) => {
          e.preventDefault();
        }}
        onDragStart={(e) => {
          e.preventDefault();
        }}
        onDragEnd={(e) => {
          e.preventDefault();
        }}
        onPointerDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
          timeout = setTimeout(() => {
            handleDragStart(event, "drag");
          }, 300);
        }}
        onPointerUp={(e) => {
          e.preventDefault();
          e.stopPropagation();
          clearTimeout(timeout);
          onClick({ ...event });
        }}
      >
        <Tooltip label={`${event.title}, ${format(event.from, "HH:mm")}`}>
          <Text
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            color={getFontColor(background.value)}
          >
            {event.title}, {format(event.from, "HH:mm")}
          </Text>
        </Tooltip>
      </Box>
      <EventResizer event={event} position="bottom" />
    </Box>
  );
};

Event.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    from: PropTypes.object,
    to: PropTypes.object,
  }),
  eventsList: PropTypes.array,
  background: PropTypes.shape({ value: PropTypes.string }),
  onClick: PropTypes.func,
  onMove: PropTypes.func,
  zIndex: PropTypes.string,
};
