export const getModelChanges = (initialData, newData) => {
  let changes = [];
  const initialDataKeys = Object.keys(initialData);

  initialDataKeys.forEach((key) => {
    if (initialData[key] !== newData[key]) {
      changes = [...changes, key];
    }
  });

  return changes;
};
