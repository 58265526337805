import PropTypes from 'prop-types';
import TreeView from '../../../../shared/tree-view/tree-view';
import AddSchemaPropComponent from './add-schema-prop-component';
import { Box } from '@chakra-ui/react';

const PropRepresentation = ({
  prop,
  propKey,
  path,
  selection,
  onAdd,
  onSelectionChange,
}) => {
  const handleOnSelectChange = () => {
    if (onSelectionChange) {
      onSelectionChange(path);
    }
  };

  const handleAddProperty = (name) => {
    if (onAdd) {
      onAdd(name, path);
    }
  };

  if (prop) {
    if (prop.type === 'object') {
      var keys = Object.keys(prop?.properties ?? {});
      return (
        <TreeView
          selected={selection === path}
          content={propKey}
          canSelect
          onSelectChange={handleOnSelectChange}
        >
          {keys.map((key) => (
            <PropRepresentation
              key={`prop-representation-${key}`}
              prop={prop.properties[key]}
              propKey={key}
              path={`${path}${path ? '.' : ''}${key}`}
              selection={selection}
              onAdd={onAdd}
              onSelectionChange={onSelectionChange}
            />
          ))}
          <Box marginLeft='8px' marginBottom='8px'>
            <AddSchemaPropComponent onAdd={handleAddProperty} />
          </Box>
        </TreeView>
      );
    } else {
      return (
        <TreeView
          selected={selection === path}
          content={propKey}
          canSelect
          onSelectChange={handleOnSelectChange}
        />
      );
    }
  } else {
    return null;
  }
};

PropRepresentation.propTypes = {
  prop: PropTypes.any.isRequired,
  propKey: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  onAdd: PropTypes.func.isRequired,
  selection: PropTypes.string,
  onSelectionChange: PropTypes.func.isRequired,
};

export default PropRepresentation;
