import { getParent } from '../../components/echo-components/utils/definition-tree-utils/get-parent';
import { showErrorToast } from '../../components/shared/echo-error-toast';

const getResult = (value) => {
  if (value === null) {
    return 'null';
  }
  switch (typeof value) {
    case 'string':
      return value;
    case 'bigint':
    case 'boolean':
    case 'number':
      return value.toString();
    case 'undefined':
      return 'null';
    default:
      return 'error';
  }
};

export const getSwitchBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: (staticParams, params, systemParams) =>
      new Promise((resolve, reject) => {
        try {
          const { source } = systemParams;
          let { callerId, callerGuid, callerNodeId } = systemParams;
          let context = componentContext;

          if (source?.context && source?.callerId) {
            callerId = source.callerId;
            context = source.context;
            callerGuid = source.callerGuid;
            callerNodeId = source.callerNodeId;
          }

          const parent = getParent(
            context.component,
            callerId,
            callerGuid,
            callerNodeId
          );

          const componentChild =
            parent.childrenComponents &&
            Array.isArray(parent.childrenComponents)
              ? parent.childrenComponents.find((cmp) => cmp.id === callerId)
              : null;

          const body = staticParams?.__processBlock?.body || block.body;
          const funcDefinition = JSON.parse(body);
          funcDefinition[2] = `return ${funcDefinition[2]};`;
          const func = JSON.parse(
            JSON.stringify(funcDefinition),
            Function.deserialize
          );

          resolve({
            __resultCondition: getResult(
              func(componentContext, block.id, params, {
                ...staticParams,
                __caller: componentChild,
              })
            ),
          });
        } catch (err) {
          showErrorToast(err);
          reject(err);
        }
      }),
  };
};
