import PropTypes from "prop-types";
import { Stack } from "@chakra-ui/react";
import EchoReactCodemirror from "../../../echo-react-codemirror/echo-react-codemirror";

const EditorComponent = ({ value, onChange, options, type, style }) => {
  return (
    <Stack width="100%" height="100%">
      <EchoReactCodemirror
        type={type}
        height="100%"
        width="100%"
        flex="1 1 auto"
        minHeight={0}
        justifyContent="stretch"
        value={value}
        options={{ ...options, theme: "material" }}
        onChange={(value) => {
          onChange(value);
        }}
        {...style}
      />
    </Stack>
  );
};

EditorComponent.propTypes = {
  type: PropTypes.oneOf(["javascript", "sql"]),
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.shape({
    mode: PropTypes.string,
    lineNumbers: PropTypes.bool,
  }),
  style: PropTypes.object,
};

export default EditorComponent;
