import React, { useRef } from "react";
import PropTypes from "prop-types";
import "./styles.css";
import { Box } from "@chakra-ui/react";

const MozillaPdf = ({ src, onSave }) => {
  const ref = useRef();

  const origin = window?.location?.origin;

  return src ? (
    <iframe
      ref={ref}
      style={{ position: "relative", height: "100%", width: "100%" }}
      onLoad={(evt) => {
        const document =
          evt?.target?.contentDocument || evt?.target?.contentWindow?.document;
        document.saveFile = (blob) => {
          if (typeof onSave === "function") {
            onSave(new File([blob], "document.pdf"));
          }
        };
      }}
      src={
        origin + "/mozilla-pdf/web/viewer.html?file=" + encodeURIComponent(src)
      }
    />
  ) : (
    <Box>Please select valid pdf file</Box>
  );
};

MozillaPdf.propTypes = {
  src: PropTypes.string,
  onSave: PropTypes.func,
};

export default MozillaPdf;
