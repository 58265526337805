import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { useMenu } from "./hooks/use-menu";
import { Box } from "@chakra-ui/react";
import { MenuElement } from ".";

const Menu = forwardRef(({ data = [], onSelect, activePath, color }, ref) => {
  const [structure] = useMenu({ data });

  return (
    <Box ref={ref}>
      {structure &&
        structure.map((structureElement) => (
          <MenuElement
            color={color}
            onSelect={onSelect}
            activePath={activePath}
            {...structureElement}
            key={structureElement.key}
            keyValue={structureElement.key}
          />
        ))}
    </Box>
  );
});

Menu.displayName = "Menu";

Menu.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      path: PropTypes.string,
      parentKey: PropTypes.string,
    }),
  ),
  onSelect: PropTypes.func,
  activePath: PropTypes.string,
  color: PropTypes.string,
};

export default Menu;
