import { useCallback, useState } from "react";

export const useComponentsRegistry = () => {
  const [components, setComponents] = useState([]);

  const register = useCallback((c) => {
    setComponents((prev) => {
      const f = prev.find((el) => el.name === c.name);
      if (f) return [...prev.filter((el) => el.name !== c.name), c];
      return [...prev, c];
    });
  }, []);

  const unregister = useCallback((c) => {
    setComponents((prev) => prev.filter((el) => el.name !== c.name));
  }, []);

  const getComponent = (name) =>
    components.find((c) => c.name.toLowerCase() === name.toLowerCase());

  const updateComponent = useCallback((c) => {
    setComponents((prev) =>
      prev.map((el) => {
        if (c.name === el.name) return c;
        return el;
      }),
    );
  }, []);

  return { register, unregister, getComponent, updateComponent };
};
