import { showErrorToast } from "../../components/shared/echo-error-toast";
import { calculateProp } from "../../echo-component/function-wrapper/calculate-prop";
import { getCallerComponentPropDefinition } from "./utils/get-caller-component-prop-definition";

export const getCallerComponentProp = (block, componentContext) => ({
  definition: block,
  execute: (staticParams, params, systemParams) =>
    new Promise((resolve, reject) => {
      try {
        const propDefinition = getCallerComponentPropDefinition(
          systemParams,
          componentContext,
          staticParams.name,
        );

        if (
          !propDefinition ||
          !propDefinition.prop ||
          propDefinition.prop.type === "PROCESS"
        ) {
          // TODO error type
          reject("Get caller component prop: Bad prop type");
          return;
        }

        const value = calculateProp(
          propDefinition.prop,
          componentContext,
          propDefinition.parent.componentProps,
          propDefinition.callerId,
          propDefinition.callerGuid,
          propDefinition.callerNodeId,
        );

        resolve({
          ...staticParams,
          variables: [
            ...(staticParams.variables || []),
            { name: staticParams.toName || staticParams.name, value },
          ],
        });
      } catch (err) {
        window.console.error(err);
        showErrorToast(err);
        reject(err);
      }
    }),
});
