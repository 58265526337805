import { toast } from 'react-toastify';

export const createLink = (
  processBlock,
  { createLinkState, setCreateLinkState, processLinks, setProcessLinks }
) => {
  if (!createLinkState?.sourceProcessBlockId) {
    setCreateLinkState({
      sourceProcessBlockId: processBlock.id,
      destinationProcessBlockId: null,
    });
  } else if (
    createLinkState.sourceProcessBlockId &&
    !createLinkState.destinationProcessBlockId
  ) {
    if (
      processLinks.some(
        (processLink) =>
          processLink.sourceProcessBlockId ===
            createLinkState.sourceProcessBlockId &&
          processLink.destinationProcessBlockId === processBlock.id
      )
    ) {
      toast.error('There actually is connection between these process blocks.');
    } else if (createLinkState.sourceProcessBlockId === processBlock.id) {
      setCreateLinkState({
        sourceProcessBlockId: null,
      });
    } else {
      Promise.all([
        setProcessLinks([
          ...processLinks,
          {
            sourceProcessBlockId: createLinkState.sourceProcessBlockId,
            destinationProcessBlockId: processBlock.id,
          },
        ]),
        setCreateLinkState({
          sourceProcessBlockId: null,
        }),
      ]);
    }
  }
};
