import React from "react";
import PropTypes from "prop-types";
import { DATAGRID_BODY_TESTID } from "../../utils/test-ids";
import { ContextMenuWrapper } from "../context-menu-wrapper/context-menu-wrapper";
import { Tbody } from "@chakra-ui/react";
import { useContextMenu } from "@echo/ui/src/lib/components/context-menu/hooks/useContextMenu";
import { useDragContext } from "../../hooks/useDragContext";
import { useDatagridContext } from "../../hooks/useDatagridContext";
import { useScrollContext } from "../../hooks/use-scroll-context";

export const BodyWithContextMenu = ({ children, ...rest }) => {
  const { gridContextActions } = useDatagridContext();
  const { onDragOver } = useDragContext();
  const {
    clickPosition,
    closeContextMenu,
    isContextMenuOpened,
    openContextMenu,
  } = useContextMenu();

  const { bodyRef } = useScrollContext();

  return (
    <ContextMenuWrapper
      isOpen={isContextMenuOpened}
      onClose={closeContextMenu}
      clickPosition={clickPosition}
      actions={gridContextActions}
    >
      <Tbody
        ref={bodyRef}
        {...rest}
        data-testid={DATAGRID_BODY_TESTID}
        onDragOver={(e) => onDragOver(e, -1)}
        onContextMenu={openContextMenu}
      >
        {children}
      </Tbody>
    </ContextMenuWrapper>
  );
};

BodyWithContextMenu.propTypes = {
  children: PropTypes.node,
};
