const addToStorage = (key, value) =>
  sessionStorage.setItem(key, JSON.stringify(value));

const getFromStorage = (key) => JSON.parse(sessionStorage.getItem(key)) || [];

const removeStorage = (key) => sessionStorage.removeItem(key);

export const createStore = (name) => {
  const key = `${name}_requests`;

  const addRequest = (id) => {
    const stored = getFromStorage(key);
    const newList = stored ? [...stored, id] : [id];
    addToStorage(key, newList);
  };

  const removeRequest = (id) => {
    const stored = getFromStorage(key);
    const newList = stored.filter((r) => r !== id);
    if (newList.length === 0) removeStorage(key);
    else addToStorage(key, newList);
  };

  const getRequests = () => getFromStorage(key);

  return { addRequest, removeRequest, getRequests };
};
