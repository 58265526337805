import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  InputGroup,
  InputRightElement,
  FormControl,
  Stack,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import "../../styles/user/user-dialog.css";
import { setUserPassword } from "../../services/login-service";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

const UserSelectListModal = ({ isOpen, onClose }) => {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newComparePassword, setNewComparePassword] = useState("");

  const [changeInfo, setChangeInfo] = useState("");
  const [changeButtonPressed, setChangeButtonPressed] = useState(false);
  const [visibilityPass, setVisibilityPass] = useState(false);
  const [visibilityNewConfirmPass, setVisibilityNewConfirmPass] =
    useState(false);
  const [visibilityNewPass, setVisibilityNewPass] = useState(false);

  const closeDialog = (e) => {
    Promise.all([
      onClose(e),
      setPassword(""),
      setNewPassword(""),
      setNewComparePassword(""),
      setChangeInfo(""),
      setChangeButtonPressed(false),
    ]);
  };

  const onFormSubmit = async (e) => {
    if (newPassword !== newComparePassword) {
      toast.error("Passwords are incorrect");
    } else {
      await setUserPassword(password, newPassword)
        .then((result) => {
          if (result === "Password changed") {
            toast.success("Password changed successfully");
            closeDialog(e);
          } else {
            toast.error("Error. " + result);
          }
        })
        .catch((error) => {
          window.console.error(error);
          toast.error("Error occured while changing the password.");
        });
    }
  };

  const handleSubmit = (e) => {
    if (password && newPassword && newComparePassword) {
      onFormSubmit(e);
    }
    setChangeButtonPressed(true);
    e?.preventDefault();
  };

  return (
    <Modal isOpen={isOpen} onClose={closeDialog}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Change password</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <Stack width="auto">
              <FormControl
                isInvalid={
                  (changeButtonPressed && !password) ||
                  changeInfo === "Niepoprawne hasło"
                }
              >
                <InputGroup>
                  <Input
                    width="100%"
                    placeholder="Old password"
                    type={visibilityPass ? "text" : "password"}
                    variant="outline"
                    helperText={
                      changeButtonPressed && !password
                        ? "Pole 'Hasło' nie może być puste"
                        : changeInfo === "Niepoprawne hasło"
                          ? changeInfo
                          : ""
                    }
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setChangeInfo("");
                    }}
                  />
                  <InputRightElement>
                    <Button
                      tabIndex={-1}
                      m="0.5rem"
                      size="sm"
                      onClick={() => setVisibilityPass(!visibilityPass)}
                    >
                      {visibilityPass ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <FormControl
                isInvalid={
                  (changeButtonPressed && !newPassword) ||
                  changeInfo === "Niezgodne nowe hasła"
                }
              >
                <InputGroup>
                  <Input
                    width="100%"
                    placeholder="New password"
                    type={visibilityNewPass ? "text" : "password"}
                    variant="outline"
                    helperText={
                      changeButtonPressed && !newPassword
                        ? "Pole 'Nowe hasło' nie może być puste"
                        : changeInfo === "Niezgodne nowe hasła"
                          ? changeInfo
                          : ""
                    }
                    value={newPassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                      setChangeInfo("");
                    }}
                  />
                  <InputRightElement>
                    <Button
                      tabIndex={-1}
                      m="0.5rem"
                      size="sm"
                      onClick={() => setVisibilityNewPass(!visibilityNewPass)}
                    >
                      {visibilityNewPass ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <FormControl
                isInvalid={
                  (changeButtonPressed && !newComparePassword) ||
                  changeInfo === "Niezgodne nowe hasła"
                }
              >
                <InputGroup>
                  <Input
                    width="100%"
                    placeholder="Confirm new password"
                    type={visibilityNewConfirmPass ? "text" : "password"}
                    variant="outline"
                    helperText={
                      changeButtonPressed && !newComparePassword
                        ? "Pole 'Powtórz nowe hasło' nie może być puste"
                        : changeInfo === "Niezgodne nowe hasła"
                          ? changeInfo
                          : ""
                    }
                    value={newComparePassword}
                    onChange={(e) => {
                      setNewComparePassword(e.target.value);
                      setChangeInfo("");
                    }}
                  />
                  <InputRightElement>
                    <Button
                      tabIndex={-1}
                      m="0.5rem"
                      size="sm"
                      onClick={() =>
                        setVisibilityNewConfirmPass(!visibilityNewConfirmPass)
                      }
                    >
                      {visibilityNewConfirmPass ? (
                        <ViewIcon />
                      ) : (
                        <ViewOffIcon />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
            </Stack>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={closeDialog}>
            Close
          </Button>
          <Button onClick={handleSubmit}>Save</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

UserSelectListModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

export default UserSelectListModal;
