import { setProp } from "./actions/set-prop/set-prop";

export const getInitialComponentState = (componentProps) => ({
  __mounted: false,
  formSource: componentProps || {},
  componentProps: componentProps || {},
});

const LOG_CALLER = false;

export const componentStateReducer = (prevState, action) => {
  switch (action.type) {
    case "SET_VARIABLE":
      if (LOG_CALLER) {
        console.info("Change variable by caller: " + action.caller);
      }
      return setProp(
        prevState,
        action.propPath,
        action.propValue,
        action.spreadObject,
      );
    case "SET_FORM_SOURCE": {
      const idObj = {
        _id:
          action.propValue.Id ??
          action.propValue.ID ??
          action.propValue.id ??
          action.propValue.iD,
      };
      if (
        !action?.propValue ||
        !Object.keys(action.propValue).some((k) => k.toLowerCase() === "id")
      ) {
        idObj.id = null;
      }

      return {
        ...setProp(
          prevState,
          "formSource",
          {
            ...action.propValue,
            ...prevState.componentProps,
            ...idObj,
          },
          true,
        ),
        ...setProp(
          prevState,
          "initialFormSource",
          {
            ...action.propValue,
            ...prevState.componentProps,
            ...idObj,
          },
          true,
        ),
        __mounted: true,
      };
    }
    case "SET_MOUNTED":
      return {
        ...prevState,
        __mounted: action.mounted,
      };
    case "CLEAR_STATE":
      return getInitialComponentState();
    case "RELOAD":
      return { ...prevState };
    default:
      return prevState;
  }
};
