export const getEchoDefinedParent = (
  definitionRoot,
  componentId,
  componentGuid,
  componentNodeId,
  echoDefinedParent = null
) => {
  const lastEchoDefinedParentFound =
    definitionRoot?.component?.source === '!?echo-defined'
      ? definitionRoot
      : echoDefinedParent;
  if (
    definitionRoot.id === componentId &&
    definitionRoot.guid === componentGuid &&
    definitionRoot.nodeId === componentNodeId
  ) {
    return null; // component is root and does not have any parents
  }

  // search in related component too
  if (definitionRoot.component) {
    const component = getEchoDefinedParent(
      definitionRoot.component,
      componentId,
      componentGuid,
      componentNodeId,
      lastEchoDefinedParentFound
    );
    if (component !== null) {
      return component;
    }
  }

  if (
    !definitionRoot.childrenComponents ||
    !definitionRoot.childrenComponents.length
  ) {
    // not found
    return null;
  }

  for (let i = 0; i < definitionRoot.childrenComponents.length; i++) {
    const component = definitionRoot.childrenComponents[i];
    if (
      component?.id === componentId &&
      component?.guid === componentGuid &&
      component?.nodeId === componentNodeId
    ) {
      // found! return last parent found
      return lastEchoDefinedParentFound;
    } else if (
      component?.component?.id === componentId &&
      component?.component?.guid === componentGuid &&
      component?.component?.nodeId === componentNodeId
    ) {
      return component;
    } else {
      const nestedSearch = getEchoDefinedParent(
        component,
        componentId,
        componentGuid,
        componentNodeId,
        lastEchoDefinedParentFound
      );
      if (nestedSearch === null) {
        // not found, continue search
        continue;
      } else {
        return nestedSearch;
      }
    }
  }

  return null;
};
