import { executeBlock } from "../process-executor";

export const executeFrontendBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: (staticParams, params, systemParams) =>
      new Promise((resolve, reject) => {
        const blockId = staticParams?.blockId || params[0];
        if (blockId) {
          Promise.resolve(
            executeBlock(
              componentContext,
              blockId,
              staticParams,
              params,
              systemParams,
            ),
          )
            .then(resolve)
            .catch(reject);
        } else {
          reject("Block id parameter must be specified");
        }
      }),
  };
};
