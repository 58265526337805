import { getEchoDefinedParent } from "../../components/echo-components/utils/definition-tree-utils/get-echo-defined-parent";
import { showErrorToast } from "../../components/shared/echo-error-toast";
import { calculateProp } from "../../echo-component/function-wrapper/calculate-prop";

export const executeComponentProp = (block, componentContext) => ({
  definition: block,
  execute: (staticParams, params, systemParams) =>
    new Promise((resolve, reject) => {
      try {
        const { source } = systemParams;
        let { callerId, callerGuid, callerNodeId } = systemParams;
        let context = componentContext;

        if (source?.context && source?.callerId) {
          callerId = source.callerId;
          context = source.context;
          callerGuid = source.callerGuid;
          callerNodeId = source.callerNodeId;
        }

        const echoDefinedParent = getEchoDefinedParent(
          context.component,
          callerId,
          callerGuid,
          callerNodeId,
        );

        const propDefinition =
          echoDefinedParent.componentProps[staticParams.name];

        if (!propDefinition || propDefinition.type !== "PROCESS") {
          reject("Bad prop type");
        }

        const propFunc = calculateProp(
          propDefinition,
          context,
          echoDefinedParent.componentProps,
          callerId,
          callerGuid,
          callerNodeId,
        );

        if (typeof propFunc === "function") {
          Promise.resolve(propFunc(...params))
            .then(resolve)
            .catch(reject);
        }
      } catch (err) {
        showErrorToast(err);
        reject(err);
      }
    }),
});
