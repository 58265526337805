export const removeOpt = (styleOptions) => {
  if (!styleOptions || typeof styleOptions !== "object") {
    return styleOptions;
  }

  const result = { ...styleOptions };
  const keys = Object.keys(result);
  for (let keyIndex in keys) {
    const key = keys[keyIndex];
    if (key.startsWith("opt-")) {
      delete result[key];
    }
  }
  return result;
};
