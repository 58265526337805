/**
 * Append element to the DOM, else Safari on iOS won't fire event
 * @param {boolean} allowMultiple allow multiple file selection
 * @returns DOM input element
 */
const createInput = (allowMultiple, accept, type) => {
  const input = document.createElement("input");
  input.type = "file";

  input.multiple = allowMultiple;
  input.accept = accept;
  input.style.display = "none";

  if (type.includes("-camera")) {
    input.capture = type.replace("-camera", "");
  }

  return input;
};

/**
 * Create input accept string from options
 * @param {array} options Available options to open
 * @returns Accept string
 */
const createAcceptString = (options) =>
  options[0].type.includes("-camera")
    ? "image/*"
    : [
        ...options.map((option) => option.mimeTypes || []),
        ...options.map((option) => option.extensions || []),
      ].join();

/**
 * Opens a file from disk using the legacy `<input type="file">` method.
 * @param {array} options Available options to open
 * @return {array} Detailed columns specification
 */
export default (options = [{}]) => {
  if (!Array.isArray(options)) {
    options = [options];
  }
  return new Promise((resolve) => {
    const input = createInput(
      options[0].multiple || false,
      createAcceptString(options) || "",
      options[0].type,
    );

    document.body.append(input);

    input.addEventListener("change", () => {
      input.remove();
      resolve(input.multiple ? Array.from(input.files) : input.files[0]);
    });

    if ("showPicker" in input && !options[0].type.includes("-camera")) {
      input.showPicker();
    } else {
      input.click();
    }
  });
};
