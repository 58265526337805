// @flow

import * as React from "react";
import { Select } from "@chakra-ui/react";
import { useDatagridContext } from "../../hooks/useDatagridContext";
import {
  DATAGRID_NAVIGATION_PAGE_SIZE_SELECT_OPT_TESTID,
  DATAGRID_NAVIGATION_PAGE_SIZE_SELECT_TESTID,
} from "../../utils/test-ids";

type Option = { value: number, label: string };

export const PageSizeSelect = (): React.Node => {
  const {
    settings: { itemsPerPage },
    actions: { onItemsPerPageChange },
  } = useDatagridContext();

  const selectOptions: Option[] = [
    { value: 20, label: "20" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  const handleChange = (e) => {
    const itemsPerPage = parseInt(e.target.value);
    onItemsPerPageChange(itemsPerPage);
  };

  return (
    <Select
      value={itemsPerPage.value}
      onChange={handleChange}
      data-testid={DATAGRID_NAVIGATION_PAGE_SIZE_SELECT_TESTID}
      width="max-content"
    >
      {selectOptions.map((opt, idx) => (
        <option
          value={opt.value}
          key={idx}
          data-testid={DATAGRID_NAVIGATION_PAGE_SIZE_SELECT_OPT_TESTID}
        >
          {opt.label}
        </option>
      ))}
    </Select>
  );
};
