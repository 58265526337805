export const dataFilter = (filterText, item) => {
  const itemKeys = Object.keys(item);
  for (let keyIndex in itemKeys) {
    const key = itemKeys[keyIndex];
    const itemKey = item[key] || '';
    const filterTextString = filterText?.toString() || '';
    if (
      itemKey.toString().toLowerCase().includes(filterTextString.toLowerCase())
    ) {
      return true;
    }
  }
  return false;
};

export const columnFilter = (item, columnFilters) => {
  const filterKeys = Object.keys(columnFilters);
  let result = true;
  for (let key of filterKeys) {
    if (
      (Object.keys(columnFilters[key]).length === 1 ||
        columnFilters[key].contains === true) &&
      columnFilters[key].value
    ) {
      result = (item[key] || '')
        .toLowerCase()
        .includes(columnFilters[key].value.toLowerCase());
    }
    if (columnFilters[key].notContains === true && columnFilters[key].value) {
      result = !(item[key] || '')
        .toLowerCase()
        .includes(columnFilters[key].value.toLowerCase());
    }
    if (columnFilters[key].equal === true && columnFilters[key].value) {
      result =
        (item[key] || '').toLowerCase() ===
        columnFilters[key].value.toLowerCase();
    }
    if (columnFilters[key].notEqual === true && columnFilters[key].value) {
      result =
        (item[key] || '').toLowerCase() !==
        columnFilters[key].value.toLowerCase();
    }
    if (columnFilters[key].greaterThan === true && columnFilters[key].value) {
      result =
        (item[key] || '').toLowerCase() >
        columnFilters[key].value.toLowerCase();
    }
    if (
      columnFilters[key].greaterOrEqual === true &&
      columnFilters[key].value
    ) {
      result =
        (item[key] || '').toLowerCase() >=
        columnFilters[key].value.toLowerCase();
    }
    if (columnFilters[key].lowerThan === true && columnFilters[key].value) {
      result =
        (item[key] || '').toLowerCase() <
        columnFilters[key].value.toLowerCase();
    }
    if (columnFilters[key].lowerOrEqual === true && columnFilters[key].value) {
      result =
        (item[key] || '').toLowerCase() <=
        columnFilters[key].value.toLowerCase();
    }
  }
  return result;
};
