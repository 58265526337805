export const tileIconSet = [
  "FiActivity",
  "FiAirplay",
  "FiAlertCircle",
  "FiAlertOctagon",
  "FiAlertTriangle",
  "FiAlignCenter",
  "FiAlignJustify",
  "FiAlignLeft",
  "FiAlignRight",
  "FiAnchor",
  "FiAperture",
  "FiArchive",
  "FiArrowDownCircle",
  "FiArrowDownLeft",
  "FiArrowDownRight",
  "FiArrowDown",
  "FiArrowLeftCircle",
  "FiArrowLeft",
  "FiArrowRightCircle",
  "FiArrowRight",
  "FiArrowUpCircle",
  "FiArrowUpLeft",
  "FiArrowUpRight",
  "FiArrowUp",
  "FiAtSign",
  "FiAward",
  "FiBarChart2",
  "FiBarChart",
  "FiBatteryCharging",
  "FiBattery",
  "FiBellOff",
  "FiBell",
  "FiBluetooth",
  "FiBold",
  "FiBookmark",
  "FiBookOpen",
  "FiBook",
  "FiBox",
  "FiBriefcase",
  "FiCalendar",
  "FiCameraOff",
  "FiCamera",
  "FiCast",
  "FiCheckCircle",
  "FiCheckSquare",
  "FiCheck",
  "FiChevronDown",
  "FiChevronLeft",
  "FiChevronRight",
  "FiChevronsDown",
  "FiChevronsLeft",
  "FiChevronsRight",
  "FiChevronsUp",
  "FiChevronUp",
  "FiChrome",
  "FiCircle",
  "FiClipboard",
  "FiClock",
  "FiCloudDrizzle",
  "FiCloudLightning",
  "FiCloudOff",
  "FiCloudRain",
  "FiCloudSnow",
  "FiCloud",
  "FiCodepen",
  "FiCodesandbox",
  "FiCode",
  "FiCoffee",
  "FiColumns",
  "FiCommand",
  "FiCompass",
  "FiCopy",
  "FiCornerDownLeft",
  "FiCornerDownRight",
  "FiCornerLeftDown",
  "FiCornerLeftUp",
  "FiCornerRightDown",
  "FiCornerRightUp",
  "FiCornerUpLeft",
  "FiCornerUpRight",
  "FiCpu",
  "FiCreditCard",
  "FiCrop",
  "FiCrosshair",
  "FiDatabase",
  "FiDelete",
  "FiDisc",
  "FiDivideCircle",
  "FiDivideSquare",
  "FiDivide",
  "FiDollarSign",
  "FiDownloadCloud",
  "FiDownload",
  "FiDribbble",
  "FiDroplet",
  "FiEdit2",
  "FiEdit3",
  "FiEdit",
  "FiExternalLink",
  "FiEyeOff",
  "FiEye",
  "FiFacebook",
  "FiFastForward",
  "FiFeather",
  "FiFigma",
  "FiFileMinus",
  "FiFilePlus",
  "FiFile",
  "FiFileText",
  "FiFilm",
  "FiFilter",
  "FiFlag",
  "FiFolderMinus",
  "FiFolderPlus",
  "FiFolder",
  "FiFramer",
  "FiFrown",
  "FiGift",
  "FiGitBranch",
  "FiGitCommit",
  "FiGithub",
  "FiGitlab",
  "FiGitMerge",
  "FiGitPullRequest",
  "FiGlobe",
  "FiGrid",
  "FiHardDrive",
  "FiHash",
  "FiHeadphones",
  "FiHeart",
  "FiHelpCircle",
  "FiHexagon",
  "FiHome",
  "FiImage",
  "FiInbox",
  "FiInfo",
  "FiInstagram",
  "FiItalic",
  "FiKey",
  "FiLayers",
  "FiLayout",
  "FiLifeBuoy",
  "FiLink2",
  "FiLinkedin",
  "FiLink",
  "FiList",
  "FiLoader",
  "FiLock",
  "FiLogIn",
  "FiLogOut",
  "FiMail",
  "FiMapPin",
  "FiMap",
  "FiMaximize2",
  "FiMaximize",
  "FiMeh",
  "FiMenu",
  "FiMessageCircle",
  "FiMessageSquare",
  "FiMicOff",
  "FiMic",
  "FiMinimize2",
  "FiMinimize",
  "FiMinusCircle",
  "FiMinusSquare",
  "FiMinus",
  "FiMonitor",
  "FiMoon",
  "FiMoreHorizontal",
  "FiMoreVertical",
  "FiMousePointer",
  "FiMove",
  "FiMusic",
  "FiNavigation2",
  "FiNavigation",
  "FiOctagon",
  "FiPackage",
  "FiPaperclip",
  "FiPauseCircle",
  "FiPause",
  "FiPenTool",
  "FiPercent",
  "FiPhoneCall",
  "FiPhoneForwarded",
  "FiPhoneIncoming",
  "FiPhoneMissed",
  "FiPhoneOff",
  "FiPhoneOutgoing",
  "FiPhone",
  "FiPieChart",
  "FiPlayCircle",
  "FiPlay",
  "FiPlusCircle",
  "FiPlusSquare",
  "FiPlus",
  "FiPocket",
  "FiPower",
  "FiPrinter",
  "FiRadio",
  "FiRefreshCcw",
  "FiRefreshCw",
  "FiRepeat",
  "FiRewind",
  "FiRotateCcw",
  "FiRotateCw",
  "FiRss",
  "FiSave",
  "FiScissors",
  "FiSearch",
  "FiSend",
  "FiServer",
  "FiSettings",
  "FiShare2",
  "FiShare",
  "FiShieldOff",
  "FiShield",
  "FiShoppingBag",
  "FiShoppingCart",
  "FiShuffle",
  "FiSidebar",
  "FiSkipBack",
  "FiSkipForward",
  "FiSlack",
  "FiSlash",
  "FiSliders",
  "FiSmartphone",
  "FiSmile",
  "FiSpeaker",
  "FiSquare",
  "FiStar",
  "FiStopCircle",
  "FiSunrise",
  "FiSunset",
  "FiSun",
  "FiTable",
  "FiTablet",
  "FiTag",
  "FiTarget",
  "FiTerminal",
  "FiThermometer",
  "FiThumbsDown",
  "FiThumbsUp",
  "FiToggleLeft",
  "FiToggleRight",
  "FiTool",
  "FiTrash2",
  "FiTrash",
  "FiTrello",
  "FiTrendingDown",
  "FiTrendingUp",
  "FiTriangle",
  "FiTruck",
  "FiTv",
  "FiTwitch",
  "FiTwitter",
  "FiType",
  "FiUmbrella",
  "FiUnderline",
  "FiUnlock",
  "FiUploadCloud",
  "FiUpload",
  "FiUserCheck",
  "FiUserMinus",
  "FiUserPlus",
  "FiUsers",
  "FiUser",
  "FiUserX",
  "FiVideoOff",
  "FiVideo",
  "FiVoicemail",
  "FiVolume1",
  "FiVolume2",
  "FiVolume",
  "FiVolumeX",
  "FiWatch",
  "FiWifiOff",
  "FiWifi",
  "FiWind",
  "FixCircle",
  "FixOctagon",
  "FixSquare",
  "Fix",
  "FiYoutube",
  "FiZapOff",
  "FiZap",
  "FiZoomIn",
  "FiZoomOut",
];
