import { testZeroHash } from "./validation";

export const getSeparators = (format) => {
  let decimal;
  let group;

  const chars = format.split("");
  const decimalSeparator = chars.find((c) => c === ".");
  const groupSeparator = chars.find((c) => !testZeroHash(c) && c !== ".");

  if (decimalSeparator) decimal = decimalSeparator;
  if (groupSeparator) group = groupSeparator;

  return { decimal, group };
};

export const getPartFormats = (format, decimalSeparator) => {
  const arr = format.split(decimalSeparator);
  const intFormat = arr[0] ? arr[0] : arr[0];
  const fractionFormat = arr[1] ? arr[1] : undefined;

  return { intFormat, fractionFormat };
};

export const getGroupSize = (format, separator) => {
  const arr = format.split(separator);
  let result = 0;
  if (arr.length > 1) {
    arr.forEach((p) => {
      if (p.length > result) result = p.length;
    });
  }

  return result;
};

export const splitFormat = (format) => {
  const { decimal, group } = getSeparators(format);
  const { intFormat, fractionFormat } = getPartFormats(format, decimal);
  const decimalPlaces = fractionFormat ? fractionFormat.length : 0;

  return {
    intFormat,
    fractionFormat,
    decimalPlaces,
    decimalSeparator: decimal,
    groupSeparator: group,
  };
};
