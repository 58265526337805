import PropTypes from "prop-types";
import { NumberEditComponent } from "./number-edit-component";
import { DateEditComponent } from "./date-edit-component";
import { BoolEditComponent } from "./bool-edit-component";
import { ComboEditComponent } from "./combo-edit-component";
import { InputEditComponent } from "./input-edit-component";

const getConditions = (type, editType) => {
  const isString = type === "string";
  const isNumber = type === "number";
  const isDate = type === "datetime";
  const isBool = type === "boolean" || editType === "checkbox";
  const isCombo = editType === "combo";

  return { isString, isNumber, isDate, isBool, isCombo };
};

const getComponentProps = (props) => {
  const { value, onChange, format, inputType, editDataSource } = props;
  const numberProps = { value, format, onChange };
  const dateProps = { value, format, onChange };
  const boolProps = { value, onChange };
  const comboProps = { value, options: editDataSource, onChange };
  const inputProps = { value, onChange, inputType };

  return { numberProps, dateProps, boolProps, comboProps, inputProps };
};

export const ConditionComponent = ({ type, editType, ...props }) => {
  const { isNumber, isDate, isBool, isCombo } = getConditions(type, editType);
  const { numberProps, dateProps, boolProps, comboProps, inputProps } =
    getComponentProps(props);

  if (isNumber) return <NumberEditComponent {...numberProps} />;
  if (isDate) return <DateEditComponent {...dateProps} />;
  if (isBool) return <BoolEditComponent {...boolProps} />;
  if (isCombo) return <ComboEditComponent {...comboProps} />;

  return <InputEditComponent {...inputProps} />;
};

ConditionComponent.propTypes = {
  type: PropTypes.string,
  editType: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  editDataSource: PropTypes.array,
};
