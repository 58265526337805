import { useMemo, useRef } from "react";
import {
  getOnDragEndHandler,
  getOnDragHandler,
  getOnDragStartHandler,
} from "../handlers";

export const useDraggable = (childrenComponent, handlers, ref) => {
  const internalRef = useRef(null);
  const draggableRef =
    childrenComponent.ref || childrenComponent.props.ref || ref || internalRef;

  const stateRef = useRef({
    storedDisplayValue: null,
    draggableDimensions: null,
    activeGapNode: null,
    actualOrder: null,
  });

  const draggableProps = useMemo(
    () => ({
      draggable: true,
      onDrag: getOnDragHandler(stateRef.current, handlers?.onDrag),
      onDragStart: getOnDragStartHandler(
        stateRef.current,
        handlers?.onDragStart,
      ),
      onDragEnd: getOnDragEndHandler(stateRef.current, handlers?.onDragEnd),
      ref: draggableRef,
    }),
    [
      draggableRef,
      handlers?.onDrag,
      handlers?.onDragEnd,
      handlers?.onDragStart,
    ],
  );

  return [draggableProps, !!stateRef?.current?.isDrag];
};
