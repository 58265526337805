import { Box } from "@chakra-ui/react";
import { FiCompass } from "react-icons/fi";

const ConditionalBlock = () => {
  return (
    <Box
      className="disable-ppz-pan"
      height="80px"
      width="80px"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        className="disable-ppz-pan"
        width="56px"
        height="56px"
        transform="rotate(45deg)"
        borderColor="red.800"
        backgroundColor="red.500"
        display="flex"
        alignItems="center"
        alignContent="center"
        justifyContent="center"
      >
        <Box className="disable-ppz-pan" transform="rotate(-45deg)">
          <FiCompass className="disable-ppz-pan" color="white" size="35px" />
        </Box>
      </Box>
    </Box>
  );
};

export default ConditionalBlock;
