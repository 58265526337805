import React from "react";
import { Box } from "@chakra-ui/react";
import ConnectionComponent from "../connection/connection-component";
import GraphDesignerModal from "../modal/graph-designer-modal";

import {
  fixDestinationProcessLinkCoordinates,
  fixSourceProcessLinkCoordinates,
} from "./coordinates";

export const getConnectionComponent = (
  connection,
  elements,
  onUpdate,
  onDelete,
  connectionPropertiesComponentFunc,
) => {
  const sourceElement = elements.find(
    (element) => element.id === connection.sourceId,
  );

  const sourceCoordinates = {
    x: sourceElement.positionX,
    y: sourceElement.positionY,
  };

  const destinationElement = elements.find(
    (element) => element.id === connection.destinationId,
  );

  const destinationCoordinates = {
    x: destinationElement.positionX,
    y: destinationElement.positionY,
  };

  return (
    <ConnectionComponent
      label={connection.label}
      editModalComponent={(modalProps) => (
        <GraphDesignerModal
          {...modalProps}
          propertiesComponent={
            connectionPropertiesComponentFunc || (() => <Box />)
          }
          state={connection}
          onEdit={onUpdate}
        />
      )}
      onDelete={() => onDelete(connection)}
      from={fixSourceProcessLinkCoordinates(sourceCoordinates)}
      to={fixDestinationProcessLinkCoordinates(destinationCoordinates)}
    />
  );
};
