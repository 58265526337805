import { echoPortal } from "../../../../components/echo-portal/echo-portal";
import QuestionAlert from "../../../../components/shared/echo-components/base-components/components/question-alert-component/question-alert.component";

export const showIsUniqueAlert = (message) =>
  new Promise((resolve, reject) =>
    echoPortal.render((container) => (
      <QuestionAlert
        open={true}
        header={"Walidacja formularza"}
        question={message}
        alertMode={"Tak/Nie"}
        onAction={(type) => {
          container.unmount();
          if (type === "Tak") resolve(true);

          resolve(false);
        }}
      />
    )),
  );
